import { HttpErrorResponse } from '@angular/common/http'
import { Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'

import { mrmaAlertConfigs } from '@app/models/alert-configuration.model'
import { composeTechnicalHTMLResponse, getErrorMessage } from '@app/models/error-message.builder'
import { BackgroundSyncStatusEnum } from '@app/models/offline-status.enum'
import { CalibrationDetails } from '@app/modules/calibration/models/calibration-details.model'
import { AppState } from '@app/store/app.store'
import { LoadCalibrationSuccessAction } from '@app/store/calibration/calibration.actions'
import { OfflineService } from '../offline.service'
import { ResponseHandlingStrategy } from './response-handling-strategy'

export class ShowToastrOnErrorCalibrationStrategy extends ResponseHandlingStrategy {
    constructor(
        next: ResponseHandlingStrategy,
        private store: Store<AppState>,
        private toastr: ToastrService,
        private offlineService: OfflineService,
        private calibrationDetail: CalibrationDetails
    ) {
        super(next)
    }

    public catchError(error: HttpErrorResponse): any {
        this.displayErrorMessage(error)
        return super.catchError(error)
    }

    public displayErrorMessage(error: HttpErrorResponse): void {
        const syncStatus = this.offlineService.getCalibrationBackgroundSyncStatus(
            this.calibrationDetail.workOrderNumber,
            this.calibrationDetail.equipmentId
        )

        if (syncStatus === BackgroundSyncStatusEnum.QUEUED) {
            this.store.dispatch(new LoadCalibrationSuccessAction(this.calibrationDetail))
        }

        if (error.status !== 0) {
            const technicalMessage = composeTechnicalHTMLResponse(getErrorMessage(error))
            const config = mrmaAlertConfigs.NetworkError
            this.toastr.error(technicalMessage, config.message, config.configuration)
        }
    }
}

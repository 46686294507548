import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { Observable } from 'rxjs'
import { map, mergeMap, switchMap } from 'rxjs/operators'

import { EquipmentService } from '@app/modules/equipment/services/equipment.service'
import { SectionLoaderEnum } from '@app/modules/shared/models/section-loader.enum'
import { ResponseHandlerService } from '@app/services/response-handler.service'
import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'
import { ResponseHandlingStrategyBuilder } from '@app/services/response-handling-strategy.builder'
import { AppState } from '@app/store/app.store'
import { CreateEquipmentSuccessAction } from '../actions/equipment-list.actions'
import {
    ActionType, CreateEquipmentAction, GetEquipmentDetailsAction, GetEquipmentDetailsSuccessAction, UpdateEquipmentNoteAction,
    UpdateEquipmentNoteSuccessAction
} from '../actions/equipment.actions'

@Injectable()
export class EquipmentEffects {

    
    createEquipment: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<CreateEquipmentAction>(ActionType.CreateEquipment),
        switchMap((action) =>
            this.responseHandlerService.query(() =>
                this.equipmentService.createEquipment(action.payload),
                this.customStrategy
            )),
        switchMap((response) => [
            new CreateEquipmentSuccessAction(response.body)
        ])
    ))

    
    getEquipmentDetails: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<GetEquipmentDetailsAction>(ActionType.GetEquipmentDetails),
        mergeMap((action) =>
            this.responseHandlerService.query(() =>
                this.equipmentService.getEquipment(action.payload),
                this.sectionLoaderStrategy
            ).pipe(
                map(response => new GetEquipmentDetailsSuccessAction(response))
            )
        )
    ))

    
    updateEquipmentNote: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<UpdateEquipmentNoteAction>(ActionType.UpdateEquipmentNote),
        switchMap((action: UpdateEquipmentNoteAction) =>
            this.responseHandlerService.query(() =>
                this.equipmentService.updateEquipmentNote(action.payload),
                this.sectionLoaderStrategy
            )),
        switchMap((response) => [
            new UpdateEquipmentNoteSuccessAction(response.body),
            new GetEquipmentDetailsAction(response.body.equipmentId)
        ])
    ))

    private customStrategy: ResponseHandlingStrategy
    private sectionLoaderStrategy: ResponseHandlingStrategy

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private toastr: ToastrService,
        private equipmentService: EquipmentService,
        private responseHandlerService: ResponseHandlerService
    ) {
        this.customStrategy = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .useShowToastrOnError(this.toastr)
            .useShowSectionLoader(this.store, SectionLoaderEnum.ModalBody)
            .responseStrategy

        this.sectionLoaderStrategy = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .useShowToastrOnError(this.toastr)
            .useShowSectionLoader(this.store, SectionLoaderEnum.EquipmentAdminTemplate)
            .responseStrategy
    }
}

import { Action } from '@ngrx/store'

import { PageComponentState } from '@app/models/page-component-state.model'

export enum ActionType {
    LogToPageHistory = '[PageHistory] Log To Page History'
}

export class LogToPageHistoryAction implements Action {
    public readonly type = ActionType.LogToPageHistory
    constructor(public payload: PageComponentState) { }
}

export type PageHistoryAction = LogToPageHistoryAction

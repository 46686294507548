import { Component, Input, OnInit } from '@angular/core'
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { debounceTime, take } from 'rxjs/operators'

import { MultiPointTemplate } from '@app/modules/calibration/models/multi-point-template.model'
import * as TemplateOptions from '@app/modules/equipment/models/default-template-options-values'
import { EquipmentTemplateCoreDetails } from '@app/modules/equipment/models/equipment-template-core-details.model'
import { Process } from '@app/modules/equipment/models/process.model'
import { TemplateTypeOption } from '@app/modules/equipment/models/template-type.model'
import { MultiPointValidationService } from '@app/modules/equipment/services/template-validations/multi-point-validation.service'
import { UOM } from '@app/modules/shared/models/engineering-units/unit-of-measurement.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import * as EquipmentTemplateAction from '@app/store/equipment/actions/equipment-template.actions'
import * as TemplateFormAction from '@app/store/equipment/actions/template-form.actions'
import { equipmentTemplateList, selectedTemplate } from '@app/store/equipment/selectors/equipment-template.selectors'
import { process } from '@app/store/equipment/selectors/process.selectors'
import { deepCopy, findOptionWithKey } from '@app/utils/app-utils.function'
import { InlineLabelComponent } from '@app/modules/mrma-ui-components/layouts/inline-label/inline-label.component'
import { DropdownComponent } from '@app/modules/shared/components/dropdown/dropdown.component'
import { AsyncPipe } from '@angular/common'
import { OptionsComponent } from '@app/modules/shared/components/options/options.component'
import { TabularSetpointsSelectorComponent } from './tabular-setpoints-selector/tabular-setpoints-selector.component'

@Component({
    selector: 'app-multi-point',
    templateUrl: './multi-point.component.html',
    styleUrls: ['./multi-point.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        InlineLabelComponent,
        DropdownComponent,
        AsyncPipe,
        OptionsComponent,
        TabularSetpointsSelectorComponent
    ]
})
export class MultiPointComponent extends SafeUnsubscriberComponent implements OnInit {
    @Input() isAdmin: boolean

    public uomList$: Observable<UOM[]>
    public processes$: Observable<Process[]>
    public isRequiredOption = TemplateOptions.isRequiredOption
    public yesNoOption = TemplateOptions.yesNoOption
    public form: UntypedFormGroup
    public getOption = findOptionWithKey
    public numberOfPointOptions = TemplateOptions.numberOfPointAnalyzerMultiPointOptions
    public defaultSetPointNames = []

    private selectedTemplate: TemplateTypeOption
    private templateDetails: EquipmentTemplateCoreDetails
    private isSetPointAdjustable: boolean
    private isExpectedReadingEdited = false

    constructor(
        protected store: Store<AppState>,
        protected formBuilder: UntypedFormBuilder,
        protected validationService: MultiPointValidationService
    ) {
        super()
    }

    public ngOnInit(): void {
        this.processes$ = this.store.select(process)
        this.addSubscription(
            this.store.select(selectedTemplate).subscribe(template => {
                this.selectedTemplate = template
            })
        )
        this.initTemplateData()
        this.watchMainFormControl()
    }

    private initTemplateData(): void {
        this.addSubscription(
            this.store.select(equipmentTemplateList).pipe(take(1)).subscribe(equipmentTemplate => {
                this.templateDetails = equipmentTemplate.find(template =>
                    template.id === this.selectedTemplate.templateUniqueId
                )

                this.setInitialToggleValue()

                this.form = this.buildForm()

                this.bindFormDependencies()

                this.store.dispatch(new TemplateFormAction.SelectCurrentTemplateFormsAction(
                    this.form.getRawValue()
                ))

                if (!this.isAdmin) {
                    this.form.disable()
                } else {
                    this.form.enable()
                }
            })
        )
    }

    private bindFormDependencies(): void {
        this.bindAdjustableSetPointToggleChange()
        this.bindAdjustableExpectedReadingToggleChange()
    }

    /** This will check if user isAdmin first before enabling. */
    private setControlIsDisabledState(controlPath: string, isDisabled: boolean): void {
        if (isDisabled) {
            this.form.get(controlPath).disable()
            return
        }

        if (this.isAdmin) {
            this.form.get(controlPath).enable()
        }
    }

    private bindAdjustableSetPointToggleChange(): void {
        const adjustableSetPointToggle = this.form.get('isSetPointAdjustable')

        // set initial enable/disable state
        if (adjustableSetPointToggle.value === 'true') {
            this.setControlIsDisabledState('isExpectedReadingEdited', true)
        }

        const toggleChange = adjustableSetPointToggle.valueChanges.subscribe(val => {
            const isSetPointAdjustable = adjustableSetPointToggle.value
            if (isSetPointAdjustable === 'true') {
                const patch: any = {
                    isExpectedReadingEdited: 'false'
                }
                this.form.patchValue(patch)
                this.setControlIsDisabledState('isExpectedReadingEdited', true)
            } else if (this.form.get('isExpectedReadingEdited').disabled) {
                this.setControlIsDisabledState('isExpectedReadingEdited', false)
            }
        })
        this.addSubscription(toggleChange)
    }

    private setInitialToggleValue(): void {
        const setPointDetails = (this.templateDetails?.detail as MultiPointTemplate)?.setPoints ?? []
        if (setPointDetails.length === 0) {
            this.isSetPointAdjustable = false
            this.isExpectedReadingEdited = false
        } else {
            // current setting is that all set points have the same toggle value. use the first set point to define toggle value for now.
            this.isSetPointAdjustable = setPointDetails[0].isSetPointAdjustable
            this.isExpectedReadingEdited = setPointDetails[0].isExpectedReadingEdited
        }
    }

    private bindAdjustableExpectedReadingToggleChange(): void {
        const adjustableExpectedReadingToggle = this.form.get('isExpectedReadingEdited')

        // set initial enable/disable state
        if (adjustableExpectedReadingToggle.value === 'true') {
            this.setControlIsDisabledState('isSetPointAdjustable', true)
        }

        const toggleChange = adjustableExpectedReadingToggle.valueChanges.subscribe(val => {
            const isExpectedReadingEdited = adjustableExpectedReadingToggle.value
            if (isExpectedReadingEdited === 'true') {
                const patch: any = {
                    isSetPointAdjustable: 'false'
                }
                this.form.patchValue(patch)
                this.setControlIsDisabledState('isSetPointAdjustable', true)
            } else if (this.form.get('isSetPointAdjustable').disabled) {
                this.setControlIsDisabledState('isSetPointAdjustable', false)
            }
        })
        this.addSubscription(toggleChange)
    }

    private buildForm(): UntypedFormGroup {
        const form = this.formBuilder.group({
            process: {
                id: this.templateDetails?.processId ?? 1,
                name: this.templateDetails?.processName ?? 'Generic'
            },
            alias: this.templateDetails?.detail?.alias ?? '',
            procedure: this.templateDetails?.detail?.procedure ?? '',

            isTestEquipmentRequired: '' + (this.templateDetails?.detail?.isTestEquipmentRequired ?? false),
            isSetPointAdjustable: '' + this.isSetPointAdjustable,
            isExpectedReadingEdited: '' + this.isExpectedReadingEdited,

            numberOfPoints: this.formBuilder.group({
                point: this.numberOfPointOptions.find(point =>
                    point.key === this.templateDetails?.detail?.numberOfPoint
                )
            }),

            setPoints: this.formBuilder.array((this.templateDetails?.detail as MultiPointTemplate)?.setPoints ?? [])
        })
        return form
    }

    private watchMainFormControl(): void {
        this.addSubscription(
            this.form.valueChanges.pipe(debounceTime(300)).subscribe(_form => {
                const raw = this.form.getRawValue()

                const point = this.form.get('numberOfPoints.point').value
                const eqCoreDetails = deepCopy(this.templateDetails) as EquipmentTemplateCoreDetails
                eqCoreDetails.detail.numberOfPoint = point?.key

                this.store.dispatch(new TemplateFormAction.SelectCurrentTemplateFormsAction(raw))
                this.store.dispatch(new EquipmentTemplateAction.EquipmentTemplateWasModifiedAction(
                    eqCoreDetails,
                    this.validationService.isValid(raw)
                ))
            })
        )
    }

}

export enum SortingState {
    None = 1,
    Ascending = 2,
    Descending = 3
}

export class FilterState {
    filterId: string
    filterItem: string[]
}

export class SortedState {
    sortId: string
    sortingState: SortingState
    sortOrder: number
    isSelected: boolean
}

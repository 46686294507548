import { mrmaStatus } from '@app/modules/shared/models/mrma-status-label.model'

export class CalibrationResultStatus {
    public id: number
    public name: string
}

export const calibrationResultStatusValue: {
    none: CalibrationResultStatus,
    passed: CalibrationResultStatus,
    failed: CalibrationResultStatus,
    failedAdjustedPassed: CalibrationResultStatus,
    deferred: CalibrationResultStatus,
    notTested: CalibrationResultStatus,
    passedAdjustedPassed: CalibrationResultStatus
} = mrmaStatus.resultStatus

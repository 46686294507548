import { ActionType, TemplateFormAction } from '../actions/template-form.actions'

export interface TemplateFormState {
    currentTemplateFormDetails: object
}

export const initialState: TemplateFormState = {
    currentTemplateFormDetails: undefined
}

export function reducer(state = initialState, action: TemplateFormAction): TemplateFormState {
    switch (action.type) {
        case ActionType.SelectCurrentTemplateFormsDetail:
            return {
                ...state,
                currentTemplateFormDetails: action.payload
            }
        default:
            return state
    }
}

import { HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { QueryBuilderService } from '@app/services/query-builder.service'
import { dateToLocalISOString } from '@app/utils/date.utils'
import { environment } from '@environments/environment'
import { AppSettings } from '@settings/app.settings'
import { CalibrationReportDetails } from '../models/calibration-report-details.model'
import { ReportListQueryParameter } from '../models/report-list-query.model'
import { ReportWorkOrderDetail } from '../models/report-work-order-detail.model'
import { WorkManagementReportQueryParameter } from '../models/work-management-report-filter.model'
import { FiltersApiData } from '../utils/utils'

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    private readonly url = `${environment.baseUrl}/api/${AppSettings.apiVersion}`

    constructor(private queryBuilderService: QueryBuilderService) { }

    public getCalibrationReportList(query: ReportListQueryParameter): Observable<HttpResponse<CalibrationReportDetails[]>> {
        query['fromDate'] = dateToLocalISOString(query.dateRange.from, true, true)
        query['toDate'] = dateToLocalISOString(query.dateRange.to, true, false)
        delete query.dateRange
        return this.queryBuilderService.get<CalibrationReportDetails[]>(`${this.url}/Calibrations`, query)
    }

    public getCalibrationReportDetail(workOrderId: string, fetchNotification = true): Observable<ReportWorkOrderDetail> {
        return this.queryBuilderService
            .get<ReportWorkOrderDetail>(`${this.url}/WorkOrders(${workOrderId})/Reports?fetchNotification=${fetchNotification}`)
            .pipe(
                map(response => response.body)
            )
    }

    public getCalibrationReportExcel(query: ReportListQueryParameter, plantCode: string): Observable<string> {
        query['plantCode'] = plantCode
        query['fromDate'] = dateToLocalISOString(query.dateRange.from, true, true)
        query['toDate'] = dateToLocalISOString(query.dateRange.to, true, false)
        delete query.dateRange
        return this.queryBuilderService
            .get<string>(`${this.url}/Report/CalibrationReport`, query)
            .pipe(map(response => response.body))
    }

    public rejectCalibration(workOrderId: string, equipmentId: string): Observable<number> {
        return this.queryBuilderService
            .put<number>(`${this.url}/WorkOrders(${workOrderId})/Equipment(${equipmentId})/Reject`)
            .pipe(
                map(response => response.status)
            )
    }

    public approveCalibration(workOrderId: string, equipmentId: string): Observable<number> {
        return this.queryBuilderService
            .put<number>(`${this.url}/WorkOrders(${workOrderId})/Equipment(${equipmentId})/Approve`)
            .pipe(
                map(response => response.status)
            )
    }

    public requestWorkOrderManagement(query: WorkManagementReportQueryParameter): Observable<any> {
        return this.queryBuilderService
            .get<string>(`${this.url}/Report/WorkManagement`, query)
    }

    public requestSiteDataReport(): Observable<HttpResponse<unknown>> {
        return this.queryBuilderService.get(`${this.url}/Report/SiteData`)
    }

    public getReportFiltersList(type = 'Calibration'): Observable<any> {
        return this.queryBuilderService
            .get<string>(`${this.url}/Preset/${type}`)
    }

    public saveReportFilters(presetData: FiltersApiData): Observable<any> {
        return this.queryBuilderService
            .post<any>(`${this.url}/Preset`, presetData)
    }

    public deleteReportFilters(id: number): Observable<any> {
        return this.queryBuilderService
            .delete<any>(`${this.url}/Preset(${id})`)
    }

    public editReportFilters(presetData: FiltersApiData): Observable<any> {
        return this.queryBuilderService
            .put<any>(`${this.url}/Preset`, presetData)
    }
}

import { Injectable } from '@angular/core'

import * as TemplateOptions from '@app/modules/equipment/models/default-template-options-values'
import { EquipmentTemplateDetailInput } from '@app/modules/equipment/models/equipment-template-detail-input.model'
import { UOM } from '@app/modules/shared/models/engineering-units/unit-of-measurement.model'
import { isNotAValue, safeDefaultObjGet, safeObjGet } from '@app/utils/app-utils.function'
import { CalculationType } from '../../models/calculation-type.model'
import { Process } from '../../models/process.model'

const LINEAR_ONLY_NUMBER_OF_POINTS = [1, 2]

@Injectable({
    providedIn: 'root'
})
export class SequentialTemplateValidationsService {

    public isValid(formDetails: object): boolean {
        const tolerance = safeObjGet(formDetails, 'tolerance') as EquipmentTemplateDetailInput
        const input = safeObjGet(formDetails, 'input') as EquipmentTemplateDetailInput
        const expected = safeObjGet(formDetails, 'expected') as EquipmentTemplateDetailInput
        const numberOfPoint = parseInt(
            safeDefaultObjGet(formDetails, 'numberOfPoints.point.key', 0),
            10
        )
        const process = safeObjGet(formDetails, 'process') as Process
        const calculationType = safeObjGet(formDetails, 'calculationType') as CalculationType

        if (isNotAValue(process.id) || isNotAValue(process.name) || numberOfPoint === 0) {
            return false
        }

        if (isNotAValue(tolerance.unitOfMeasurement) || isNotAValue(tolerance.value)) {
            return false
        }

        if (numberOfPoint === 1 && (
            isNotAValue(input.unitOfMeasurement) ||
            isNotAValue(input.maximumRange) ||
            isNotAValue(expected.unitOfMeasurement) ||
            isNotAValue(expected.maximumRange))
        ) {
            return false
        }

        if (numberOfPoint !== 1 && (
            isNotAValue(input.unitOfMeasurement) ||
            isNotAValue(input.maximumRange) ||
            isNotAValue(input.minimumRange) ||
            isNotAValue(expected.unitOfMeasurement) ||
            isNotAValue(expected.maximumRange) ||
            isNotAValue(expected.minimumRange))
        ) {
            return false
        }

        if (numberOfPoint !== 1 && (
            input.minimumRange > input.maximumRange ||
            expected.minimumRange > expected.maximumRange)
        ) {
            return false
        }

        if (LINEAR_ONLY_NUMBER_OF_POINTS.includes(numberOfPoint) && (calculationType.id !== TemplateOptions.CalculationTypes.linear.id)) {
            return false
        }

        return true
    }

    public isCalculationTypeOptionEnabled(formDetails: object): boolean {
        const numberOfPoint = parseInt(
            safeDefaultObjGet(formDetails, 'numberOfPoints.point.key', '0'),
            10
        )

        return !LINEAR_ONLY_NUMBER_OF_POINTS.includes(numberOfPoint)
    }

    public limitUOMType(
        expected: EquipmentTemplateDetailInput,
        tolerance: EquipmentTemplateDetailInput,
        percentageUOM: UOM
    ): UOM[] {
        if (expected?.unitOfMeasurement?.uomCode !== percentageUOM.uomCode && !tolerance) {
            return [
                expected.unitOfMeasurement,
                percentageUOM
            ]
        }

        if (tolerance?.unitOfMeasurement?.uomCode === percentageUOM.uomCode) {
            return [
                percentageUOM,
                expected.unitOfMeasurement
            ]
        }

        return [
            expected.unitOfMeasurement,
            percentageUOM
        ]
    }
}

<div [formGroup]="formGroup"
     class="em-c-field em-c-field--toggle">
    <div class="em-c-toggle">
        <ng-container *ngFor="let option of options">
            <input class="em-c-toggle__input em-u-is-vishidden"
                   type="radio"
                   [formControlName]="formControlPath"
                   [checked]="getDefaultOption() === option[key]"
                   [value]="returnOption(option)"
                   [id]="option[key] + formControlPath" />
            <label class="em-c-toggle__label {{ labelStyle }}"
                   [ngClass]="{
                    'options-disabled': isDisabled(option.disabled),
                    'options-selected-disabled': selected === option[key] && isDisabled(option.disabled)
                }"
                   (click)="select(option, option[key] + formControlPath)">
                {{ option[value] }}
            </label>
        </ng-container>
    </div>
</div>
import { createSelector } from '@ngrx/store'

import { WorkOrderState } from './work-order.reducer'

export const workOrderState = (state): WorkOrderState => state.workOrder

export const workOrderDetails = createSelector(workOrderState, workOrder => workOrder.details)
export const workOrderDetailIsLoading = createSelector(workOrderState, workOrder => workOrder.isLoading)

export const workOrderMainWorkCenter = createSelector(workOrderState, workOrder => workOrder.mainWorkCenterFilterState)
export const workOrderPlannerGroup = createSelector(workOrderState, workOrder => workOrder.plannerGroupFilterState)
export const workOrderPlantSection = createSelector(workOrderState, workOrder => workOrder.plantSectionFilterState)
export const workOrderMaintenancePlant = createSelector(workOrderState, workOrder => workOrder.maintenancePlantFilterState)
export const workOrderLocation = createSelector(workOrderState, workOrder => workOrder.locationFilterState)
export const workOrderType = createSelector(workOrderState, workOrder => workOrder.workOrderTypeFilterState)
export const isNotificationLoading = createSelector(workOrderState, workOrder => workOrder.isNotificationLoading)
export const notifications = createSelector(workOrderState, workOrder => workOrder.notifications)


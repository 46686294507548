import { FlamingoForm } from '@app/modules/flamingo/models/flamingo-form.model'
import {
    EquipmentFlamingoAction, GetEquipmentFlamingoFormMappingAction, GetEquipmentFlamingoFormMappingSuccessAction
} from '../actions/equipment-flamingo-form.actions'

export interface EquipmentFlamingoFormMappingState {
    flamingoForms: FlamingoForm[]
    isLoading: boolean
}

export const initialState: EquipmentFlamingoFormMappingState = {
    flamingoForms: [],
    isLoading: false
}

export function reducer(state = initialState, action: EquipmentFlamingoAction): EquipmentFlamingoFormMappingState {
    switch (action.type) {
        case GetEquipmentFlamingoFormMappingAction.type: {
            return {
                ...state,
                isLoading: true
            }
        }
        case GetEquipmentFlamingoFormMappingSuccessAction.type: {
            return {
                ...state,
                flamingoForms: action['forms'],
                isLoading: false
            }
        }
        default:
            return state
    }
}

import { Directive, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'

// The `@Directive() or @Component()` is required as of Ng 9 because
// this class implements `OnDestroy`.
// ----
// Some services and directives also extend from this class, not just component.
// As @Component() can extend @Directive() but vice-versa is not true,
// we need @Directive() here.
@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class SafeUnsubscriberComponent implements OnDestroy {
    subscriptions: Subscription[] = []

    ngOnDestroy(): void {
        this.unsubscribeAll()
    }

    protected addSubscription(newSubscription: Subscription): void {
        this.subscriptions.push(newSubscription)
    }

    protected addSubscriptions(newSubscriptions: Subscription[]): void {
        this.subscriptions.push(...newSubscriptions)
    }

    protected unsubscribeAll(): void {
        this.subscriptions.forEach((subscription: Subscription) => {
            if (subscription && !subscription.closed) {
                subscription.unsubscribe()
            }
        })
    }

}

import { HttpErrorResponse } from '@angular/common/http'

import { Modal, ModalType } from '@app/modules/modal-container/models/modal.model'
import { isNotAValue } from '@app/utils/app-utils.function'
import { get } from 'lodash'

export const createErrorModal = (error: string): Modal => {
    return {
        title: 'ERROR',
        icon: 'circle-exclamation-mark',
        body: `<p>${error}</p>`,
        close: true,
        footer: false,
        type: ModalType.Generic
    } as Modal
}

export const getErrorMessage = (response: HttpErrorResponse) => {
    return get(response, 'error.message')
}

export const composeTechnicalHTMLResponse = (errorMessage: string): string => {
    if (isNotAValue(errorMessage)) {
        return ''
    }

    return `
        <div class="global-error-handler">
            <p>${errorMessage}</p>
        </div>
    `
}



import { createAction, props, union } from '@ngrx/store'

import { SapDataSync } from '@app/models/sap-data-sync.model'

/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match */

const actionPrefix = '[SapDataSync]'

export const GetLatestSyncDateAction =
    createAction(`${actionPrefix} Get Latest SAP sync date`)
export const GetLatestSyncDateSuccessAction =
    createAction(`${actionPrefix} Get Latest SAP sync date Success`, props<SapDataSync>())
export const GetLatestSyncDateFailureAction =
    createAction(`${actionPrefix} Get Latest SAP sync date Failure`)

const all = union({
    GetLatestSyncDateAction,
    GetLatestSyncDateSuccessAction,
    GetLatestSyncDateFailureAction,
})

export type SapDataSyncAction = typeof all

import { Action } from '@ngrx/store'

import { EquipmentHistory } from '@app/modules/calibration/models/equipment-history.model'

export enum ActionType {
    GetEquipmentHistory = '[Equipment History] Get Equipment History',
    GetEquipmentHistorySuccess = '[Equipment History] Get Equipment History Success'
}

export class GetEquipmentHistoryAction implements Action {
    readonly type = ActionType.GetEquipmentHistory

    constructor(public payload: string) { }
}

export class GetEquipmentHistorySuccessAction implements Action {
    readonly type = ActionType.GetEquipmentHistorySuccess

    constructor(public payload: EquipmentHistory) { }
}

export type EquipmentHistoryAction = GetEquipmentHistoryAction
    | GetEquipmentHistorySuccessAction

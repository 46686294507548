export class Option {
    key: string
    value: string
    disabled?: boolean
}

export enum OptionReturnType {
    key = 1,
    value = 2,
    object = 3
}

import { isNotAValue } from '../app-utils.function'

export interface MDLink {
    full: string
    linkText: string
    url: string
    title: string
    others: string
}

const mdLinkRegMatch = (value: string) => /\[(.+)\]\((https?:\/\/[^\s]+)(?: "(.+)")?\)|(https?:\/\/[^\s]+)/ig.exec(value)

/**
 * Check if the value has a markdown format of a link
 */
export const mdIsALink = (value: string): boolean => {
    const result = mdLinkRegMatch(value)

    return !isNotAValue(result?.[1]) && !isNotAValue(result?.[2])
}

/**
 * Get markdown link structure
 */
export const mdLink = (value: string): MDLink => {
    const result = mdLinkRegMatch(value)

    return {
        full: result?.[0],
        linkText: result?.[1],
        url: result?.[2],
        title: result?.[3],
        others: result?.[4]
    }
}

export enum ActionType {
    Connected = '[Connection] Connected',
    Disconnected = '[Connection] Disconnected'
}

export class ConnectedAction {
    readonly type = ActionType.Connected
}

export class DisconnectedAction {
    readonly type = ActionType.Disconnected
}

export type ConnectionAction = ConnectedAction | DisconnectedAction

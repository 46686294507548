import { CalculationType } from './calculation-type.model'
import { TestingDirection } from './testing-direction.enum'

export const enableDisableOptions = [
    { key: 'true', value: 'Enable' },
    { key: 'false', value: 'Disable' }
]

export const numberOfPointRepeatabilityOptions = [
    { key: 2, value: '2 Point' },
    { key: 3, value: '3 Point' },
    { key: 4, value: '4 Point' },
    { key: 5, value: '5 Point' },
    { key: 6, value: '6 Point' },
    { key: 7, value: '7 Point' },
    { key: 8, value: '8 Point' },
    { key: 9, value: '9 Point' },
    { key: 10, value: '10 Point' }
]

export const numberOfPointAnalyzerMultiPointOptions = [...Array(30)].map((_, index) => {
    const numOfPoints = index + 1
    return { key: numOfPoints, value: `${numOfPoints} Point` }
})


export const numberOfPointTemperatureOptions = [
    { key: '3', value: '3 Point' },
    { key: '5', value: '5 Point' }
]

export const numberOfPointSequentialOptions = [
    { key: '2', value: '2 Point' },
    { key: '3', value: '3 Point' },
    { key: '4', value: '4 Point' },
    { key: '5', value: '5 Point' }
]

export const yesNoOption = [
    { key: 'true', value: 'Enabled' },
    { key: 'false', value: 'Disabled' }
]

export const isRequiredOption = [
    { key: 'true', value: 'Required' },
    { key: 'false', value: 'Optional' }
]

export const testingDirectionOptions = [
    { key: TestingDirection.NotAvailable, value: 'N/A' },
    { key: TestingDirection.Increasing, value: 'Increasing' },
    { key: TestingDirection.Decreasing, value: 'Decreasing' }
]

export class CalculationTypes {
    public static readonly linear: CalculationType = { id: 1, name: 'Linear' }
    public static readonly squareRootAtInput: CalculationType = { id: 2, name: 'Square root at input' }
    public static readonly squareRootAtOutput: CalculationType = { id: 3, name: 'Square root at output' }
    public static readonly all = [CalculationTypes.linear, CalculationTypes.squareRootAtInput, CalculationTypes.squareRootAtOutput]

}

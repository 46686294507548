import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { AppMonitoringService } from '@app/services/app-monitoring.service'

@Injectable()
export class HTTPErrorHandlerInterceptor implements HttpInterceptor {

    constructor(private appMonitoringService: AppMonitoringService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((err: HttpErrorResponse) => {
                err['__ErrorHandled'] = true
                if (err.status === 401) {
                    // TODO: try refresh the token...
                    return throwError(err)
                } else {
                    this.appMonitoringService.instance?.trackException({
                        error: err,
                        properties: {
                            source: 'HTTP_ERROR_HANDLER_INTERCEPTOR',
                            httpResponseStatus: err.status,
                            httpResponseStatusText: err.statusText,
                            httpResponseMessage: err.message,
                            httpRequestBody: req.body
                        }
                    })
                    // Rethrow err so upper-level error handling code still work
                    this.appMonitoringService?.logErrorMessageToConsole(err, 'HTTP_ERROR_HANDLER')
                    return throwError(err)
                }
            })
        )
    }

}

import { QuerySummary } from '@app/modules/shared/models/query-summary.model'
import { SapEquipmentAction, ActionType } from '../actions/sap-equipment.actions'
import { SapEquipment } from '@app/modules/equipment/models/sap-equipment.model'
import { SapEquipmentQueryParameter } from '@app/modules/equipment/models/sap-equipment-query-parameter.model'

export interface SapEquipmentState {
    sapEquipmentList: SapEquipment[]
    sapEquipmentListQuery: QuerySummary<SapEquipmentQueryParameter>
}

export const initialState: SapEquipmentState = {
    sapEquipmentList: [],
    sapEquipmentListQuery: {
        queryParameters: {
            equipmentTag: null,
            planningPlant: null,
            usrStatus: null,
            isTestEquipment: false,
            sortBy: 'equipmentTag',
            page: '1',
            perPage: '10'
        },
        currentPage: 1,
        itemPerPage: '10',
        totalItemCount: 0
    }
}

export function reducer(state = initialState, action: SapEquipmentAction): SapEquipmentState {
    switch (action.type) {
        case ActionType.GetSAPEquipmentListSuccess:
            return {
                ...state,
                sapEquipmentList: action.payload.result,
                sapEquipmentListQuery: action.payload.querySummary
            }
        default:
            return state
    }
}

import { SlideUpOverlay } from '@app/modules/shared/models/slide-up-overlay.modal'
import { ActionType, ToggleOverlay } from './overlay.actions'

export interface OverlayToggleState {
    slideUpOverlay: SlideUpOverlay
}

const initialState: OverlayToggleState = {
    slideUpOverlay: {
        visibility: false,
        id: null
    }
}

export function reducer(state = initialState, action: ToggleOverlay): OverlayToggleState {
    switch (action.type) {
        case ActionType.SlideOverlay:
            return {
                ...state,
                slideUpOverlay: action.payload
            }
        default:
            return state
    }
}

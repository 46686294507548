import { SimpleChange, SimpleChanges } from '@angular/core'

type SimpleChangeCallback = (change: SimpleChange) => void
type SimpleChangesCallback = (changes: SimpleChanges) => void
interface Do<T> { do: (fn: T) => SimpleChangesWrapper }

class SimpleChangesWrapper {

    constructor(public changes) { }

    /**
     * Run function in `.do()` if `propName` exist in `changes`.
     * The callbacks will be passed the specific `SimpleChange`.
     */
    public forChangeIn(propName: string): Do<SimpleChangeCallback> {
        const change = this.changes[propName]
        if (change) {
            return {
                do: (fn: any) => {
                    fn(change)
                    return this
                }
            }
        }

        return { do: () => this }
    }

    /**
     * Run callback with **the original `changes`** if **any** of `propNames` exist
     * in `changes`.
     */
    public forChangeInAny(propNames: string[]): Do<SimpleChangesCallback> {
        let doFn = (_fn) => this
        propNames.some(propName => {
            if (this.changes[propName]) {
                doFn = (fn) => {
                    fn(this.changes)
                    return this
                }
                return true
            }
        })

        return { do: doFn }
    }

    /**
     * Run callback with **the original `changes`** if **all** of `propNames` exist
     * in `changes`.
     */
    public forChangeInAll(propNames: string[]): Do<SimpleChangesCallback> {
        let doFn = (fn) => {
            fn(this.changes)
            return this
        }
        propNames.some(propName => {
            if (!this.changes[propName]) {
                doFn = (_fn) => this
                return true
            }
        })

        return { do: doFn }
    }

}

/**
 * Provide utilities functions for dealing with `SimpleChanges`
 * passed by `ngOnChanges()`.
 */
export const scw = (changes: SimpleChanges) => new SimpleChangesWrapper(changes)

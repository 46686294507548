import { NgClass, NgIf } from '@angular/common'
import { Component, Input } from '@angular/core'
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms'

@Component({
    selector: 'app-admin-form-row',
    templateUrl: './admin-form-row.component.html',
    styleUrls: ['./admin-form-row.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgClass,
        NgIf
    ]
})
export class AdminFormRowComponent {
    @Input() label: string
    @Input() description: string
    @Input() formGroup: UntypedFormGroup
}

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

import { SapEquipment } from '@app/modules/equipment/models/sap-equipment.model'
import { TestEquipment, TestEquipmentListItem, TestEquipmentQueryParameter } from '@app/modules/test-equipment/models/test-equipment.model'
import { ActionType, TestEquipmentActionUnion } from './test-equipment.actions'

export interface TestEquipmentState extends EntityState<TestEquipmentListItem> {
    queryParameters: TestEquipmentQueryParameter
    selectedSingleTestEquipment: TestEquipment,
    selectedMultipleTestEquipment: TestEquipment[],
    searchTestEquipment: SapEquipment[],
    searchKey: string
}

export const adapter: EntityAdapter<TestEquipmentListItem>
    = createEntityAdapter<TestEquipmentListItem>({
        selectId: testEquipment => testEquipment.equipmentId
    })

const initialState: TestEquipmentState = adapter.getInitialState({
    queryParameters: {
        equipmentTag: undefined,
        includeExpired: true,
        includeInactive: true,
        sortBy: 'equipmentTag',
        page: '1',
        perPage: '10'
    } as TestEquipmentQueryParameter,
    selectedSingleTestEquipment: undefined,
    selectedMultipleTestEquipment: [],
    searchTestEquipment: [],
    searchKey: undefined
})

export function reducer(state = initialState, action: TestEquipmentActionUnion): TestEquipmentState {
    switch (action.type) {
        case ActionType.CreateTestEquipmentSuccess:
            return {
                ...adapter.addOne({
                    ...action.payload,
                    isChecked: false,
                    isNew: true,
                    status: {
                        id: 1,
                        name: 'Active'
                    }
                }, state)
            }
        case ActionType.GetTestEquipmentSuccess:
            return {
                ...adapter.setAll(action.payload.map(testEquipment => {
                    return {
                        ...testEquipment,
                        isChecked: false,
                        isNew: false,
                        status: {
                            id: testEquipment.isActive ? 1 : 2,
                            name: testEquipment.isActive ? 'Active' : 'Inactive'
                        }
                    }
                }), state)
            }
        case ActionType.GetTestEquipmentFailure:
            return {
                ...adapter.removeAll(state)
            }
        case ActionType.UpdateTestEquipmentSuccess:
            return adapter.updateOne({
                id: action.payload.equipmentId,
                changes: action.payload
            }, state)
        case ActionType.FilterTestEquipment:
            return {
                ...state,
                queryParameters: action.payload
            }
        case ActionType.SearchNewTestEquipmentSuccess:
            return {
                ...state,
                searchTestEquipment: action.payload.result,
                searchKey: action.payload.querySummary.queryParameters.equipmentTag
            }
        case ActionType.TouchNewTestEquipment:
            return adapter.updateMany(
                action.payload.map(eq => {
                    return {
                        id: eq.equipmentId, changes: { ...eq, isNew: false }
                    }
                }), state)
        default:
            return state
    }
}

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors()

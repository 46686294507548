import { HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators'

import { QueryBuilderService } from '@app/services/query-builder.service'
import { environment } from '@environments/environment'
import { AppSettings } from '@settings/app.settings'
import { EquipmentTemplateMapping } from '../models/equipment-template-mapping.model'
import { Equipment } from '../models/equipment.model'
import { ServiceFluid } from '@app/modules/equipment/models/equipment-list-api-response.model'

@Injectable({
    providedIn: 'root'
})
export class EquipmentService {
    private readonly equipmentBaseUrl = `${environment.baseUrl}/api/${AppSettings.apiVersion}/Equipments`

    constructor(private queryBuilderService: QueryBuilderService) {
    }

    public getEquipment(equipmentId: string): Observable<Equipment> {
        return this.queryBuilderService
            .get<Equipment>(`${this.equipmentBaseUrl}(${equipmentId})`)
            .pipe(map(response => {
                return response.body
            }))
    }

    public getEquipments(
        search = '',
        plantCode = '',
        expandTemplate = false,
        criticality = '',
        plannerGroup = '',
        mainWorkCenter = '',
        maintenancePlant = '',
        plantSection = '',
        location = '',
        category = '',
        classification = '',
        templateTypeIds = '',
        sortBy = '',
        currentPage = 1,
        itemPerPage = 'all'
    ): Observable<HttpResponse<EquipmentTemplateMapping[]>> {
        return this.queryBuilderService
            .get<EquipmentTemplateMapping[]>(
                this.equipmentBaseUrl,
                {
                    search,
                    plantCode,
                    expandTemplate,
                    criticality,
                    plannerGroup,
                    mainWorkCenter,
                    maintenancePlant,
                    plantSection,
                    location,
                    category,
                    classification,
                    templateTypeIds,
                    sortBy,
                    currentPage,
                    itemPerPage
                })
            .pipe(map(response => {
                return response
            }))
    }

    public createEquipment(equipmentObject): Observable<HttpResponse<Equipment>> {
        return this.queryBuilderService
            .post<Equipment>(this.equipmentBaseUrl, equipmentObject)
            .pipe(map(response => {
                return response
            }))
    }

    public updateEquipmentNote(payload: Equipment): Observable<HttpResponse<Equipment>> {
        const equipmentURL = `${this.equipmentBaseUrl}(${payload.equipmentId})`
        return this.queryBuilderService
            .put<Equipment>(equipmentURL, payload)
            .pipe(map(response => response))
    }

    public getEquipmentFilter(filterType: string): Observable<HttpResponse<string[]>> {
        const filterUrl = `${environment.baseUrl}/api/${AppSettings.apiVersion}/Equipments/` + filterType
        return this.queryBuilderService
            .get<string[]>(filterUrl)
            .pipe(map(response => response))
    }

    public getServiceFluidOptionsList(): Observable<HttpResponse<ServiceFluid[]>> {
        const serviceFluidUrl = `${environment.baseUrl}/api/${AppSettings.apiVersion}/AllServiceFluid`
        return this.queryBuilderService
            .get<ServiceFluid[]>(serviceFluidUrl)
            .pipe(map(response => {
                return response
            }))
    }
}

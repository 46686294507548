// This `dev` environment is for the Development server deployment.
// Don't change it for your local development on your machine.
// Use `.local-dev` or `.local-dev.local-api` instead

import 'zone.js/plugins/zone-error'

import { environment as base } from './environment.base'

export const environment = {
    ...base,
    development: true,
    serviceWorker: true,
    name: 'MRMA DEV',
    baseUrl: 'https://apid.mrma.exxonmobil.com',
    apiVersion: 'v2',
    auth: {
        clientId: '6a17c0cd-aeea-46ec-b522-cfca53608ac8',
        authority: 'https://login.microsoftonline.com/EMCloudAD.onmicrosoft.com/',
        validateAuthority: true,
        navigateToLoginRequestUrl: true,
        redirectUri: 'https://d.mrma.exxonmobil.com',
        postLogoutRedirectUri: 'https://d.mrma.exxonmobil.com',
        scopes: [
            'api://c0cad19b-3e21-4cc0-a75f-6287dc64a1e6/MRMADev/Read',
            'api://c0cad19b-3e21-4cc0-a75f-6287dc64a1e6/MRMADev/Write'
        ]
    },
    flamingoForm: {
        baseUrl: 'https://api.dev.flamingo.xom.cloud/api',
        scopes: [
            'api://79489178-e02a-40d2-b3df-e4f3a100d52b/flamingo-dev/User'
        ],
        environment: 'dev'
    },
    features: {
        'email-subscription': true
    },
    debug: {
        logLevel: 'INFO',
        logCDTime: true,
        enableClassProfiler: false,
        enableFnProfiler: false
    },
    functionAppUrl: 'https://mrma-funct-dev.azurewebsites.net'
}

import { createEntityAdapter, EntityState } from '@ngrx/entity'

import { MaintenancePlan, MaintenancePlanExpanded } from '@app/modules/shared/models/maintenance-plan.model'
import { deepCopy } from '@app/utils/app-utils.function'
import {
    GetMaintenanceItemLongTextSuccess, GetMaintenancePlanDetailAction, GetMaintenancePlanDetailFailedAction, GetMaintenancePlanDetailSuccessAction,
    GetMaintenancePlanListAction, GetMaintenancePlanListSuccessAction, GetMaintenancePlanLongTextSuccess, MaintenancePlanAction
} from './maintenance-plan.actions'

export interface MaintenancePlanState extends EntityState<MaintenancePlan> {
    totalMaintenancePlanCount: number
    isListLoading: boolean
    selectedPlan?: {
        hasError: boolean
        maintenancePlan?: MaintenancePlanExpanded
    }
}

export const adapter = createEntityAdapter<MaintenancePlan>({
    selectId: maintenancePlanList => maintenancePlanList.maintenancePlanNumber
})

const initialMaintenancePlanState: MaintenancePlanState = adapter.getInitialState({
    totalMaintenancePlanCount: -1,
    isListLoading: true,
    lastFullSyncDate: null
})

export const reducer = (state: MaintenancePlanState = initialMaintenancePlanState, action: MaintenancePlanAction): MaintenancePlanState => {
    switch (action.type) {
        case GetMaintenancePlanListAction.type: {
            return {
                ...state,
                isListLoading: true
            }
        }
        case GetMaintenancePlanListSuccessAction.type:
            const { totalItemCount } = action.summary
            return adapter.addMany(action.content.filter(e => !!e), {
                ...state,
                isListLoading: false,
                totalMaintenancePlanCount: totalItemCount
            })

        case GetMaintenancePlanDetailAction.type:
            return {
                ...state,
                selectedPlan: undefined
            }
        case GetMaintenancePlanDetailFailedAction.type:
            return {
                ...state,
                selectedPlan: {
                    hasError: true
                }
            }
        case GetMaintenancePlanDetailSuccessAction.type:
            const maintenancePlan = action.maintenancePlan
            return {
                ...state,
                selectedPlan: {
                    hasError: false,
                    maintenancePlan
                }
            }

        case GetMaintenancePlanLongTextSuccess.type:
            {
                const { longText, maintenancePlanNumber } = action
                const newSelectedPlan = deepCopy(state.selectedPlan)
                const selectedPlanNumber = newSelectedPlan.maintenancePlan?.maintenancePlanNumber
                if (selectedPlanNumber !== maintenancePlanNumber) {
                    console.warn(`maintenancePlanNumber mismatch! ${selectedPlanNumber} : ${maintenancePlanNumber}`)
                    return state
                }

                newSelectedPlan.maintenancePlan.longText = longText || '-'
                return {
                    ...state,
                    selectedPlan: newSelectedPlan
                }
            }

        case GetMaintenanceItemLongTextSuccess.type:
            {
                const { longText, maintenanceItemNumber } = action
                const newSelectedPlan = deepCopy(state.selectedPlan)
                const maintenanceItems = newSelectedPlan.maintenancePlan?.maintenanceItems
                const index = maintenanceItems?.findIndex(maintenanceItem =>
                    maintenanceItem.maintenanceItemNumber === maintenanceItemNumber
                )
                if (index < 0) {
                    console.warn('maintenanceItemNumber not found in selected plan!')
                    return state
                }
                maintenanceItems[index].longText = longText || '-'

                return {
                    ...state,
                    selectedPlan: newSelectedPlan
                }
            }

        default:
            return state
    }
}

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors()

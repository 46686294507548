import { CompareComponent } from '@app/modules/equipment/components/equipment-template/template-forms/compare/compare.component'
import { SequentialComponent } from '@app/modules/equipment/components/equipment-template/template-forms/sequential/sequential.component'
import { TemperatureComponent } from '@app/modules/equipment/components/equipment-template/template-forms/temperature/temperature.component'
import { ThirdPartyComponent } from '@app/modules/equipment/components/equipment-template/template-forms/third-party/third-party.component'
import { WeightComponent } from '@app/modules/equipment/components/equipment-template/template-forms/weight/weight.component'
import { MultiPointComponent } from '../components/equipment-template/template-forms/multi-point/multi-point.component'
import { OnePointComponent } from '../components/equipment-template/template-forms/one-point/one-point.component'
import { SafetyValveComponent } from '../components/equipment-template/template-forms/safety-valve/safety-valve.component'
import { TemplateTypeEnum } from './template-type.enum'
import {
    AnalyzerComponent
} from '@app/modules/equipment/components/equipment-template/template-forms/analyzer/analyzer.component'
import { PassFailCheckComponent } from '../components/equipment-template/template-forms/pass-fail-check/pass-fail-check.component'
import { RepeatabilityComponent } from '../components/equipment-template/template-forms/repeatability/repeatability.component'

export class TemplateTypeMapping {
    type: TemplateTypeEnum
    component: any
}

export const templateTypeMapped = [
    { type: TemplateTypeEnum[TemplateTypeEnum.thirdParty], component: ThirdPartyComponent },
    { type: TemplateTypeEnum[TemplateTypeEnum.weightFourPoint], component: WeightComponent },
    { type: TemplateTypeEnum[TemplateTypeEnum.weightSixPoint], component: WeightComponent },
    { type: TemplateTypeEnum[TemplateTypeEnum.compareTwoPoint], component: CompareComponent },
    { type: TemplateTypeEnum[TemplateTypeEnum.compareThreePoint], component: CompareComponent },
    { type: TemplateTypeEnum[TemplateTypeEnum.sequential], component: SequentialComponent },
    { type: TemplateTypeEnum[TemplateTypeEnum.temperature], component: TemperatureComponent },
    { type: TemplateTypeEnum[TemplateTypeEnum.leakCheck], component: ThirdPartyComponent },
    { type: TemplateTypeEnum[TemplateTypeEnum.passFailCheck], component: PassFailCheckComponent },
    { type: TemplateTypeEnum[TemplateTypeEnum.repeatability], component: RepeatabilityComponent },
    { type: TemplateTypeEnum[TemplateTypeEnum.onePoint], component: OnePointComponent },
    { type: TemplateTypeEnum[TemplateTypeEnum.safetyValve], component: SafetyValveComponent },
    { type: TemplateTypeEnum[TemplateTypeEnum.multiPoint], component: MultiPointComponent },
    { type: TemplateTypeEnum[TemplateTypeEnum.analyzer], component: AnalyzerComponent }
]

import { Injectable } from '@angular/core'

import { Equipment } from '../../models/equipment.model'
import { TemplateTypeEnum } from '../../models/template-type.enum'
import { Process } from '@app/modules/equipment/models/process.model'
import { EquipmentTemplateDetailInput } from '@app/modules/equipment/models/equipment-template-detail-input.model'
import { safeObjGet, isNotAValue } from '@app/utils/app-utils.function'

@Injectable({
    providedIn: 'root'
})
export class CompareTemplateValidationService {
    public isValid(formDetails: object, templateType: TemplateTypeEnum): boolean {

        const process = safeObjGet(formDetails, 'process') as Process
        if (isNotAValue(process.id) || isNotAValue(process.name)) {
            return false
        }

        const tolerance = safeObjGet(formDetails, 'tolerance') as EquipmentTemplateDetailInput
        if (isNotAValue(tolerance.unitOfMeasurement) || isNotAValue(tolerance.value)) {
            return false
        }

        const compareEquipment = safeObjGet(formDetails, 'compareEquipment') as Equipment[]
        if (!compareEquipment) {
            return false
        }

        if (templateType === TemplateTypeEnum['2 Point Compare'] &&
            isNotAValue(compareEquipment[0])) {
            return false
        }

        if (templateType === TemplateTypeEnum['3 Point Compare'] && (
            isNotAValue(compareEquipment[0]) ||
            isNotAValue(compareEquipment[1]))) {
            return false
        }

        if (templateType === TemplateTypeEnum['3 Point Compare'] && (
            compareEquipment[0].equipmentId === compareEquipment[1].equipmentId)) {
            return false
        }

        return true
    }
}

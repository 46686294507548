import { calibrationResultStatusValue } from '@app/modules/calibration/models/calibration-result-status.model'
import { PMStatusEnum } from '@app/modules/calibration/models/calibration-status.enum'
import { mrmaStatus } from '@app/modules/shared/models/mrma-status-label.model'
import { ReportListQueryParameter } from '../models/report-list-query.model'

export interface ReportFilterField {
    name: ReportFiltersName
    value: any[]
}

export interface ReportFilterFields {
    reviewStatus: ReportFilterField,
    pmStatus: ReportFilterField,
    pmResult: ReportFilterField,
    pmPerformedBy: ReportFilterField,
    templateTypes: ReportFilterField,
    workOrderType: ReportFilterField
}

export interface CalibrationPresets {
    id?: number
    name?: string
    selected?: boolean
    childFilter?: {
        queryParams: ReportListQueryParameter
        reportFilters: ReportFilterFields
    }
}

export interface ChildFiltersUnderPreset {
    workOrderFilters: any,
    reportFilters: ReportFilterFields
}

export type ReportFiltersName = 'Review Status' | 'PM Status' | 'PM Result' | 'PM Performed By' | 'PM Performed Date' | 'Templates' | 'Work Order Type'

export const defaultReportFilters: ReportFilterFields = {
    templateTypes: {
        name: 'Templates',
        value: []
    },
    reviewStatus: {
        name: 'Review Status',
        value: mrmaStatus.reviewStatus
    },
    pmStatus: {
        name: 'PM Status',
        value: [
            {
                id: PMStatusEnum.Draft,
                name: 'Draft'
            },
            {
                id: PMStatusEnum.Completed,
                name: 'Completed'
            }
        ]
    },
    pmResult: {
        name: 'PM Result',
        value: [
            calibrationResultStatusValue.none,
            calibrationResultStatusValue.passed,
            calibrationResultStatusValue.passedAdjustedPassed,
            calibrationResultStatusValue.failed,
            calibrationResultStatusValue.failedAdjustedPassed,
            calibrationResultStatusValue.deferred,
            calibrationResultStatusValue.notTested
        ]
    },
    pmPerformedBy: {
        name: 'PM Performed By',
        value: []
    },
    workOrderType: {
        name: 'Work Order Type',
        value: []
    }
}


export const calibrationPresetNameErrorMessage = 'Preset name already exists.'

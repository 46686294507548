import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { combineLatest } from 'rxjs'

import { AppPolicyType } from '@app/models/app-policy/policy-type.enum'
import { AppState } from '@app/store/app.store'
import { currentUser, selectedPlant } from '@app/store/identity/identity.selectors'
import { UserProfile } from '../models/user.model'
import { AppPolicy } from './app-policies.service'

@Injectable({
    providedIn: 'root'
})
export class PolicyCheckerService {
    private user: UserProfile
    private plantCode: string

    constructor(
        private appPolicy: AppPolicy,
        private store: Store<AppState>
    ) {
        this.init()
    }

    public isValid(policyType: AppPolicyType): boolean {
        return this.appPolicy.checkPolicy(policyType, this.user, this.plantCode)
    }

    private init(): void {
        combineLatest([
            this.store.select(currentUser),
            this.store.select(selectedPlant)
        ]).subscribe(([user, plantCode]) => {
            this.user = user
            this.plantCode = plantCode
        })
    }
}

import { HttpEvent, HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { environment } from '@environments/environment'
import { AppSettings } from '@settings/app.settings'
import { QueryBuilderService } from './query-builder.service'

@Injectable({
    providedIn: 'root'
})
export class FileDownloadService {
    private readonly url = `${environment.baseUrl}/api/${AppSettings.apiVersion}`

    constructor(private queryBuilderService: QueryBuilderService) { }

    public downloadFile(response: HttpResponse<Blob>): void {
        const fileName = this.getFileNameFromHeader(response.headers.get('content-disposition'))
        const contentType = response.headers.get('content-type')
        const downloadLink = document.createElement('a')
        downloadLink.href = window.URL.createObjectURL(new Blob([response.body], { type: contentType }))
        downloadLink.setAttribute('download', fileName)
        document.body.appendChild(downloadLink)
        downloadLink.click()
        downloadLink.parentNode.removeChild(downloadLink)
    }

    public downloadReportFile(fileName: string): Observable<HttpEvent<Blob>> {
        return this.queryBuilderService
            .getFileWithProgress(`${this.url}/Report(${fileName})`)
    }

    private getFileNameFromHeader(contentDispositionHeader: string): string {
        return contentDispositionHeader.split('filename=')[1].split(';')[0].replace(/"/g, '').trim()
    }
}

<div class="em-c-field em-js-typeahead"
     [ngClass]="{ 'em-is-disabled': isDisabled }">
    <div class="em-c-input test-equipment-remove-button em-u-font-style-regular"
         [ngClass]="{ 'custom-disable': isDisabled }"
         (click)="removeSelf()"
         *ngIf="showRemoveButton">
        <span class="test-equipment-remove-span noselect">Remove</span>
    </div>
    <input #input
           type="text"
           class="em-c-input em-js-typeahead em-u-font-style-regular"
           placeholder="{{ getPlaceholderMessage() }}"
           [ngModel]="searchText$ | async"
           (ngModelChange)="searchText.next($event)"
           [disabled]="isDisabled"
           (focus)="setFocusState(true)" />
    <div class="em-c-input em-js-typeahead mrma-itempicker-selected em-u-font-style-regular"
         *ngIf="selectedTestEquipment"
         (click)="clearSelection()">
        <span class="em-c-typeahead__suggestion mrma-itempicker-name"
              title="{{ selectedTestEquipment.equipmentTag }} ({{ selectedTestEquipment.description }})">
            {{ selectedTestEquipment.equipmentTag }} ({{ selectedTestEquipment.description }})
        </span>
    </div>
    <div class="em-c-field__menu em-js-typeahead-menu"
         [ngClass]="{ 'em-is-active': isReadyToShowList() }"
         (focus)="setFocusState(true)">
        <ul #itemsList
            class="em-c-typeahead-list"
            (scroll)="onListScroll($event)">
            <li class="em-c-typeahead-list__item"
                *ngFor="let testEquipment of filteredTestEquipments"
                (click)="selectTestEquipment(testEquipment)"
                [ngClass]="{ 'em-is-disabled': isTestEquipmentNeededToBeDisabled(testEquipment) }">
                <span class="em-c-typeahead__suggestion"
                      title="{{ testEquipment.equipmentTag }} ({{ testEquipment.description }})">
                    {{ testEquipment.equipmentTag }} ({{ testEquipment.description }})
                </span>
            </li>
        </ul>
    </div>
    <div class="mrma-itempicker-remove-button"
         (click)="clearSelection()"
         [hidden]="!selectedTestEquipment || isDisabled">
        <svg class="em-c-icon em-c-icon--small">
            <use xmlns:xlink="http://www.w3.org/1999/xlink"
                 xlink:href="./assets/unity-1.2.0/images/24/em-icons.svg#icon-x"></use>
        </svg>
    </div>
</div>

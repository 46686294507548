import { ConnectionAction, ActionType } from './connection.actions'

export interface ConnectionState {
    isOnline: boolean
}

const initialState: ConnectionState = {
    isOnline: true
}

export function reducer(state = initialState, action: ConnectionAction): ConnectionState {
    switch (action.type) {
        case ActionType.Connected:
            return { ...state, isOnline: true }
        case ActionType.Disconnected:
            return { ...state, isOnline: false }
        default:
            return state
    }
}

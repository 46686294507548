// the FilterType's value MUST be the same as URL path
export enum EquipmentFilterUrl {
    Criticality = 'Criticality',
    PlannerGroup = 'PlannerGroup',
    MainWorkCenter = 'MainWorkCenter',
    MaintenancePlant = 'MaintenancePlant',
    PlantSection = 'PlantSection',
    Location = 'Location',
    Category = 'Category',
    Classification = 'Classification'
}

import { Injectable, OnDestroy } from '@angular/core'
import { Store } from '@ngrx/store'
import { BehaviorSubject, Subscription } from 'rxjs'
import { tap } from 'rxjs/operators'

import { UserProfile } from '@app/models/user.model'
import { PermissionService } from '@app/modules/shared/services/permission.service'
import { AppState } from '@app/store/app.store'
import { currentUser } from '@app/store/identity/identity.selectors'

@Injectable({
    providedIn: 'root'
})
export class IdentityService implements OnDestroy {

    public readonly currentIdentity$: BehaviorSubject<UserProfile> = new BehaviorSubject(null)

    // TODO: This could probably be simplify somewhat...
    public readonly isCurrentUserAnAdmin$: BehaviorSubject<boolean> = new BehaviorSubject(false)
    public readonly isCurrentUserASupport$: BehaviorSubject<boolean> = new BehaviorSubject(false)
    public readonly isCurrentUserAReviewer$: BehaviorSubject<boolean> = new BehaviorSubject(false)
    public readonly isCurrentUserATechnician$: BehaviorSubject<boolean> = new BehaviorSubject(false)
    public readonly isCurrentUserAnEngineer$: BehaviorSubject<boolean> = new BehaviorSubject(false)

    /**
     * @deprecated - this is not async optimized. Using it may prevent some optimization, resulting
     * in slower UI.
     *
     * Use the `currentIdentity$` instead.
     *
     * ** IMPORTANT ** while "FindAllReferences" does not show any result, this object is being accessed
     * in multiple places through the "safeObjGet() and safeDeafaultObjGet()".
     * As such, we cannot remove this yet.
     */
    public currentIdentity: UserProfile

    private storeSub: Subscription

    constructor(private store: Store<AppState>, private permissionService: PermissionService) {
        this.storeSub = this.store.select(currentUser).pipe(
            tap(userProfile => this.currentIdentity = userProfile),
            tap(userProfile => {
                this.currentIdentity$.next(userProfile)

                if (userProfile) {
                    this.isCurrentUserAnAdmin$.next(this.permissionService.isAdmin(userProfile, userProfile.preferredPlantCode))
                    this.isCurrentUserASupport$.next(this.permissionService.isSupport(userProfile))
                    this.isCurrentUserAReviewer$.next(this.permissionService.isReviewer(userProfile, userProfile.preferredPlantCode))
                    this.isCurrentUserATechnician$.next(this.permissionService.isTechnician(userProfile, userProfile.preferredPlantCode))
                    this.isCurrentUserAnEngineer$.next(this.permissionService.isEngineer(userProfile, userProfile.preferredPlantCode))
                }
            })
        ).subscribe()
    }

    ngOnDestroy(): void {
        if (this.storeSub) {
            this.storeSub.unsubscribe()
        }
    }
}

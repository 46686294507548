import { Injectable } from '@angular/core'
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs'
import { concatMap, filter, take, tap } from 'rxjs/operators'

import { RouteName } from '@app/models/route-name.enum'
import { User, UserOrder } from '@app/models/user.model'
import { EquipmentTemplateCoreDetails } from '@app/modules/equipment/models/equipment-template-core-details.model'
import { EquipmentTemplateUtilService } from '@app/modules/equipment/services/equipment-template-util.service'
import { EquipmentCalibration } from '@app/modules/work-order/models/equipment-calibration.model'
import { WorkOrderDetails } from '@app/modules/work-order/models/work-order-details.model'
import { AppState } from '@app/store/app.store'
import * as CalibrationSelector from '@app/store/calibration/calibration.selectors'
import { GetEquipmentTemplateDetailsAction } from '@app/store/equipment/actions/equipment-template.actions'
import { GetEquipmentDetailsAction } from '@app/store/equipment/actions/equipment.actions'
import { GetProcessConfigurationAction } from '@app/store/equipment/actions/process.actions'
import { equipmentTemplateList as equipmentTemplateSelector } from '@app/store/equipment/selectors/equipment-template.selectors'
import { currentUser as userSelector } from '@app/store/identity/identity.selectors'
import { SetProgressBarLoaderAction } from '@app/store/loader/loader.actions'
import { isLoading } from '@app/store/loader/loader.selectors'
import { LoadWorkOrderDetailsAction } from '@app/store/work-order/work-order.actions'
import { workOrderDetails as workOrderSelector } from '@app/store/work-order/work-order.selectors'
import { isNotAValue } from '@app/utils/app-utils.function'
import { GetCalibrationAction } from '../../../store/calibration/calibration.actions'
import { UsersService } from '../../user/services/users.service'
import { CalibrationDetails } from '../models/calibration-details.model'
import { CalibrationResultSet } from '../models/calibration-result-set.model'
import { CalibrationResultValue } from '../models/calibration-result-value.model'
import { CalibrationStatusEnum } from '../models/calibration-status.enum'
import { CalibrationStatus } from '../models/calibration-status.model'

@Injectable({
    providedIn: 'root'
})
export class CalibrationInitializerService {

    public noCompleted = 0
    public dataObservableCompletion$ = new BehaviorSubject<{ noCompleted: number, latestCompletion: string }>(
        { noCompleted: 0, latestCompletion: 'N/A' }
    )
    private currentUser: User
    private currentWorkOrder: WorkOrderDetails

    constructor(
        private store: Store<AppState>,
        private equipmentTemplateUtilService: EquipmentTemplateUtilService,
        private formBuilder: UntypedFormBuilder,
        private userService: UsersService
    ) {
        this.store.select(userSelector)
            .pipe(filter(user => user !== undefined))
            .pipe(take(1))
            .subscribe(currentUser => {
                this.currentUser = currentUser
            })
    }

    public loadData(workOrderId: string, equipmentId: string): void {
        if (workOrderId && equipmentId) {
            this.store.dispatch(LoadWorkOrderDetailsAction({ id: workOrderId, fetchNotification: false }))
            this.store.dispatch(new GetEquipmentTemplateDetailsAction(equipmentId))
            this.store.dispatch(new GetCalibrationAction(workOrderId, equipmentId))
            this.store.dispatch(new GetProcessConfigurationAction())
            this.store.dispatch(new GetEquipmentDetailsAction(equipmentId))
        }
    }
    public getDataObservable(workOrderId: string, equipmentId: string)
        : Observable<[
            WorkOrderDetails,
            EquipmentTemplateCoreDetails[],
            CalibrationDetails,
            boolean
        ]> {

        // The service does not get reset on page changes so we must reset the complete count manually
        this.noCompleted = 0

        const trackProgress = (caller = '') => concatMap((value: any, index) => {
            if (index === 0) {
                this.noCompleted++
            }
            this.dataObservableCompletion$.next({ noCompleted: this.noCompleted, latestCompletion: caller })
            this.store.dispatch(new SetProgressBarLoaderAction((this.noCompleted / 4) * 100, [RouteName.Calibration]))
            return of(value)
        })

        return combineLatest([
            this.store.select(workOrderSelector).pipe(
                filter(details => details && details.workOrderNumber === workOrderId),
                trackProgress('workOrderSelector')
            ).pipe(tap(workOrder => this.currentWorkOrder = workOrder)),
            this.store.select(equipmentTemplateSelector).pipe(
                filter(templates =>
                    this.equipmentTemplateUtilService
                        .findEquipmentTemplateById(templates, equipmentId) || templates.length === 0 ? true : false
                ),
                trackProgress('equipmentTemplateSelector')
            ),
            this.store.select(CalibrationSelector.calibrationDetails).pipe(
                filter(detail => detail !== undefined),
                trackProgress('calibrationDetails')
            ),
            this.store.select(isLoading).pipe(trackProgress('isLoading'))
        ])
    }

    public generateCalibrationObject(
        template: EquipmentTemplateCoreDetails,
        equipmentCalibration: EquipmentCalibration,
        calibrationDetails: CalibrationDetails = null,
        resetPMFinalResult = false
    ): CalibrationDetails {
        const calibrationDetailsTemplateBase = CalibrationDetails.createBaseObject()
        const finalPMResultStatus = resetPMFinalResult ? null : calibrationDetails?.finalPMResultStatus ?? null
        const isFinalPMResultStatusManuallySet = resetPMFinalResult ? false : !!calibrationDetails?.isFinalPMResultStatusManuallySet ?? false

        return {
            ...calibrationDetailsTemplateBase,
            // WARNING: Backend API only accept [id: 0 or more] or no id field, do not send [id: null] or [id: undefined]
            id: calibrationDetails?.id ?? undefined,
            customFormTemplate: calibrationDetails?.customFormTemplate,
            isFinalPMResultStatusManuallySet,
            finalPMResultStatus,
            plantCode: this.currentWorkOrder.plantCode,
            workOrderNumber: this.currentWorkOrder.workOrderNumber,
            workOrderDescription: this.currentWorkOrder.description,
            notificationNumber: this.currentWorkOrder.notification.number,
            equipmentId: equipmentCalibration?.equipmentId,
            equipmentTag: equipmentCalibration?.equipmentTag,
            // need to use on template to prevent error when template is not yet mapped
            templateId: template?.id,
            templateTypeId: template?.templateTypeId,
            processId: template?.processId,
            processName: template?.processName,
            calibrationChecklist: this.equipmentTemplateUtilService.transformChecklistQuestions(template?.checklistQuestion ?? []),
            calibrationTemplate: calibrationDetails?.calibrationTemplate ?? template?.detail,
            pmPerformedTechnicians: calibrationDetails?.pmPerformedTechnicians || [{ ...this.currentUser, displayOrder: 1 }]
        } as CalibrationDetails
    }

    /**
     * Return a FormGroup in format:
     *
     * ```
     *  {
     *      resultSetName: FormControl
     *      resultSet: FormArray
     *  }
     * ```
     * Which can be used as `calibrationForm.results.calibrationResult.results`
     */
    // TODO: why not use resultSet.resultSetName
    public initializeResultSetForm(
        resultSetName: string,
        resultSet: CalibrationResultSet,
        numberOfPoint: number,
        injectedInputRequired: boolean = false): UntypedFormGroup {
        const resultSetFormArray = this.formBuilder.array([])
        for (let i = 1; i <= numberOfPoint; i++) {
            const resultRow = (resultSet?.resultSet ?? []).find(row => row.pointNumber === i)
            const resultSetFormControl = this.fillResultRow(resultRow, i, injectedInputRequired)
            resultSetFormArray.push(resultSetFormControl)
        }

        return this.formBuilder.group({
            resultSetName,
            resultSet: resultSetFormArray
        })
    }

    public preProcessCalibrationData(calibration: CalibrationDetails): CalibrationDetails {
        calibration.pmPerformedTechnicians = this.selectFirstPMPerformedTechnician(
            calibration.pmPerformedTechnicians,
            calibration.calibrationStatus
        )

        calibration.equipmentReplacedTechnicians = this.userService.sortUserOrder(calibration.equipmentReplacedTechnicians)

        return calibration
    }

    public updateMobileViewInjectAndAdjustedInput(calibrationForm: UntypedFormGroup): void {
        const result = calibrationForm.get('results.calibrationResult.results') as UntypedFormArray
        result.controls.forEach(res => {
            const instrumentResultSet = res.get('resultSetName').value === 'Instrument'
            if (instrumentResultSet) {
                return
            }

            const resultSet = res.get('resultSet') as UntypedFormArray

            resultSet.controls.forEach(element => {
                const input = element.get('input').value
                const injectedInput = element.get('injectedInput') as UntypedFormControl
                const adjustedInjectedInput = element.get('adjustedInjectedInput') as UntypedFormControl

                if (isNotAValue(injectedInput.value)) {
                    injectedInput.setValue(input)
                }

                if (isNotAValue(adjustedInjectedInput.value)) {
                    adjustedInjectedInput.setValue(input)
                }
            })
        })
    }

    private selectFirstPMPerformedTechnician(pmPerformedTechnicians: UserOrder[], calibrationStatus: CalibrationStatus): UserOrder[] {
        let orderedTechnicians = this.userService.sortUserOrder(pmPerformedTechnicians)
        if (orderedTechnicians && orderedTechnicians[0] &&
            (this.currentUser.guid === orderedTechnicians[0].guid || calibrationStatus.id === CalibrationStatusEnum.Completed)) {
            return orderedTechnicians
        }
        // if changing the first technician, remove the current user from the list (if any) then add him/her as first technician instead
        // also removing, GRT dummy user from user list if calibration is not complete
        orderedTechnicians = orderedTechnicians.filter(technician =>
            technician.guid !== this.currentUser.guid &&
            technician.displayName !== 'GRT User')
        orderedTechnicians.unshift({ ...this.currentUser, displayOrder: 1 })

        let order = 1
        orderedTechnicians.forEach(technician => {
            technician.displayOrder = order
            order += 1
        })

        return orderedTechnicians
    }

    private fillResultRow(calibrationResultValue: CalibrationResultValue, numberOfPoint: number, injectedInuptRequired?: boolean): UntypedFormGroup {
        return this.formBuilder.group({
            adjustedInjectedInput: injectedInuptRequired ? (calibrationResultValue?.adjustedInjectedInput ?? null) : null,
            asFound: [(calibrationResultValue?.asFound ?? null), Validators.required],
            asLeft: [(calibrationResultValue?.asLeft ?? null), Validators.required],
            injectedInput: injectedInuptRequired ? (calibrationResultValue?.injectedInput ?? null) : null,
            pointNumber: [numberOfPoint, Validators.required]
        })
    }
}

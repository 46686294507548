import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { QueryBuilderService } from '@app/services/query-builder.service'
import { environment } from '@environments/environment'
import { AppSettings } from '@settings/app.settings'
import { TemplateType } from '../models/template-type.model'

@Injectable({
    providedIn: 'root'
})
export class TemplateTypeService {
    private readonly templateTypeBaseUrl =
        `${environment.baseUrl}/api/${AppSettings.apiVersion}/TemplateTypes`

    constructor(private queryBuilderService: QueryBuilderService) { }

    public getTemplatesTypes(): Observable<TemplateType[]> {
        return this.queryBuilderService
            .get<TemplateType[]>(this.templateTypeBaseUrl)
            .pipe(map(response => response.body))
    }
}

import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { tap } from 'rxjs/operators'

import { AppMonitoringService } from '@app/services/app-monitoring.service'
import { ActionType as AttachmentActionType } from './attachment/attachment.actions'
import { ActionType as CalibrationActionType } from './calibration/calibration.actions'
import { ActionType as ReportActionType } from './report/report.actions'

@Injectable()
export class AppStoreMonitoringMiddleware {

    /**
     * The name of ActionTypes in this `ofType()` list will be logged to AppInsight
     * without any payload information.
     */
    logAction$ = createEffect(() => this.actions$.pipe(
        ofType(...[
            CalibrationActionType.CreateCalibrationDetails,
            CalibrationActionType.UpdateCalibrationDetails,
            CalibrationActionType.ReopenCalibration,
            CalibrationActionType.ResumeCalibration,
            CalibrationActionType.ReopenNotification,
            CalibrationActionType.LoadCalibrationSuccess,
            AttachmentActionType.UploadCalibrationAttachment,
            AttachmentActionType.ReplaceCalibrationAttachment,
            AttachmentActionType.DeleteCalibrationAttachment,
            AttachmentActionType.GetCalibrationAttachments,
            AttachmentActionType.UploadEquipmentSettingAttachment,
            AttachmentActionType.ReplaceEquipmentSettingAttachment,
            AttachmentActionType.DeleteEquipmentSettingAttachment,
            AttachmentActionType.GetEquipmentSettingAttachments,
            ReportActionType.RejectCalibration,
            ReportActionType.ApproveCalibration
        ]),
        tap((action: any) => {
            this.appMonitoringService.instance?.trackEvent({
                name: '@ngrx: ' + action['type']
            })
        })
    ), { dispatch: false })

    constructor(private actions$: Actions, private appMonitoringService: AppMonitoringService) { }

}

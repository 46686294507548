import { createSelector } from '@ngrx/store'

import { BackgroundSyncStatusEnum } from '@app/models/offline-status.enum'
import { AppState } from '../app.store'
import { selectAll } from './offline.reducer'

export const selectOffline = createSelector((state: AppState) => state, (state) => state.offline)
export const pmUploadQueue = createSelector(selectOffline, state => state.dataWaitingForUpload)
export const selectFailedToSyncRecords = createSelector(selectOffline, state => {
    const workOrderCaches = selectAll(state)
    return workOrderCaches.reduce((result, woCache) => {
        woCache.equipments.forEach(equipment => {
            if (equipment.backgroundSyncStatus === BackgroundSyncStatusEnum.FAILED) {
                const message = `WO: ${woCache.workOrderNumber}, EQ: ${equipment.equipmentId}`
                result.push(message)
            }
        })

        return result
    }, [])
})

export const selectWorkOrderCache = createSelector(selectOffline, selectAll)

export const selectAutoDownloadEnabled = createSelector(selectOffline, state => state.isAutoDownloadEnabled)

export const pmAlertMessage = {
    saved: 'Saved',
    pmEqCompleted: 'PM completed for this equipment.',
    repairWONumber: 'Please enter the repair work order number.',
    attachmentRequire: 'Attachment required.',
    swUploadFail: 'Upload failed! Please try saving/completing the PM again or contact your supervisor.',
    swEQUploadSuccess: 'Uploaded! PM completed for this equipment.',
    swEQUploadDraft: 'Uploaded! Your PM is still in draft version. Please finish your PM.',
    swEQWaitingNetworkConnection: 'PM completed! Waiting for network connection to upload data.',
    swDataSavedOffline: 'Data saved offline. Waiting for network connection to upload data.',
    uploadingPMData: 'Uploading PM data!',
    pmUploadFinished: 'PMs data [uploaded]/[total] uploaded!',
    notificationClosureDelay: 'Notification closure request has been sent. This process typically takes a few minutes. Please do not reopen the calibration in the meantime'
}

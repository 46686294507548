import { Action } from '@ngrx/store'

import { SearchType } from './search.reducer'
import { WorkOrderListItem } from '@app/modules/work-order-search/models/work-order-list-item.model'

export enum ActionType {
    Search = '[Search] Search',
    SearchSuccess = '[Search] SearchSuccess',
    ClearSearch = '[Search] Clear',
    SetSearchType = '[Search] SetType',
    SelectWorkOrder = '[Search] Select work order'
}

export class SearchAction implements Action {
    readonly type = ActionType.Search

    constructor(public payload: { searchType: SearchType, query: string }) { }
}

export class SearchSuccessAction implements Action {
    readonly type = ActionType.SearchSuccess

    constructor(public payload: WorkOrderListItem[]) { }
}

export class SetSearchTypeAction implements Action {
    readonly type = ActionType.SetSearchType

    constructor(public payload: SearchType) { }
}

export class ClearSearchAction implements Action {
    readonly type = ActionType.ClearSearch
}

export class SelectWorkOrderAction implements Action {
    public readonly type = ActionType.SelectWorkOrder

    constructor(public workOrderNumber: string) { }
}

export type CalibrationSearchAction = SearchSuccessAction
    | SearchAction
    | SetSearchTypeAction
    | ClearSearchAction
    | SelectWorkOrderAction


import { environment } from '../environments/environment'

export class AppSettings {
    public static readonly apiVersion = environment.apiVersion
    public static readonly informModalIdentifier = 'inform-modal'
    public static readonly confirmModalIdentifier = 'confirm-modal'
    public static readonly dateTimeFormat = 'MMM D, YYYY'
    public static readonly dateWithTimeFormat = 'MMM D, YYYY HH:mm:ss'
    public static readonly dateTimeFormatJP = 'YYYY-MM-DD'
    public static readonly repairWorkOrderDigit = 8
    public static readonly attentionNeededDay = 30
    public static readonly profilePictureUrl = 'https://api.hoe.na.xom.com/apps-cp-emit/picture/v1/api/user'
    public static readonly newTemplateIdRange = {
        min: 100000,
        max: 1000000
    }
    public static readonly forceRefreshIntervalAfterSWUpdate = 10000
    public static readonly equipmentListTotalRowPerPage = 120
    public static readonly maintenancePlanListTotalRowPerPage = 500
    public static readonly reportListTotalRowPerPage = 500
    public static readonly eqSyncTimeInMinutes = 1440
    public static readonly testEquipmentDropdownPageSize = 40
    public static readonly flamingoFormPageSize = 40
    public static readonly userListProgressCheckDelay = 1500

    public static readonly pmo = {
        maxIntervalStep: 1000,

        /**
         * PMO interval unit mapping pairs
         * To allow valid unit mapping from MRMA and SAP system
         * Array structure => [(Unit from MRMA setting), (Unit from SAP)]
         */
        intervalUnitMapping: [
            [null, 'YR'],
            [null, 'MON'],
            ['YR', 'YR'],
            ['MON', 'YR'],
            ['MON', 'MON'],
            ['WK', 'WK'],
            ['DAY', 'DAY']
        ]

    }
}

import { animate, style, transition, trigger } from '@angular/animations'
import { Component, OnInit } from '@angular/core'
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent, RouterModule } from '@angular/router'
import { MsalBroadcastService, MsalService } from '@azure/msal-angular'
import { InteractionStatus } from '@azure/msal-browser'
import { Store } from '@ngrx/store'
import { interval } from 'rxjs'
import { delayWhen, filter, takeWhile } from 'rxjs/operators'

import { SafeUnsubscriberComponent } from './safe-unsubscriber.component'
import { AppMonitoringService } from './services/app-monitoring.service'
import { LaunchScreenLoaderService } from './services/launch-screen-loader.service'
import { AppState } from './store/app.store'
import { isHomeComponentInitialized } from './store/loader/loader.selectors'
import { NgClass, NgIf } from '@angular/common'
import { ModalContainerComponent } from './modules/modal-container/modal-container.component'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        trigger('zoomInOut', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'scale(1.2)'
                }),
                animate('200ms ease-out', style({
                    opacity: 1,
                    transform: 'scale(1)'
                }))
            ]),
            transition(':leave', [
                style({ opacity: 1, transform: 'scale(1)' }),
                animate('200ms ease-in', style({
                    opacity: 0,
                    transform: 'scale(1.2)'
                }))
            ])
        ])
    ],
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        RouterModule,
        ModalContainerComponent
    ]
})
export class AppComponent extends SafeUnsubscriberComponent implements OnInit {
    public isIframe = false
    public isLoading = false
    public isAccountCheckedOut = false
    public isBrowserNotSupported = false
    public isHomeComponentRendered = false

    public loaderImgSrc = './assets/images/loaders/drawing-loader.svg'

    constructor(
        private msalBroadcastService: MsalBroadcastService,
        private authService: MsalService,
        private router: Router,
        private appMonitoringService: AppMonitoringService,
        private store: Store<AppState>,
        private launchScreenLoaderService: LaunchScreenLoaderService
    ) {
        super()

        this.loaderImgSrc = this.launchScreenLoaderService.getLoaderImgSrc()

        this.router.events.subscribe((e: Event | RouterEvent) => {
            this.navigationInterceptor(e)
        })
    }

    ngOnInit(): void {
        this.isLoading = true
        this.isIframe = window !== window.parent && !window.opener

        this.checkBrowserSupport()

        // Will auto unsubscribe itself once initial load return true
        this.store.select(isHomeComponentInitialized).pipe(
            takeWhile(hasFinishedInitialLoading => !hasFinishedInitialLoading, true),
            filter(hasFinishedInitialLoading => hasFinishedInitialLoading)
        ).subscribe(() => this.isHomeComponentRendered = true)

        // Immediately check account when every auth interaction is done
        // Otherwise check account anyway after 5 sec to prevent loading screen being stuck
        this.msalBroadcastService.inProgress$.pipe(
            delayWhen((status: InteractionStatus) => status === InteractionStatus.None ? interval(0) : interval(5000))
        ).subscribe(() => {
            this.checkoutAccount()
        })

        this.appMonitoringService.initialize()
    }

    public checkoutAccount(): void {
        const loggedInAccounts = this.authService.instance.getAllAccounts()
        this.authService.instance.setActiveAccount(loggedInAccounts[0])
        if (loggedInAccounts.length > 0) {
            this.isLoading = false
            this.isAccountCheckedOut = true
        }
    }

    public navigateToChromeInstallation(): void {
        const win = window.open('https://it.na.xom.com/learning-support/tutorials-how-tos/software/windows-10/install-google-chrome')
        win.focus()
    }
    public checkBrowserSupport(): void {
        const ua = window.navigator.userAgent
        const isIE = /MSIE|Trident|Edge\//.test(ua)

        if (isIE) {
            this.isBrowserNotSupported = true
        } else {
            this.isBrowserNotSupported = false
        }
    }

    private navigationInterceptor(event: Event | RouterEvent): void {
        if (event instanceof NavigationStart) {
            this.isLoading = true
        }
        if (event instanceof NavigationEnd) {
            this.isLoading = false
        }

        // Set loading state to false in both of the below events to hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
            this.isLoading = false
        }
        if (event instanceof NavigationError) {
            this.isLoading = false
        }
    }
}

import { ToastrConfig } from './toastr-config.model'

const errorConfig = {
    tapToDismiss: false,
    enableHtml: true,
    closeButton: true,
    disableTimeOut: true,
    positionClass: 'toast-top-right'
} as ToastrConfig

const stickyNoneErrorConfig = {
    tapToDismiss: true,
    closeButton: true,
    disableTimeOut: false,
    timeOut: 10000,
    positionClass: 'toast-top-right'
} as ToastrConfig

const tempNoneErrorConfig = {
    tapToDismiss: true,
    closeButton: true,
    disableTimeOut: false,
    positionClass: 'toast-top-right',
    timeOut: 10000
} as ToastrConfig

const tempInfoConfig = {
    enableHtml: true,
    tapToDismiss: true,
    closeButton: true,
    disableTimeOut: true,
    positionClass: 'toast-top-right'
} as ToastrConfig

const stickyNoneDismissableConfig = {
    timeOut: 0,
    disableTimeOut: true,
    tapToDismiss: false
} as ToastrConfig

const warningConfig = {
    tapToDismiss: false,
    closeButton: true,
    disableTimeOut: true,
    positionClass: 'toast-top-right',
} as ToastrConfig

const genericCalibrationErrorMessage =
    'Something went wrong, please save the calibration and try again. If the problem persists, please contact your supervisor.'

const genericNetworkAndApplicationErrorMessage =
    'Something went wrong, please refresh and try again. If the problem persists, please contact your supervisor.'

const massCompleteDisableDraftErrorMessage =
    'Mass Update is not available for draft items.'

export const mrmaAlertConfigs = {
    CalibrationError: {
        message: genericCalibrationErrorMessage,
        configuration: errorConfig
    },
    massCompleteDraftError: {
        message: massCompleteDisableDraftErrorMessage,
        configuration: errorConfig
    },
    NetworkError: {
        message: genericNetworkAndApplicationErrorMessage,
        configuration: errorConfig
    },
    Validation: {
        configuration: stickyNoneErrorConfig
    },
    WorkflowSuccess: {
        configuration: tempNoneErrorConfig
    },
    WorkflowInfo: {
        configuration: tempNoneErrorConfig
    },
    WorkflowWarning: {
        configuration: tempNoneErrorConfig
    },
    WorkflowRequire: {
        configuration: stickyNoneErrorConfig
    },
    WorkflowError: {
        configuration: stickyNoneErrorConfig
    },
    workflowInfo: {
        configuration: tempInfoConfig
    },
    ProgressInflight: {
        configuration: stickyNoneDismissableConfig
    },
    NotificationClosureDelayWarning: {
        configuration: warningConfig
    }
}

import { Component, Input, OnInit } from '@angular/core'
import { ReactiveFormsModule, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'

import { EquipmentTemplateCoreDetails } from '@app/modules/equipment/models/equipment-template-core-details.model'
import { Equipment } from '@app/modules/equipment/models/equipment.model'
import { Process } from '@app/modules/equipment/models/process.model'
import { TemplateTypeEnum } from '@app/modules/equipment/models/template-type.enum'
import { TemplateTypeOption } from '@app/modules/equipment/models/template-type.model'
import { EquipmentListService } from '@app/modules/equipment/services/equipment-list.service'
import { CompareTemplateValidationService } from '@app/modules/equipment/services/template-validations/compare-template-validations.service'
import { UOM } from '@app/modules/shared/models/engineering-units/unit-of-measurement.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import * as EquipmentTemplateAction from '@app/store/equipment/actions/equipment-template.actions'
import * as TemplateFormAction from '@app/store/equipment/actions/template-form.actions'
import { equipmentList } from '@app/store/equipment/selectors/equipment-list.selectors'
import { equipmentTemplateList, selectedTemplate } from '@app/store/equipment/selectors/equipment-template.selectors'
import { process } from '@app/store/equipment/selectors/process.selectors'
import { uomList } from '@app/store/equipment/selectors/uom.selectors'
import { isNotAValue } from '@app/utils/app-utils.function'
import { AdminFormRowComponent } from '../admin-form-row/admin-form-row.component'
import { DropdownComponent } from '@app/modules/shared/components/dropdown/dropdown.component'
import { AsyncPipe, NgClass, NgIf } from '@angular/common'
import { UomPresentationPipePipe } from '@app/modules/equipment/pipes/uom-desc-with-short-name-pipe'
import { FilterPercentUom } from '@app/modules/shared/pipes/filter-percent-uom.pipe'
import { NumericInputComponent } from '@app/modules/mrma-ui-components/controls/numeric-input/numeric-input.component'
import { TestEquipmentPickerComponent } from '../../../test-equipment-picker/test-equipment-picker.component'

@Component({
    selector: 'app-compare',
    templateUrl: './compare.component.html',
    styleUrls: ['./compare.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        AdminFormRowComponent,
        DropdownComponent,
        AsyncPipe,
        UomPresentationPipePipe,
        FilterPercentUom,
        NgClass,
        NumericInputComponent,
        TestEquipmentPickerComponent,
        NgIf
    ]
})
export class CompareComponent extends SafeUnsubscriberComponent implements OnInit {
    @Input() isAdmin: boolean

    public compareEquipment$: Observable<Equipment[]>
    public uomList$: Observable<UOM[]>
    public processes$: Observable<Process[]>
    public compareTemplateForm: UntypedFormGroup
    public selectedTemplate: TemplateTypeOption
    private templateDetails: EquipmentTemplateCoreDetails

    constructor(
        private store: Store<AppState>,
        private formBuilder: UntypedFormBuilder,
        private compareTemplateValidation: CompareTemplateValidationService,
        private equipmentListUtilService: EquipmentListService
    ) {
        super()
    }

    public get compareEquipmentForm(): UntypedFormArray {
        return this.compareTemplateForm.get('compareEquipment') as UntypedFormArray
    }

    public get compare3PointTemplateId(): number {
        return TemplateTypeEnum['3 Point Compare']
    }

    public get equipmentTagIsDuplicated(): boolean {
        const equipments = this.compareTemplateForm.get('compareEquipment').value as Array<any>
        const equipment1 = equipments[0]?.equipmentId
        const equipment2 = equipments[1]?.equipmentId
        return !isNotAValue(equipment1) && !isNotAValue(equipment2) && (equipment1 === equipment2)
    }

    ngOnInit(): void {
        this.uomList$ = this.store.select(uomList)
        this.processes$ = this.store.select(process)
        this.compareEquipment$ = this.store.select(equipmentList)
        this.addSubscription(
            this.store.select(selectedTemplate).subscribe(template => this.selectedTemplate = template)
        )
        this.initTemplateData()
        this.watchMainFormControl()
    }

    private watchMainFormControl(): void {
        this.addSubscription(
            this.compareTemplateForm.valueChanges.subscribe(form => {
                this.store.dispatch(new TemplateFormAction.SelectCurrentTemplateFormsAction(form))
                this.store.dispatch(new EquipmentTemplateAction.EquipmentTemplateWasModifiedAction(
                    this.templateDetails,
                    this.compareTemplateValidation.isValid(this.compareTemplateForm.value, this.templateDetails.templateTypeId)
                ))
            })
        )
    }

    private initTemplateData(): void {
        this.store.select(equipmentTemplateList).pipe(take(1)).subscribe(equipmentTemplate => {
            this.templateDetails = equipmentTemplate.find(template =>
                template.id === this.selectedTemplate.templateUniqueId
            )

            this.compareTemplateForm = this.formBuilder.group({
                process: {
                    id: (this.templateDetails?.processId ?? 1),
                    name: (this.templateDetails?.processName ?? 'Generic')
                },
                alias: (this.templateDetails?.detail?.alias ?? ''),
                tolerance: this.formBuilder.group({
                    unitOfMeasurement: (this.templateDetails?.detail?.tolerance?.unitOfMeasurement ?? null),
                    value: (this.templateDetails?.detail?.tolerance?.value ?? 0)
                }),
                procedure: (this.templateDetails?.detail?.procedure ?? ''),
                compareEquipment: this.formBuilder.array([
                    (this.templateDetails?.detail?.compareEquipment ?? '')[0],
                    (this.templateDetails?.detail?.compareEquipment ?? '')[1]
                ])
            })
            this.store.dispatch(new TemplateFormAction.SelectCurrentTemplateFormsAction(
                this.compareTemplateForm.value
            ))

            if (!this.isAdmin) {
                this.compareTemplateForm.disable()
            }
        })
    }
}

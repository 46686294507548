<div class="em-c-field dropdown-container"
     [ngClass]="{'em-is-disabled': form?.disabled || formControl?.disabled}"
     [ngStyle]="styles">
    <span *ngIf="displayValue" class="em-c-select displayed-selection">{{getDisplayedTitle()}}</span>
    <select class="em-c-select em-u-width-100" [ngClass]="{'transparent-select': displayValue}"
            [formControl]="formControl">
        <option [ngValue]="null"
                disabled>
            {{noValueOption}}
        </option>

        <option *ngFor="let option of options; let idx = index" [ngValue]="option"
                [disabled]="isDisabled(idx)" class="enabled-option">
            {{ getDisplayedOption(option) }}
        </option>
    </select>
</div>

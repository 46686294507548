import { Injectable } from '@angular/core'

import { Role, UserProfile } from '@app/models/user.model'

export enum RoleEnum {
    Any = 1,
    ReadOnly = 2,
    Technician = 3,
    Administrator = 4,
    Coordinator = 5,
    Reviewer = 6,
    Support = 7,
    SiteEngineer = 8,
    GlobalEngineer = 9,
    GlobalRead = 10
}

@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    public isReviewer = (user: UserProfile, plantCode: string) => this.hasRole(user, RoleEnum.Reviewer, plantCode)
    public isAdmin = (user: UserProfile, plantCode: string) => this.hasRole(user, RoleEnum.Administrator, plantCode)
    public isSupport = (user: UserProfile) => this.hasRole(user, RoleEnum.Support)
    public isTechnician = (user: UserProfile, plantCode: string) => this.hasRole(user, RoleEnum.Technician, plantCode)
    public isAdminOrSupport = (user: UserProfile, plantCode: string) => this.hasRole(user, RoleEnum.Administrator, plantCode)
        || this.hasRole(user, RoleEnum.Support)
    public isEngineer = (user: UserProfile, plantCode: string) => this.hasRole(user, RoleEnum.SiteEngineer, plantCode)
        || this.hasRole(user, RoleEnum.GlobalEngineer)
    public isReadOnly = (user: UserProfile, plantCode: string) => this.hasRole(user, RoleEnum.ReadOnly, plantCode)
        || this.hasRole(user, RoleEnum.GlobalRead)


    public hasAnyWriteAccess = (user: UserProfile, plantCode: string) => {
        return user.roles
            .filter(role => this.isRoleHasSamePlantAsCurrent(plantCode, role))
            .filter(role => !this.isReadOnlyRole(role))
            .length > 0
    }


    private isRoleHasSamePlantAsCurrent = (currentPlanCode: string, role: Role) => {
        return currentPlanCode === role.plantCode
    }

    private isReadOnlyRole = (role: Role) => {
        return role.roleId === RoleEnum.ReadOnly || role.roleId === RoleEnum.GlobalRead
    }

    private hasRole(user: UserProfile, roleId: RoleEnum, plantCode = ''): boolean {
        const hasARole = (role: Role) => role.roleId === roleId
        if (!user?.roles) {
            return false
        }

        return !plantCode ?
            user.roles.some(role => hasARole(role)) :
            user.roles.some(role => hasARole(role) && role.plantCode === plantCode)
    }
}

import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { Observable } from 'rxjs'
import { exhaustMap, map } from 'rxjs/operators'

import { ProcessService } from '@app/modules/equipment/services/process.service'
import { ResponseHandlerService } from '@app/services/response-handler.service'
import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'
import { ResponseHandlingStrategyBuilder } from '@app/services/response-handling-strategy.builder'
import { AppState } from '@app/store/app.store'
import { ActionType, GetProcessConfigurationSuccessAction, GetProcessesSuccessAction } from '../actions/process.actions'

@Injectable()
export class ProcessEffect {

    
    loadProcess$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(ActionType.GetProcesses),
            exhaustMap(() => this.responseHandlerService.query(() => this.processService.getAll(), this.equipmentProcessStategy)
                .pipe(
                    map(processes => new GetProcessesSuccessAction(processes))
                )
            )
        ))

    
    loadProcessConfiguration$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(ActionType.GetProcessConfigurations),
            exhaustMap(() => this.responseHandlerService.query(() => this.processService.getConfigurations(), this.processConfigurationStategy)
                .pipe(
                    map(configurations => new GetProcessConfigurationSuccessAction(configurations))
                )
            )
        ))

    private equipmentProcessStategy: ResponseHandlingStrategy
    private processConfigurationStategy: ResponseHandlingStrategy

    constructor(
        private store: Store<AppState>,
        private actions$: Actions,
        private processService: ProcessService,
        private responseHandlerService: ResponseHandlerService,
        private toastr: ToastrService
    ) {
        this.equipmentProcessStategy = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .useShowToastrOnError(this.toastr)
            .useShowLoader(this.store)
            .responseStrategy

        this.processConfigurationStategy = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .useShowToastrOnError(this.toastr)
            .responseStrategy
    }
}

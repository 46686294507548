import { HttpErrorResponse } from '@angular/common/http'
import { Observable } from 'rxjs'

export class ResponseHandlingStrategy {
    constructor(protected next: ResponseHandlingStrategy) { }

    public init(): void {
        if (this.next) {
            this.next.init()
        }
    }

    public finalize(): void {
        if (this.next) {
            this.next.finalize()
        }
    }

    public catchError<TItem>(error: HttpErrorResponse): Observable<TItem> {
        if (!this.next) {
            throw new Error('ResponseHandlingStrategy.catchError must return a value.')
        }

        return this.next.catchError(error)
    }
}

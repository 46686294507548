import { EquipmentIntervalSetting } from '@app/modules/equipment/models/equipment.model'
import { MaintenancePlan } from '@app/modules/shared/models/maintenance-plan.model'
import { ActionType, EquipmentPMOAction } from '../actions/equipment-pmo.actions'

export interface EquipmentPMOState {
    testIntervalSetting: EquipmentIntervalSetting,
    maintenancePlans: MaintenancePlan[]
    isPlanLoading: boolean
    isSettingLoading: boolean
    // TODO: Implement Accidental Navigation Prevention
    isSettingModified: boolean
    isSettingSaving: boolean
}

const initialState: EquipmentPMOState = {
    testIntervalSetting: null,
    maintenancePlans: null,
    isPlanLoading: true,
    isSettingLoading: true,
    isSettingModified: false,
    isSettingSaving: false
}

export const reducer = (state = initialState, action: EquipmentPMOAction): EquipmentPMOState => {

    switch (action.type) {

        case ActionType.GetMaintenancePlansStart:
            return {
                ...state,
                isPlanLoading: true
            }

        case ActionType.GetMaintenancePlansSuccess:
            // TODO: Do lossless deepCopy()
            const maintenancePlan = action.maintenancePlans
            return {
                ...state,
                isPlanLoading: false,
                maintenancePlans: maintenancePlan
            }

        case ActionType.GetMaintenancePlansFail:
            // TODO: Implement better UI for error state
            return {
                ...state,
                isPlanLoading: false
            }

        case ActionType.GetTestIntervalSettingStart:
            return {
                ...state,
                isSettingLoading: true
            }
        case ActionType.GetTestIntervalSettingSuccess:
            {
                const testIntervalSetting = action.testIntervalSetting
                return {
                    ...state,
                    isSettingLoading: false,
                    testIntervalSetting
                }
            }

        case ActionType.GetTestIntervalSettingFail:
            return {
                ...state,
                isSettingLoading: false
            }

        case ActionType.UpdateTestIntervalSettingStart:
            {
                const { testIntervalSetting } = action.payload
                return {
                    ...state,
                    isSettingSaving: true,
                    testIntervalSetting
                }
            }

        case ActionType.UpdateTestIntervalSettingSuccess:
        case ActionType.UpdateTestIntervalSettingFail:
            return {
                ...state,
                isSettingSaving: false
            }

        default:
            return state
    }

}

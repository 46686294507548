import { Component, Input } from '@angular/core'
import { NgClass, NgIf } from '@angular/common';

/**
 * Note: This will soon be moved to ../components/
 */
@Component({
    selector: 'app-inline-label',
    templateUrl: './inline-label.component.html',
    styleUrls: ['./inline-label.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf]
})
export class InlineLabelComponent {

    @Input() label: string
    @Input() description: string

    /**
     * If the content section is larger, decided where the labels should
     * be aligned to relative to the content section. (Web view only)
     */
    @Input() labelsVerticleAlign: 'top' | 'center' | 'bottom' = 'center'

    /**
     * If the labels section is larger, decided where the content should
     * be aligned to relative to the labels section. (Web view only)
     */
    @Input() contentVerticleAlign: 'top' | 'center' | 'bottom' = 'center'

}

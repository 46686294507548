import { EquipmentHistory } from '@app/modules/calibration/models/equipment-history.model'
import { ActionType, EquipmentHistoryAction } from './equipment-history.actions'

export interface EquipmentHistoryState {
    equipmentHistory: EquipmentHistory
}

const initialState: EquipmentHistoryState = {
    equipmentHistory: undefined
}

export function reducer(state = initialState, action: EquipmentHistoryAction): EquipmentHistoryState {
    switch (action.type) {
        case ActionType.GetEquipmentHistorySuccess:
            return {
                ...state,
                equipmentHistory: action.payload,
            }
        default:
            return state
    }
}

import { Action } from '@ngrx/store'

import { FilterFields } from '@app/modules/equipment/models/equipment-filter-fields.model'
import { EquipmentQueryParameter } from '@app/modules/equipment/models/equipment-query-parameter.model'
import { EquipmentTemplateCoreDetails } from '@app/modules/equipment/models/equipment-template-core-details.model'
import { EquipmentTemplateMapping } from '@app/modules/equipment/models/equipment-template-mapping.model'
import { Equipment } from '@app/modules/equipment/models/equipment.model'
import { TemplateQueryParameter } from '@app/modules/equipment/models/template-query-parameter.model'
import { TemplateTypeFilter } from '@app/modules/equipment/models/template-type-filter.model'
import { SortedState } from '@app/modules/shared/models/filter.model'
import { QuerySummary } from '@app/modules/shared/models/query-summary.model'
import { ServiceFluid } from '@app/modules/equipment/models/equipment-list-api-response.model'

export enum ActionType {
    CreateEquipmentSuccess = '[EquipmentList] Create Equipment Success',
    UpdateTemplateToEquipmentListSuccess = '[EquipmentList] Add Equipment Template Success',
    RemoveTemplateFromEquipmentListSuccess = '[EquipmentList] Remove Equipment Template Success',
    FilterEquipmentList = '[EquipmentList] Filter Equipment List',
    GetCriticalityList = '[EquipmentList] Get Criticality List',
    GetCriticalityListSuccess = '[EquipmentList] Get Criticality List success',
    GetEquipmentList = '[EquipmentList] Get Equipment List',
    GetEquipmentListSuccess = '[EquipmentList] Get Equipment List Success',
    GetEquipmentListFailure = '[EquipmentList] Get Equipment List Failure',
    TouchNewEquipment = '[EquipmentList] Touch New Equipment',
    ApplyEquipmentListSort = '[EquipmentList] Apply Equipment List Sort',
    ApplyEquipmentListTemplateFilter = '[EquipmentList] Apply Equipment List Template Filter',
    ApplyEquipmentListABCIndicatorFilter = '[EquipmentList] Apply Equipment List ABCIndicator Filter',
    ApplyEquipmentListPlannerGroupFilter = '[EquipmentList] Apply Equipment List Planner Group Filter',
    ApplyEquipmentListMainWorkCenterFilter = '[EquipmentList] Apply Equipment List Main Work Center Filter',
    ApplyEquipmentListMaintenancePlantFilter = '[EquipmentList] Apply Equipment List Maintenance Plant Filter',
    ApplyEquipmentListPlantSectionFilter = '[EquipmentList] Apply Equipment List Plant Section Filter',
    ApplyEquipmentListLocationFilter = '[EquipmentList] Apply Equipment List Location Filter',
    ApplyEquipmentListCategoryFilter = '[EquipmentList] Apply Equipment List Category Filter',
    ApplyEquipmentListClassificationFilter = '[EquipmentList] Apply Equipment List Classification Filter',
    GetPlannerGroupList = '[EquipmentList] Get Planner Group List',
    GetPlannerGroupListSuccess = '[EquipmentList] Get Planner Group List Success',
    GetMainWorkCenterList = '[EquipmentList] Get Main Work Center List',
    GetMainWorkCenterListSuccess = '[EquipmentList] Get Main Work Center List Success',
    GetMaintenancePlantList = '[EquipmentList] Get Maintenance Plant List',
    GetMaintenancePlantListSuccess = '[EquipmentList] Get Maintenance Plant List Success',
    GetPlantSectionList = '[EquipmentList] Get Plant Section List',
    GetPlantSectionListSuccess = '[EquipmentList] Get Plant Section List Success',
    GetLocationList = '[EquipmentList] Get Location List',
    GetLocationListSuccess = '[EquipmentList] Get Location List Success',
    GetCategoryList = '[EquipmentList] Get Category List',
    GetCategoryListSuccess = '[EquipmentList] Get Category List Success',
    GetClassificationList = '[EquipmentList] Get Classification List',
    GetClassificationListSuccess = '[EquipmentList] Get Classification List Success',
    GetServiceFluidList = '[EquipmentList] Get Service Fluid List',
    GetServiceFluidListSuccess = '[EquipmentList] Get Service Fluid List Success',
}

export class GetCriticalityListAction implements Action {
    public readonly type = ActionType.GetCriticalityList
}

export class GetCriticalityListSuccessAction implements Action {
    public readonly type = ActionType.GetCriticalityListSuccess
    constructor(public payload: string[]) { }
}

export class GetPlannerGroupListAction implements Action {
    public readonly type = ActionType.GetPlannerGroupList
}

export class GetPlannerGroupListSuccessAction implements Action {
    public readonly type = ActionType.GetPlannerGroupListSuccess
    constructor(public payload: string[]) { }
}
export class GetMainWorkCenterListAction implements Action {
    public readonly type = ActionType.GetMainWorkCenterList
}

export class GetMainWorkCenterListSuccessAction implements Action {
    public readonly type = ActionType.GetMainWorkCenterListSuccess
    constructor(public payload: string[]) { }
}
export class GetMaintenancePlantListAction implements Action {
    public readonly type = ActionType.GetMaintenancePlantList
}

export class GetMaintenancePlantListSuccessAction implements Action {
    public readonly type = ActionType.GetMaintenancePlantListSuccess
    constructor(public payload: string[]) { }
}
export class GetPlantSectionListAction implements Action {
    public readonly type = ActionType.GetPlantSectionList
}

export class GetPlantSectionListSuccessAction implements Action {
    public readonly type = ActionType.GetPlantSectionListSuccess
    constructor(public payload: string[]) { }
}

export class GetLocationListAction implements Action {
    public readonly type = ActionType.GetLocationList
}

export class GetLocationListSuccessAction implements Action {
    public readonly type = ActionType.GetLocationListSuccess
    constructor(public payload: string[]) { }
}

export class GetCategoryListAction implements Action {
    public readonly type = ActionType.GetCategoryList
}

export class GetCategoryListSuccessAction implements Action {
    public readonly type = ActionType.GetCategoryListSuccess
    constructor(public payload: string[]) { }
}

export class GetClassificationListAction implements Action {
    public readonly type = ActionType.GetClassificationList
}

export class GetClassificationListSuccessAction implements Action {
    public readonly type = ActionType.GetClassificationListSuccess
    constructor(public payload: string[]) { }
}

export class GetTemplateListAction implements Action {
    public readonly type = ActionType.GetEquipmentList
    constructor(public payload: TemplateQueryParameter) { }
}

export class GetEquipmentListAction implements Action {
    public readonly type = ActionType.GetEquipmentList
    constructor(public payload: QuerySummary<EquipmentQueryParameter>, public replace = false) { }
}

export class GetEquipmentListSuccessAction implements Action {
    public readonly type = ActionType.GetEquipmentListSuccess
    constructor(
        public payload: {
            querySummary?: QuerySummary<EquipmentQueryParameter>,
            result: Equipment[],
            replace: boolean
        }
    ) { }
}

export class GetEquipmentListFailureAction implements Action {
    public readonly type = ActionType.GetEquipmentListFailure
}

export class TouchNewEquipmentAction implements Action {
    public readonly type = ActionType.TouchNewEquipment
    constructor(public payload: EquipmentTemplateMapping[]) { }
}

export class CreateEquipmentSuccessAction implements Action {
    public readonly type = ActionType.CreateEquipmentSuccess
    constructor(public payload: Equipment) { }
}

export class UpdateTemplateToEquipmentListSuccessAction implements Action {
    public readonly type = ActionType.UpdateTemplateToEquipmentListSuccess
    constructor(public payload: EquipmentTemplateCoreDetails) { }
}

export class RemoveTemplateFromEquipmentListSuccessAction implements Action {
    public readonly type = ActionType.RemoveTemplateFromEquipmentListSuccess
    constructor(public equipmentId: string, public templateId: number) { }
}

export class ApplyEquipmentListSortAction implements Action {
    public readonly type = ActionType.ApplyEquipmentListSort
    constructor(public payload: SortedState[]) { }
}

export class ApplyEquipmentListTemplateFilterAction implements Action {
    public readonly type = ActionType.ApplyEquipmentListTemplateFilter
    constructor(public payload: TemplateTypeFilter[]) { }
}

export class ApplyEquipmentListABCIndicatorFilterAction implements Action {
    public readonly type = ActionType.ApplyEquipmentListABCIndicatorFilter
    constructor(public payload: FilterFields[]) { }
}

export class ApplyEquipmentListPlannerGroupFilterAction implements Action {
    public readonly type = ActionType.ApplyEquipmentListPlannerGroupFilter
    constructor(public payload: FilterFields[]) { }
}

export class ApplyEquipmentListMainWorkCenterFilterAction implements Action {
    public readonly type = ActionType.ApplyEquipmentListMainWorkCenterFilter
    constructor(public payload: FilterFields[]) { }
}

export class ApplyEquipmentListMaintenancePlantFilterAction implements Action {
    public readonly type = ActionType.ApplyEquipmentListMaintenancePlantFilter
    constructor(public payload: FilterFields[]) { }
}

export class ApplyEquipmentListPlantSectionFilterAction implements Action {
    public readonly type = ActionType.ApplyEquipmentListPlantSectionFilter
    constructor(public payload: FilterFields[]) { }
}

export class ApplyEquipmentListLocationFilterAction implements Action {
    public readonly type = ActionType.ApplyEquipmentListLocationFilter
    constructor(public payload: FilterFields[]) { }
}

export class ApplyEquipmentListCategoryFilterAction implements Action {
    public readonly type = ActionType.ApplyEquipmentListCategoryFilter
    constructor(public payload: FilterFields[]) { }
}

export class ApplyEquipmentListClassificationFilterAction implements Action {
    public readonly type = ActionType.ApplyEquipmentListClassificationFilter
    constructor(public payload: FilterFields[]) { }
}

export class GetServiceFluidListAction implements Action {
    public readonly type = ActionType.GetServiceFluidList
}
export class GetServiceFluidListSuccessAction implements Action {
    public readonly type = ActionType.GetServiceFluidListSuccess

    constructor(public payload: ServiceFluid[]) {}
}

export type EquipmentListAction = GetTemplateListAction
    | GetCriticalityListAction
    | GetCriticalityListSuccessAction
    | GetPlannerGroupListAction
    | GetPlannerGroupListSuccessAction
    | GetMainWorkCenterListAction
    | GetMainWorkCenterListSuccessAction
    | GetMaintenancePlantListAction
    | GetMaintenancePlantListSuccessAction
    | GetPlantSectionListAction
    | GetPlantSectionListSuccessAction
    | GetLocationListAction
    | GetLocationListSuccessAction
    | GetCategoryListAction
    | GetCategoryListSuccessAction
    | GetClassificationListAction
    | GetClassificationListSuccessAction
    | GetEquipmentListAction
    | GetEquipmentListSuccessAction
    | GetEquipmentListFailureAction
    | TouchNewEquipmentAction
    | CreateEquipmentSuccessAction
    | UpdateTemplateToEquipmentListSuccessAction
    | RemoveTemplateFromEquipmentListSuccessAction
    | ApplyEquipmentListSortAction
    | ApplyEquipmentListTemplateFilterAction
    | ApplyEquipmentListABCIndicatorFilterAction
    | ApplyEquipmentListPlannerGroupFilterAction
    | ApplyEquipmentListMainWorkCenterFilterAction
    | ApplyEquipmentListMaintenancePlantFilterAction
    | ApplyEquipmentListPlantSectionFilterAction
    | ApplyEquipmentListLocationFilterAction
    | ApplyEquipmentListCategoryFilterAction
    | ApplyEquipmentListClassificationFilterAction
    | GetServiceFluidListAction
    | GetServiceFluidListSuccessAction

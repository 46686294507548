import { Injectable } from '@angular/core'

import { stringToBool } from '@app/utils/app-utils.function'
import { RepeatabilityAdminFormRawData } from '../../models/repeatability-admin-form-raw-data.model'

@Injectable({
    providedIn: 'root'
})
export class RepeatabilityTemplateValidationsService {

    public isValid(model: RepeatabilityAdminFormRawData): boolean {

        const isAccuracyTesting = stringToBool(model?.isAccuracyTesting)
        const isRepeatabilityTesting = stringToBool(model?.isRepeatabilityTesting)
        const isTestingSelected = isAccuracyTesting || isRepeatabilityTesting

        if (!model.process || !model.numberOfPoints?.point || !isTestingSelected) {
            return false
        }

        if (isAccuracyTesting) {
            if (model.input.minimumRange === null ||
                model.input.unitOfMeasurement === null ||
                model.expected.minimumRange === null ||
                model.expected.unitOfMeasurement === null ||
                model.accuracyTolerance.value === null ||
                model.accuracyTolerance.unitOfMeasurement === null
            ) {
                return false
            }
        }

        if (isRepeatabilityTesting) {
            if (model.repeatabilityTolerance.value === null ||
                model.repeatabilityTolerance.unitOfMeasurement === null
            ) {
                return false
            }
        }

        return true
    }

}

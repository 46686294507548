import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { Observable, of } from 'rxjs'
import { catchError, concatMap, switchMap, tap } from 'rxjs/operators'

import { User, UserProfile } from '@app/models/user.model'
import { UsersService } from '@app/modules/user/services/users.service'
import { ResponseHandlerService } from '@app/services/response-handler.service'
import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'
import { ResponseHandlingStrategyBuilder } from '@app/services/response-handling-strategy.builder'
import {
    ActionType, GetCurrentUserFailureAction, GetCurrentUserSuccessAction, UpdateUserAction, UpdateUserFailureAction
} from '@app/store/identity/identity.actions'
import { AppSettings } from '@settings/app.settings'
import { AppState } from '../app.store'

@Injectable()
export class IdentityEffects {

    
    public getUser: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActionType.GetCurrentUser),
        concatMap(() => {
            return this.responseHandlerService.query(() => this.usersService.getMe(), this.getUserStrategyLoader)
                .pipe(
                    switchMap(user => {
                        const profile = this.convertToUserProfile(user)
                        return [new GetCurrentUserSuccessAction(profile)]
                    }),
                    catchError(err => {
                        this.router.navigateByUrl('/unauthorized')
                        return of(new GetCurrentUserFailureAction())
                    })
                )
        })
    ))

    
    public updateUser: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(ActionType.UpdateUser),
        concatMap(action =>
            this.responseHandlerService.query(
                () => this.usersService.update((action as UpdateUserAction).payload),
                this.updateUserStrategyLoader
            ).pipe(
                tap(() => location.href = ''),
                switchMap(() => of({ type: 'NO_ACTION' })),
                catchError(_ => of(new UpdateUserFailureAction()))
            )
        )
    ))

    private updateUserStrategyLoader: ResponseHandlingStrategy
    private getUserStrategyLoader: ResponseHandlingStrategy

    constructor(
        private actions$: Actions,
        private store: Store<AppState>,
        private router: Router,
        private responseHandlerService: ResponseHandlerService,
        private usersService: UsersService,
        private toastr: ToastrService
    ) {
        this.updateUserStrategyLoader = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .useShowToastrOnError(this.toastr, 5000)
            .useShowLoader(this.store)
            .responseStrategy
        this.getUserStrategyLoader = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .useShowLoader(this.store)
            .responseStrategy
    }

    private convertToUserProfile(user: User): UserProfile {
        const profile = { ...user } as UserProfile
        if (user.lanId) {
            profile.profilePictureUrl = `${AppSettings.profilePictureUrl}/${user.lanId.replace('\\', '_')}`
        }

        return profile
    }
}

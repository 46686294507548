export enum CacheStatusEnum {
    UNDEFINED = 'UNDEFINED',
    LOADING = 'LOADING',
    CACHED = 'CACHED',
    FAILED = 'FAILED'
}

export enum BackgroundSyncStatusEnum {
    NOT_QUEUED = 'NOT_QUEUED',
    QUEUED = 'QUEUED',
    SYNCING = 'SYNCING',
    SYNCED = 'SYNCED',
    FAILED = 'FAILED'
}

import { Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'

import { CalibrationDetails } from '@app/modules/calibration/models/calibration-details.model'
import { SectionLoaderEnum } from '@app/modules/shared/models/section-loader.enum'
import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'
import { AppState } from '@app/store/app.store'
import { OfflineService } from './offline.service'
import { Handle404Strategy } from './response-handling-strategies/handle-404.strategy'
import { RethrowErrorStrategy } from './response-handling-strategies/rethrow-error.strategy'
import { ShowLoaderStrategy } from './response-handling-strategies/show-loader.strategy'
import { ShowModalOnErrorCalibrationStrategy } from './response-handling-strategies/show-modal-on-error-calibration.strategy'
import { ShowModalOnErrorStrategy } from './response-handling-strategies/show-modal-on-error.strategy'
import { ShowSectionLoaderStrategy } from './response-handling-strategies/show-section-loader.strategy'
import { ShowToastrOnErrorCalibrationStrategy } from './response-handling-strategies/show-toastr-on-error-calibration.strategy'
import { ShowToastrOnErrorStrategy } from './response-handling-strategies/show-toastr-on-error.stratgy'

export class ResponseHandlingStrategyBuilder {
    public responseStrategy: ResponseHandlingStrategy

    public useShowLoader(store: Store<AppState>): ResponseHandlingStrategyBuilder {
        this.responseStrategy = new ShowLoaderStrategy(this.responseStrategy, store)
        return this
    }

    public useShowModalOnError(store: Store<AppState>): ResponseHandlingStrategyBuilder {
        this.responseStrategy = new ShowModalOnErrorStrategy(this.responseStrategy, store)
        return this
    }

    public useShowModalOnErrorCalibration(
        store: Store<AppState>,
        offlineService: OfflineService,
        calibrationDetail: CalibrationDetails,
        delayBeforePopup: number
    ): ResponseHandlingStrategyBuilder {
        this.responseStrategy = new ShowModalOnErrorCalibrationStrategy(
            this.responseStrategy,
            store,
            offlineService,
            calibrationDetail,
            delayBeforePopup
        )
        return this
    }

    public useHandle404(returnValue: any): ResponseHandlingStrategyBuilder {
        this.responseStrategy = new Handle404Strategy(this.responseStrategy, returnValue)
        return this
    }

    public useRethrowError(): ResponseHandlingStrategyBuilder {
        this.responseStrategy = new RethrowErrorStrategy(this.responseStrategy)
        return this
    }

    public useShowSectionLoader(store: Store<AppState>, sectionName: SectionLoaderEnum): ResponseHandlingStrategyBuilder {
        this.responseStrategy = new ShowSectionLoaderStrategy(this.responseStrategy, store, sectionName)
        return this
    }

    /**
     * @param toastr inject toastr service
     * @param delay (Optional) delay in milliseconds for toastr to show up
     */
    public useShowToastrOnError(toastr: ToastrService, delay = 0): ResponseHandlingStrategyBuilder {
        this.responseStrategy = new ShowToastrOnErrorStrategy(this.responseStrategy, toastr, delay)
        return this
    }

    public useShowToastrOnCalibrationError(
        store: Store<AppState>,
        toastr: ToastrService,
        offlineService: OfflineService,
        calibrationDetail: CalibrationDetails
    ): ResponseHandlingStrategyBuilder {
        this.responseStrategy = new ShowToastrOnErrorCalibrationStrategy(
            this.responseStrategy,
            store,
            toastr,
            offlineService,
            calibrationDetail
        )
        return this
    }
}

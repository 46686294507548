import { CustomFormResultValue } from '../components/custom-form/models/custom-form-result.model'
import { CalibrationResultSet } from './calibration-result-set.model'

export class CalibrationResult {
    results: CalibrationResultSet[]
    customForms?: CustomFormResultValue[]
}

export class ThirdPartyCalibrationResult extends CalibrationResult {
    companyName: string
}

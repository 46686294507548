import { Injectable } from '@angular/core'

import { Tolerance } from '@app/modules/shared/models/engineering-units/tolerance.model'
import { isNotAValue } from '@app/utils/app-utils.function'

@Injectable({
    providedIn: 'root'
})
export class ToleranceValidationService {
    public isValid(model: Tolerance): boolean {
        if (isNotAValue(model)) {
            return false
        }

        if (isNotAValue(model.higherRange) || isNotAValue(model.lowerRange)) {
            return false
        }

        if (isNotAValue(model.unitOfMeasurement)) {
            return false
        }

        if (isNotAValue(model.unitOfMeasurement.uomCode)) {
            return false
        }

        return true
    }
}

import { Injectable } from '@angular/core'
import { MsalService } from '@azure/msal-angular'
import { BehaviorSubject, timer } from 'rxjs'
import { first } from 'rxjs/operators'

import { environment } from '@environments/environment'

@Injectable({
    providedIn: 'root'
})
export class FlamingoAuthenticationService {
    public readonly flamingoToken$: BehaviorSubject<string> = new BehaviorSubject('')

    constructor(
        private authService: MsalService
    ) {
        this.acquireFlamingoToken()
    }

    private async acquireFlamingoToken(): Promise<void> {
        await this.authService.instance.initialize();
        const loggedInAccounts = this.authService.instance.getAllAccounts()
        this.authService.acquireTokenSilent({
            scopes: environment.flamingoForm.scopes,
            authority: environment.auth.authority,
            account: loggedInAccounts[0]
        }).pipe(first()).subscribe(authResult => {
            this.flamingoToken$.next(authResult.accessToken)
            this.startTokenTimer(authResult.expiresOn)
        })
    }

    /**
     * Set a timer to renew token when it expires.
     * we need to do this ourselves since we manually manage Flamingo token outside of HTTP interceptor / guards
     * so it may not renew token automatically when we need.
     */
    private startTokenTimer(tokenExpiresOn: Date): void {
        timer(tokenExpiresOn).pipe(first()).subscribe(() => {
            this.acquireFlamingoToken()
        })
    }
}

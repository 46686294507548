import { CustomForm, CustomFormObject } from '@app/modules/calibration/components/custom-form/models/custom-form.model'
import { ActionType, CustomFormAction } from './custom-form.actions'

export interface CustomFormState {
    forms: CustomForm[]
}

const initialState: CustomFormState = {
    forms: []
}

export function reducer(state = initialState, action: CustomFormAction): CustomFormState {
    switch (action.type) {
        case ActionType.GetTemplatesSuccess:
            return {
                ...state,
                forms: action.payload
            }
        default:
            return state
    }
}

export enum CalibrationStatusEnum {
    NotStarted = 0,
    Open = NotStarted,
    Draft = 1,
    /** deprecated - no longer in use */
    PendingSAP = 2,
    Completed = 3
}

export enum CalibrationStatusTextEnum {
    'not started' = CalibrationStatusEnum.NotStarted,
    'draft' = CalibrationStatusEnum.Draft,
    'completed' = CalibrationStatusEnum.Completed
}

export { CalibrationStatusEnum as PMStatusEnum }


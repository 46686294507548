import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

import { QueryBuilderService } from '@app/services/query-builder.service'
import { AppState } from '@app/store/app.store'
import { calibrationDetails } from '@app/store/calibration/calibration.selectors'
import { deepCopy } from '@app/utils/app-utils.function'
import { dateToLocalISOString } from '@app/utils/date.utils'
import { mdIsALink } from '@app/utils/strings/markdown'
import { environment } from '@environments/environment'
import { AppSettings } from '@settings/app.settings'
import { CalibrationDetails, Cylinder } from '../models/calibration-details.model'
import { CalibrationInitializerService } from './calibration-initializer.service'

@Injectable({
    providedIn: 'root'
})
export class CalibrationService {
    private selectedCalibration: CalibrationDetails
    private readonly url = `${environment.baseUrl}/api/${AppSettings.apiVersion}/Calibrations`

    constructor(
        private calibrationInitializerService: CalibrationInitializerService,
        private queryBuilderService: QueryBuilderService,
        private store: Store<AppState>
    ) {
        this.store.select(calibrationDetails).subscribe(result => {
            this.selectedCalibration = result
        })
    }

    public static isProcedureLink(equipmentTemplates, templateId): boolean {

        const equipmentTemplate = equipmentTemplates.find(x => x.id === templateId)
        const templateProcedure = equipmentTemplate?.detail?.procedure

        if (/^http/gi.test(templateProcedure)) {
            return true
        }

        if (mdIsALink(templateProcedure)) {
            return true
        }

        return false
    }

    /**
     * Patch all instances of Date in a CalibrationDetails with local ISO time string **and set time to midnight**
     * instead of letting JS do its own stringify which will default to ISO format in UTC
     * **Note:** Will NOT mutate the original calibration object but return a **deep copied**.
     */
    private static patchCalibrationDetailsDatesWithLocalISOTimeFormat(calibration: CalibrationDetails): CalibrationDetails | any {
        const calibrationPayload = deepCopy(calibration)

        // FIXME: Should be somewhere else, but don't know where yet
        // FIXME: This will definitely be hard to maintain, need a better solution
        const DATES_TO_CONVERT = [
            'pmPerformedDate',
            'equipmentReplacedDate',
            'reviewDate'
        ]

        DATES_TO_CONVERT.forEach(key => {
            const dateToConvert = calibrationPayload[key]
            if (dateToConvert) {
                calibrationPayload[key] = dateToLocalISOString(new Date(dateToConvert), true, true)
            }
        })

        return calibrationPayload
    }

    private static patchIfIsNoTemplate(calibration: CalibrationDetails): CalibrationDetails | any {
        if (!CalibrationDetails.isNoTemplate(calibration)) {
            return calibration
        }

        // Patch for No-Template
        const calibrationPayload = deepCopy(calibration)
        const patchedPayload = {
            ...calibrationPayload,
            templateId: null,
            templateTypeId: null,
            calibrationTemplate: null
        }

        return patchedPayload
    }

    public getCalibrationDetails(workOrderNumber: string, equipmentId: string): Observable<CalibrationDetails> {
        return this.queryBuilderService
            .get<CalibrationDetails>(`${this.url}(${workOrderNumber},${equipmentId})`)
            .pipe(
                map(response => this.calibrationInitializerService.preProcessCalibrationData(response.body)),
                catchError(() => of({} as CalibrationDetails))
            )
    }

    /**
     * Call api to create a new calibration detail using POST method
     * @param sendWithLocalISOTimeFormat - default to false which will send in **UTC** ISO time
     * as oppose to **Local** ISO - i.e. send as ...17:00+7:00 instead of ...10:00+0:00
     */
    public createCalibrationDetails(
        calibration: CalibrationDetails,
        sendWithLocalISOTimeFormat = false
    ): Observable<CalibrationDetails> {

        const calibrationPayload = CalibrationService.patchIfIsNoTemplate(sendWithLocalISOTimeFormat ?
            CalibrationService.patchCalibrationDetailsDatesWithLocalISOTimeFormat(calibration) :
            calibration)

        return this.queryBuilderService
            .post<CalibrationDetails>(`${this.url}`, calibrationPayload)
            .pipe(
                map(response => response.body)
            )
    }

    /**
     * Call api to update calibration detail using PUT method
     * @param sendWithLocalISOTimeFormat - default to false which will send in **UTC** ISO time
     * as oppose to **Local** ISO - i.e. send as ...17:00+7:00 instead of ...10:00+0:00
     */
    public updateCalibrationDetails(
        workOrderNumber: string,
        equipmentId: string,
        calibration: CalibrationDetails,
        sendWithLocalISOTimeFormat = false
    ): Observable<CalibrationDetails> {

        const calibrationPayload = CalibrationService.patchIfIsNoTemplate(sendWithLocalISOTimeFormat ?
            CalibrationService.patchCalibrationDetailsDatesWithLocalISOTimeFormat(calibration) :
            calibration)

        return this.queryBuilderService
            .put<CalibrationDetails>(`${this.url}(${workOrderNumber},${equipmentId})`, calibrationPayload)
            .pipe(
                map(response => response.body)
            )
    }

    public reopenCalibration(workOrderNumber: string, equipmentId: string): Observable<number> {
        return this.queryBuilderService
            .put<number>(`${environment.baseUrl}/api/${AppSettings.apiVersion}/WorkOrders(${workOrderNumber})/Equipment(${equipmentId})/Reopen`)
            .pipe(
                map(response => response.status)
            )
    }

    public resumeCalibration(workOrderNumber: string, equipmentId: string): Observable<number> {
        return this.reopenCalibration(workOrderNumber, equipmentId)
    }

    public getSelectedCalibration(): CalibrationDetails {
        return this.selectedCalibration
    }

    public getEquipmentCylinderHistory(equipmentId): Observable<Cylinder[]> {
        return this.queryBuilderService
            .get<Cylinder[]>(`${environment.baseUrl}/api/${AppSettings.apiVersion}/Equipments(${equipmentId})/Cylinders`)
            .pipe(
                map(response => response.body)
            )
    }

}

import { Action } from '@ngrx/store'
import { TemplateType } from '@app/modules/equipment/models/template-type.model'

export enum ActionType {
    GetTemplateTypes = '[Template Type] Get Template Types',
    GetTemplateTypesSuccess = '[Template Type] Get Template Types Success',
}

export class GetTemplateTypesAction implements Action {
    public readonly type = ActionType.GetTemplateTypes
}

export class GetTemplateTypesSuccessAction implements Action {
    public readonly type = ActionType.GetTemplateTypesSuccess
    constructor(public payload: TemplateType[]) { }
}

export type TemplateTypeAction = GetTemplateTypesAction
    | GetTemplateTypesSuccessAction

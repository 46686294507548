import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { QueryBuilderService } from '@app/services/query-builder.service'
import { environment } from '@environments/environment'
import { AppSettings } from '@settings/app.settings'
import { Process, ProcessConfiguration } from '../models/process.model'

@Injectable({
    providedIn: 'root'
})
export class ProcessService {
    private readonly url = `${environment.baseUrl}/api/${AppSettings.apiVersion}`

    constructor(private baseApiService: QueryBuilderService) { }

    public getAll(): Observable<Process[]> {
        return this.baseApiService
            .get<Process[]>(`${this.url}/Processes`)
            .pipe(map(response => response.body))
    }

    public getConfigurations(): Observable<ProcessConfiguration[]> {
        return this.baseApiService
            .get<ProcessConfiguration[]>(`${this.url}/ProcessConfigurations`)
            .pipe(map(response => response.body))
    }
}

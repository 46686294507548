import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { Observable } from 'rxjs'
import { exhaustMap, map } from 'rxjs/operators'

import { CustomFormService } from '@app/modules/calibration/components/custom-form/services/custom-form.service'
import { ResponseHandlerService } from '@app/services/response-handler.service'
import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'
import { ResponseHandlingStrategyBuilder } from '@app/services/response-handling-strategy.builder'
import { ActionType, GetCustomFormTemplatesAction, GetCustomFormTemplatesSuccessAction } from './custom-form.actions'

@Injectable()
export class CustomFormEffects {
    
    loadCustomFormTemplate: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<GetCustomFormTemplatesAction>(ActionType.GetTemplates),
        exhaustMap(_ => this.responseHandlerService.query(() =>
            this.customFormService.getCustomFormTemplate(), this.customStrategyReportList
        ).pipe(
            map(forms => new GetCustomFormTemplatesSuccessAction(forms))
        ))
    ))

    private customStrategyReportList: ResponseHandlingStrategy

    constructor(
        private toastr: ToastrService,
        private actions$: Actions,
        private customFormService: CustomFormService,
        private responseHandlerService: ResponseHandlerService
    ) {
        this.customStrategyReportList = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .useShowToastrOnError(this.toastr)
            .responseStrategy
    }
}

import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { QueryBuilderService } from '@app/services/query-builder.service'
import { environment } from '@environments/environment'
import { AppSettings } from '@settings/app.settings'
import { TestEquipment } from '../models/test-equipment.model'

@Injectable({
    providedIn: 'root'
})
export class TestEquipmentService {
    private readonly url = `${environment.baseUrl}/api/${AppSettings.apiVersion}/TestEquipments`

    constructor(private queryBuilderService: QueryBuilderService) { }

    public getTestEquipment(queryString: string = ''): Observable<TestEquipment[]> {
        return this.queryBuilderService.get<TestEquipment[]>(`${this.url}`, {
            equipmentTag: queryString,
            includeExpired: true,
            includeInactive: true,
            sortBy: 'EquipmentTag',
            page: '1',
            perPage: 'all'
        })
            .pipe(map(response => response.body))
    }

    public updateTestEquipment(testEquipment: TestEquipment): Observable<TestEquipment> {
        return this.queryBuilderService
            .put<TestEquipment>(`${this.url}(${testEquipment.equipmentId})`, testEquipment)
            .pipe(map(response => response.body))
    }

    public createTestEquipment(testEquipment: TestEquipment): Observable<TestEquipment> {
        return this.queryBuilderService
            .post<TestEquipment>(`${this.url}`, testEquipment)
            .pipe(map(response => response.body))
    }
}

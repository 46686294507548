import { SortedState, SortingState } from '@app/modules/shared/models/filter.model'

export const equipmentFilterSetting = [
    {
        sortId: 'equipmentId',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'equipmentTag',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'techIdentificationNumber',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'functionalLocation',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'description',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'abcIndicator',
        sortingState: SortingState.None,
        sortOrder: 0
    }
] as SortedState[]

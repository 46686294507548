<div class="tabular-wrapper">
    <ng-container *ngIf="formArray.controls.length > 0; else noticeMessage">

        <div style="padding-bottom: 20px;">
            <app-inline-label label="SHARE SETPOINT"
                              description="(SHARE UNIT ACROSS SET POINTS)">
                <div class="em-l-grid__item admin-input-width">
                    <app-options [formGroup]="setpointSelectorGroup"
                                 [formControlPath]="'shareSetPoint'"
                                 [defaultOption]="getOption(setpointSelectorGroup.get('shareSetPoint').value, yesNoOption)"
                                 [labelStyle]="'em-u-width-50'"
                                 [options]="yesNoOption"
                                 [key]="'key'"
                                 [value]="'value'"
                                 [disabled]="this.setpointSelectorGroup.get('shareSetPoint').disabled">
                    </app-options>
                </div>
            </app-inline-label>
        </div>

        <!-- Set Points 
             Use rowIndex + 1 so it starts with 1 and align with pointNumber that also starts with 1 -->
        <app-setpoint-selector-row *ngFor="let c of formArray.controls; let i = index"
                                   [formControl]="c"
                                   [defaultSetPointName]="getDefaultSetPointName(i)"
                                   [isSharedSetPoint]="setpointSelectorGroup.get('shareSetPoint').value"
                                   [isExpectedReadingEdited]="showExpectedReadingColumn"
                                   [isSetPointAdjustable]="isSetPointAdjustable"
                                   [setPointUOM]="setpointSelectorGroup.get('setpointUOM').value"
                                   [expectedReadingUOM]="setpointSelectorGroup.get('expectedReadingUOM').value"
                                   [toleranceUOM]="setpointSelectorGroup.get('toleranceUOM').value"
                                   [rowIndex]="i+1"></app-setpoint-selector-row>
    </ng-container>

    <ng-template #noticeMessage>
        <div class="notice-message">Please select at least one point.</div>
    </ng-template>
</div>

import { Injectable } from '@angular/core'

import { SpecialUomCode } from '@app/models/special-uom.model'
import { ToleranceCalculationType } from '@app/modules/shared/models/engineering-units/setpoint.model'
import { Tolerance } from '@app/modules/shared/models/engineering-units/tolerance.model'
import { UnitRange } from '@app/modules/shared/models/engineering-units/unit-range.model'
import { UnitValue } from '@app/modules/shared/models/engineering-units/unit-value.model'
import { deepCopy, round } from '@app/utils/app-utils.function'

@Injectable({
    providedIn: 'root'
})
export class EngineeringCalculationService {
    /**
     * Return whether a value or unitValue is within a unitRange.
     * By default, the given unitRange will be treat as inclusive
     * (use <= and >= comparison instead of < and >).
     */
    public static isInRange(
        value: UnitValue | number,
        range: UnitRange | { minimumRange: number, maximumRange: number },
        inclusive = true
    ): boolean {
        const _value = round((typeof value === 'number') ? value : value.value)
        const { minimumRange, maximumRange } = range
        const roundedMin = round(minimumRange)
        const roundedMax = round(maximumRange)

        if (inclusive) {
            return _value >= roundedMin && _value <= roundedMax
        } else {
            return _value > roundedMin && _value < roundedMax
        }
    }

    /**
     * **Note:** In most case, `getAcceptableRange` in `SetPointService` should be the
     * function you are looking for.
     * That function will read the needed setting from a given SetPoint for you.
     *
     * ----
     *
     * Given a `UnitValue`, a `Tolerance` and a `ToleranceCalculationType`, along with any
     * `additionalInfo` if needed, calculate and return a `UnitRange` of acceptable range
     * that would fall __within__ the given tolerance.
     */
    public static calculateAcceptableRange(
        unitValue: UnitValue,
        tolerance: Tolerance,
        toleranceCalculationType: ToleranceCalculationType,
        additionalInfo?: { measurementRange: UnitRange }
    ): UnitRange {
        const { unitOfMeasurement: valueUOM, value } = unitValue
        const { unitOfMeasurement: toleranceUOM } = tolerance
        const lowerRange = Math.abs(tolerance.lowerRange)
        const higherRange = Math.abs(tolerance.higherRange)
        let min: number
        let max: number

        switch (toleranceCalculationType) {
            case ToleranceCalculationType.Additive:
                if (valueUOM.uomCode !== toleranceUOM.uomCode) {
                    throw new Error('Cannot add or substract value and tolerance with different units!')
                }

                return {
                    unitOfMeasurement: deepCopy(valueUOM),
                    minimumRange: value - lowerRange,
                    maximumRange: value + higherRange
                } as UnitRange

            case ToleranceCalculationType.Multiplicative:
                if (toleranceUOM.uomCode !== SpecialUomCode.Percentage) {
                    throw new Error(`Multiplicative ToleranceCalculationType requires the Tolerance's unit to be Percentage!`)
                }

                // Reversed the operator sign to handle negative values
                min = value < 0 ? 1 + (lowerRange / 100) : 1 - (lowerRange / 100)
                max = value < 0 ? 1 - (higherRange / 100) : 1 + (higherRange / 100)

                return {
                    unitOfMeasurement: deepCopy(valueUOM),
                    minimumRange: round(value * min),
                    maximumRange: round(value * max)
                } as UnitRange

            case ToleranceCalculationType.PercentageOfSpan:

                const measurementRange = additionalInfo?.measurementRange
                if (!measurementRange) {
                    throw new Error('A measurementRange is expected for PercentageOfSpan ToleranceCalculationType!')
                }

                const { unitOfMeasurement: rangeUOM, maximumRange, minimumRange } = measurementRange
                if (rangeUOM.uomCode !== valueUOM.uomCode) {
                    throw new Error('The unit of value must be the same as the unit of measurementRange!')
                }
                if (toleranceUOM.uomCode !== SpecialUomCode.Percentage) {
                    throw new Error(`PercentageOfSpan ToleranceCalculationType requires the Tolerance's unit to be Percentage!`)
                }
                if (maximumRange < minimumRange) {
                    console.warn('maximumRange should not be lower than minimumRange!')
                }

                const span = Math.abs(maximumRange - minimumRange)

                // Reversed the operator sign to handle negative values
                min = value < 0 ? value + (span * (lowerRange / 100)) : value - (span * (lowerRange / 100))
                max = value < 0 ? value - (span * (higherRange / 100)) : value + (span * (higherRange / 100))

                return {
                    unitOfMeasurement: deepCopy(valueUOM),
                    minimumRange: round(min),
                    maximumRange: round(max)
                } as UnitRange

        }
    }
}

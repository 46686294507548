<app-admin-form-row [formGroup]="sequentialTemplateForm"
                    label="PROCESS *">
    <div class="em-l-grid__item admin-input-width">
        <app-dropdown [form]="sequentialTemplateForm"
                      [formControlPath]="'process'"
                      [options]="(processes$ | async)"
                      [key]="'id'"
                      [value]="'name'">
        </app-dropdown>
    </div>
</app-admin-form-row>
<app-admin-form-row [formGroup]="sequentialTemplateForm"
                    label="TEMPLATE ALIAS">
    <div class="em-l-grid__item admin-input-width">
        <input formControlName="alias"
               type="text"
               class="em-c-input em-u-font-style-regular"
               maxlength="20">
    </div>
</app-admin-form-row>
<app-admin-form-row [formGroup]="sequentialTemplateForm"
                    label="NUMBER OF POINTS *">
    <div class="em-l-grid__item admin-input-width">
        <app-dropdown [form]="sequentialTemplateForm.controls.numberOfPoints"
                      [formControlPath]="'point'"
                      [options]="numberOfPointOptions"
                      [key]="'key'"
                      [value]="'value'">
        </app-dropdown>
    </div>
</app-admin-form-row>
<br>

<app-admin-form-row [formGroup]="sequentialTemplateForm"
                    label="INPUT RANGE *"
                    description="(Measurement Range)">
    <div class="em-l-grid__item admin-input-width">
        <app-dropdown [form]="sequentialTemplateForm.controls.input"
                      [formControlPath]="'unitOfMeasurement'"
                      [options]="(uomList$ | async | uomPresentationPipe | filterPercentUom)"
                      [key]="'uomCode'"
                      [value]="'uomDisplayValue'">
        </app-dropdown>
    </div>
    <div formGroupName="input"
         class="em-l-grid__item admin-input-width em-c-field"
         [ngClass]="{'em-is-disabled': sequentialTemplateForm.disabled}">
        <div class="em-l-grid em-l-grid--2up">
            <div class="em-l-grid__item em-u-padding-top-none">
                <app-numeric-input formControlName="minimumRange"
                                   [clearZeroOnFocus]="true">
                </app-numeric-input>
            </div>
            <div class="em-l-grid__item em-u-padding-top-none">
                <app-numeric-input formControlName="maximumRange"
                                   [clearZeroOnFocus]="true">
                </app-numeric-input>
            </div>
        </div>
    </div>
</app-admin-form-row>
<app-admin-form-row [formGroup]="sequentialTemplateForm"
                    label="EXPECTED OUTPUT RANGE *"
                    description="(DCS Range)">
    <div class="em-l-grid__item admin-input-width">
        <app-dropdown [form]="sequentialTemplateForm.controls.expected"
                      [formControlPath]="'unitOfMeasurement'"
                      [options]="(uomList$ | async | uomPresentationPipe | filterPercentUom)"
                      [key]="'uomCode'"
                      [value]="'uomDisplayValue'">
        </app-dropdown>
    </div>
    <div formGroupName="expected"
         class="em-l-grid__item admin-input-width em-c-field"
         [ngClass]="{'em-is-disabled': sequentialTemplateForm.disabled}">
        <div class="em-l-grid em-l-grid--2up">
            <div class="em-l-grid__item em-u-padding-top-none">
                <app-numeric-input formControlName="minimumRange"
                                   [clearZeroOnFocus]="true">
                </app-numeric-input>
            </div>
            <div class="em-l-grid__item em-u-padding-top-none">
                <app-numeric-input formControlName="maximumRange"
                                   [clearZeroOnFocus]="true">
                </app-numeric-input>
            </div>
        </div>
    </div>
</app-admin-form-row>
<app-admin-form-row [formGroup]="sequentialTemplateForm"
                    label="TOLERANCE *">
    <div class="em-l-grid__item admin-input-width">
        <app-dropdown *ngIf="selectiveUOMList.length > 0"
                      [form]="sequentialTemplateForm.controls.tolerance"
                      [formControlPath]="'unitOfMeasurement'"
                      [options]="selectiveUOMList | uomPresentationPipe"
                      [key]="'uomCode'"
                      [value]="'uomDisplayValue'">
        </app-dropdown>
        <app-dropdown *ngIf="selectiveUOMList.length === 0"
                      [form]="sequentialTemplateForm.controls.tolerance"
                      [formControlPath]="'unitOfMeasurement'"
                      [options]="(uomList$ | async | uomPresentationPipe)"
                      [key]="'uomCode'"
                      [value]="'uomDisplayValue'">
        </app-dropdown>
    </div>
    <div formGroupName="tolerance"
         class="em-l-grid__item admin-input-width em-c-field"
         [ngClass]="{'em-is-disabled': sequentialTemplateForm.disabled}">
        <app-numeric-input formControlName="value"
                           [clearZeroOnFocus]="true">
        </app-numeric-input>
    </div>
</app-admin-form-row>
<app-admin-form-row [formGroup]="sequentialTemplateForm"
                    label="CALCULATION TYPE">
    <div class="em-l-grid__item admin-calculation-type">
        <app-options [formGroup]="sequentialTemplateForm"
                     [formControlPath]="'calculationType'"
                     [defaultOption]="defaultCalculationType"
                     [returnType]="3"
                     [labelStyle]="'em-u-width-33'"
                     [disabled]="isCalculationTypeDisabled || sequentialTemplateForm.disabled"
                     [options]="calculationTypes"
                     [key]="'id'"
                     [value]="'name'">
        </app-options>
    </div>
</app-admin-form-row>
<app-admin-form-row [formGroup]="sequentialTemplateForm"
                    label="PROCEDURE">
    <div class="em-l-grid__item admin-input-width em-c-field"
         [ngClass]="{'em-is-disabled': sequentialTemplateForm.disabled}">
        <input formControlName="procedure"
               type="text"
               placeholder="B4P-RXG-XXX.doc"
               class="em-c-input em-u-font-style-regular">
    </div>
</app-admin-form-row>
<br>
<app-admin-form-row [formGroup]="sequentialTemplateForm"
                    label="TEST EQUIPMENT"
                    description="(Choose 'Required' if you want technician to select test equipment before completing the work)">
    <div class="em-l-grid__item admin-input-width">
        <app-options [formGroup]="sequentialTemplateForm"
                     [formControlPath]="'isTestEquipmentRequired'"
                     [defaultOption]="getOption(sequentialTemplateForm.controls.isTestEquipmentRequired.value, isRequiredOption)"
                     [labelStyle]="'em-u-width-50'"
                     [options]="isRequiredOption"
                     [key]="'key'"
                     [disabled]="sequentialTemplateForm.disabled"
                     [value]="'value'">
        </app-options>
    </div>
</app-admin-form-row>
<app-admin-form-row [formGroup]="sequentialTemplateForm"
                    label="INJECTED VALUE REQUIRED"
                    description="(Will add extra column for &quot;input&quot;)">
    <div class="em-l-grid__item admin-input-width">
        <app-options [formGroup]="sequentialTemplateForm"
                     [formControlPath]="'isInjectedInputRequired'"
                     [defaultOption]="getOption(sequentialTemplateForm.controls.isInjectedInputRequired.value, yesNoOption)"
                     [labelStyle]="'em-u-width-50'"
                     [options]="yesNoOption"
                     [key]="'key'"
                     [disabled]="sequentialTemplateForm.disabled"
                     [value]="'value'">
        </app-options>
    </div>
</app-admin-form-row>

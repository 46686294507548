import { Update } from '@ngrx/entity'
import { Action } from '@ngrx/store'

import { BackgroundSyncStatusEnum, CacheStatusEnum } from '@app/models/offline-status.enum'
import { CalibrationDetails } from '@app/modules/calibration/models/calibration-details.model'
import { WorkOrderEquipment } from '@app/modules/calibration/models/work-order-equipment.model'
import { EquipmentTemplateCoreDetails } from '@app/modules/equipment/models/equipment-template-core-details.model'
import { FlamingoSchema } from '@app/modules/flamingo/models/flamingo-form.model'
import { UploadQueue } from '@app/modules/shared/models/upload-queue.model'
import { WorkOrderListItem } from '@app/modules/work-order-search/models/work-order-list-item.model'
import { WorkOrderDetails } from '@app/modules/work-order/models/work-order-details.model'
import { WorkOrderCache } from './offline.reducer'

export enum ActionType {
    InitAPICaching = '[Offline] Start to load all the APIs',
    CacheUserOfflineItems = '[Offline] Trigger caching of offline items',
    CacheUserWoItems = '[Offline] Add list of work order cache status',
    CacheUserEqTemplateItems = '[Offline] Trigger caching of user equipment template items',
    CacheEqCylinderHistory = '[Offline] Trigger caching of user equipment cylinder history',
    CacheUserEqCalibrationItems = '[Offline] Trigger caching of user equipment calibration item',
    CacheUserNotificationItems = '[Offline] Trigger caching of user notification item',
    CacheUserFlamingoFormMappingItems = '[Offline] Trigger caching of user flamingo form mapping item',
    CacheUserFlamingoFormSchemaItems = '[Offline] Trigger caching of user flamingo form schema item',
    CacheUserTodoItemsSuccess = '[Offline] Trigger caching of user todo items success',
    CacheUserTodoItemsFailure = '[Offline] Trigger caching of user todo items failure',
    UpdateTestEquipmentListCacheStatus = '[Offline] Update test equipment master data cache status',
    UpdateUserListCacheStatus = '[Offline] Update user master data cache status',
    UpdateEquipmentTemplateCacheStatus = '[Offline] Update template cache status for EQ & WO',
    UpdateEquipmentCalibrationCacheStatus = '[Offline] Update calibration cache status for EQ & WO',
    UpdateEquipmentFlamingoCacheStatus = '[Offline] Update flamingo cache status for EQ & WO',
    UpdateWorkOrderDetailCacheStatus = '[Offline] Update work order detail cache status for WO',
    UpdatePMSyncStatus = '[Offline] Update Update PM sync status',
    RemoveWorkOrderCacheStatus = '[Offline] Remove work order detail from cache status',
    RemoveAllWorkOrdersCacheStatus = '[Offline] Remove all work order detail from cache status',
    UpdateWorkOrderEquipmentCalibrationStatus = '[Offline] Update work order equipment calibration status',
    AddPMToUploadQueue = '[Offline] Add PM to upload queue',
    UpdatePMUploadLoadQueue = '[Offline] Update PM upload queue',
    RemovedPMFromUploadQueue = '[Offline] Remove PM from upload queue',
    ClearOfflineCache = '[Offline] Clear Offline Cache',
    GetAutoDownloadEnabled = '[Offline] Get Auto Download enabled',
    LoadAutoDownloadEnabledSuccess = '[Offline] Load Auto Download enabled success',
    UpdateAutoDownloadEnabled = '[Offline] Update Auto Download enabled'
}

export class InitAPICaching {
    readonly type = ActionType.InitAPICaching

    constructor(public payload: WorkOrderListItem[]) { }
}

export class CacheUserOfflineItems implements Action {
    readonly type = ActionType.CacheUserOfflineItems

    constructor(public payload: WorkOrderListItem[]) { }
}

export class CacheUserWoItems {
    readonly type = ActionType.CacheUserWoItems

    constructor(public payload: WorkOrderDetails[]) { }
}

export class CacheUserEqTemplateItems implements Action {
    readonly type = ActionType.CacheUserEqTemplateItems

    constructor(public equipmentIdList: string[]) { }
}
export class CacheEqCylinderHistory implements Action {
    readonly type = ActionType.CacheEqCylinderHistory

    constructor(public equipmentIdList: string[]) { }
}

export class CacheUserEqCalibrationsItems implements Action {
    readonly type = ActionType.CacheUserEqCalibrationItems

    constructor(public woEq: WorkOrderEquipment[]) { }
}

export class CacheUserNotificationItems implements Action {
    readonly type = ActionType.CacheUserNotificationItems

    constructor(public notificationNumbers: string[], public workOrderNumber: string) { }
}

export class CacheUserFlamingoFormMappingItems implements Action {
    readonly type = ActionType.CacheUserFlamingoFormMappingItems

    constructor(public formIds: string[]) { }
}

export class CacheUserFlamingoFormSchemaItems implements Action {
    readonly type = ActionType.CacheUserFlamingoFormSchemaItems

    constructor(public formIds: string[]) { }
}

export class CacheUserTodoItemsSuccess implements Action {
    readonly type = ActionType.CacheUserTodoItemsSuccess
}

export class CacheUserTodoItemsFailure implements Action {
    readonly type = ActionType.CacheUserTodoItemsFailure
}

export class UpdateTestEquipmentListCacheStatusAction implements Action {
    readonly type = ActionType.UpdateTestEquipmentListCacheStatus

    constructor(public payload: { cacheStatus: CacheStatusEnum }) { }
}

export class UpdateUserListCacheStatusAction implements Action {
    readonly type = ActionType.UpdateUserListCacheStatus

    constructor(public payload: { cacheStatus: CacheStatusEnum }) { }
}

export class UpdateWorkOrderDetailCacheStatusAction implements Action {
    readonly type = ActionType.UpdateWorkOrderDetailCacheStatus

    constructor(public payload: {
        workOrderCaches: Update<WorkOrderCache>[]
    }) { }
}

export class UpdateEquipmentTemplateCacheStatusAction implements Action {
    readonly type = ActionType.UpdateEquipmentTemplateCacheStatus

    constructor(public payload: { eqTemplates: EquipmentTemplateCoreDetails[][] }) { }
}

export class UpdateEquipmentCalibrationCacheStatusAction implements Action {
    readonly type = ActionType.UpdateEquipmentCalibrationCacheStatus

    constructor(public payload: { eqCalibrations: CalibrationDetails[] }) { }
}

export class UpdateEquipmentFlamingoCacheStatusAction implements Action {
    readonly type = ActionType.UpdateEquipmentFlamingoCacheStatus

    constructor(public payload: { eqFlamingos: FlamingoSchema[] }) { }
}

export class UpdateWorkOrderEquipmentCalibrationStatusAction implements Action {
    readonly type = ActionType.UpdateWorkOrderEquipmentCalibrationStatus

    constructor(public calibrationDetails: CalibrationDetails) { }
}

export class RemoveWorkOrderCacheStatusAction implements Action {
    readonly type = ActionType.RemoveWorkOrderCacheStatus

    constructor(public payload: string) { }
}

export class RemoveAllWorkOrderCacheStatusAction implements Action {
    readonly type = ActionType.RemoveAllWorkOrdersCacheStatus

    constructor(public payload: string[]) { }
}

export class AddPMToUploadQueueAction implements Action {
    readonly type = ActionType.AddPMToUploadQueue

    constructor(public payload: UploadQueue) { }
}

export class UpdatePMUploadQueueAction implements Action {
    readonly type = ActionType.UpdatePMUploadLoadQueue

    constructor(public payload: UploadQueue) { }
}

export class UpdatePMSyncStatusAction implements Action {
    readonly type = ActionType.UpdatePMSyncStatus

    constructor(public payload: {
        workOrderNumber: string,
        equipmentId: string,
        status: BackgroundSyncStatusEnum
    }) { }
}

export class RemovePMFromUploadQueueAction implements Action {
    readonly type = ActionType.RemovedPMFromUploadQueue

    constructor(public payload: UploadQueue = null) { }
}

export class ClearOfflineCache implements Action {
    readonly type = ActionType.ClearOfflineCache
}

export class GetAutoDownloadEnabledAction implements Action {
    readonly type = ActionType.GetAutoDownloadEnabled
}

export class LoadAutoDownloadEnabledSuccessAction implements Action {
    readonly type = ActionType.LoadAutoDownloadEnabledSuccess

    constructor(public isAutoDownloadEnabled: boolean) { }
}

export class UpdateAutoDownloadEnabledAction implements Action {
    readonly type = ActionType.UpdateAutoDownloadEnabled

    constructor(public isAutoDownloadEnabled: boolean) { }
}

export type OfflineActionUnion = InitAPICaching
    | CacheUserOfflineItems
    | CacheUserWoItems
    | CacheUserEqTemplateItems
    | CacheUserEqCalibrationsItems
    | CacheUserNotificationItems
    | CacheUserTodoItemsSuccess
    | CacheUserTodoItemsFailure
    | CacheUserFlamingoFormMappingItems
    | CacheUserFlamingoFormSchemaItems
    | UpdateTestEquipmentListCacheStatusAction
    | UpdateUserListCacheStatusAction
    | UpdateEquipmentTemplateCacheStatusAction
    | UpdateEquipmentCalibrationCacheStatusAction
    | UpdateEquipmentFlamingoCacheStatusAction
    | UpdateWorkOrderDetailCacheStatusAction
    | UpdateWorkOrderEquipmentCalibrationStatusAction
    | UpdatePMSyncStatusAction
    | RemoveWorkOrderCacheStatusAction
    | RemoveAllWorkOrderCacheStatusAction
    | AddPMToUploadQueueAction
    | UpdatePMUploadQueueAction
    | RemovePMFromUploadQueueAction
    | ClearOfflineCache
    | GetAutoDownloadEnabledAction
    | LoadAutoDownloadEnabledSuccessAction
    | UpdateAutoDownloadEnabledAction

import { Action } from '@ngrx/store'

import { User, UserQueryParameter } from '@app/models/user.model'
import { QuerySummary } from '@app/modules/shared/models/query-summary.model'

export enum ActionType {
    FilterUser = '[User] Filter User',
    GetAllUser = '[User] Get All User',
    GetAllUserSuccess = '[User] Get All User Success',
    GetAllUserFailure = '[User] Get All User Failure',
    SelectUser = '[User] Select User',
    UpdateUserCertification = '[User] Update User Certification',
    UpdateUserCertificationSuccess = '[User] Update User Certification Success',
    UpdateUserCertificationFailure = '[User] Update User Certification Failure',
    GetUserListFetchingFetchingProcess = '[User] Get user list fetching progress',
    LogoutUser = '[User] Clear individual user cache'
}

export class FilterUser implements Action {
    readonly type = ActionType.FilterUser

    constructor(public payload: QuerySummary<UserQueryParameter>) { }
}

export class GetAllUserAction implements Action {
    readonly type = ActionType.GetAllUser

    constructor(public forceFetch = false) { }
}

export class GetAllUserSuccessAction implements Action {
    readonly type = ActionType.GetAllUserSuccess

    constructor(public payload: User[], public lastSyncDate: string) { }
}

export class GetAllUserFailureAction implements Action {
    readonly type = ActionType.GetAllUserFailure
}

export class SelectUserAction implements Action {
    readonly type = ActionType.SelectUser

    constructor(public payload: User) { }
}

export class UpdateUserCertificationAction implements Action {
    readonly type = ActionType.UpdateUserCertification

    constructor(public payload: User) { }
}

export class UpdateUserCertificationSuccessAction implements Action {
    readonly type = ActionType.UpdateUserCertificationSuccess

    constructor(public payload: User) { }
}

export class UpdateUserCertificationFailureAction implements Action {
    readonly type = ActionType.UpdateUserCertificationFailure
}

export class GetUserListFetchingFetchingProcessAction implements Action {
    readonly type = ActionType.GetUserListFetchingFetchingProcess
}

export class LogoutUserAction implements Action {
    readonly type = ActionType.LogoutUser
}

export type UserAction = FilterUser
    | GetAllUserAction
    | GetAllUserSuccessAction
    | GetAllUserFailureAction
    | SelectUserAction
    | UpdateUserCertificationAction
    | UpdateUserCertificationSuccessAction
    | UpdateUserCertificationFailureAction
    | GetUserListFetchingFetchingProcessAction
    | LogoutUserAction


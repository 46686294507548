import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import moment from 'moment'

import { maxDaysLimit } from '@app/modules/report/components/work-management-report/work-management-report.constant'
import { deepCopy } from '@app/utils/app-utils.function'
import { ReportFilterFields, CalibrationPresets } from '../constants/report-filter-list.constant'
import { ReportListQueryParameter } from '../models/report-list-query.model'

export interface FiltersApiData {
    id?: number,
    presetName: string,
    type: string,
    isDefault: boolean,
    filter: any,
}

const workorderFields = [
    'plannerGroup',
    'mainWorkCenter',
    'maintenancePlant',
    'plantSection',
    'location',
    'criticality',
    'category',
    'classification',
]

const reportFilterFields = [
    'templateTypes',
    'reviewStatus',
    'pmStatus',
    'pmResult',
    'pmPerformedBy',
    'workOrderType',
]

export const presetListMapApiData = (filters: CalibrationPresets, isDefault: boolean = false): FiltersApiData => {
    const filter = {}
    const { queryParams, reportFilters } = filters.childFilter

    Object.keys(reportFilters).forEach(key => {
        const { value } = reportFilters[key]
        const newValues = value.filter(item => item.selected).map(item => item.id ? item.id : item.name)
        if (key !== 'pmPerformedDate' && newValues.length > 0) {
            filter[key] = newValues
        }
    })

    workorderFields.forEach((field) => {
        if (queryParams[field]) {
            filter[field] = queryParams[field].split(',')
        }
    })


    return {
        id: filters?.id,
        presetName: filters.name,
        type: 'Calibration',
        isDefault,
        filter: JSON.stringify(filter)
    }
}

const childFilterMap = (filters: FiltersApiData, reportFilters): {
    queryParams: ReportListQueryParameter
    reportFilters: ReportFilterFields
} => {
    const queryParams = {}
    workorderFields.forEach((key) => {
        if (filters[key]) {
            queryParams[key] = filters[key].join(',')
        }
    })

    const mappedReportFilters = deepCopy(reportFilters)
    reportFilterFields.forEach((key) => {
        mappedReportFilters[key].value = mappedReportFilters[key].value.map((item => {
            if (filters[key]?.some(val => item.id ? val === item.id : val === item.name)) {
                return {
                    ...item,
                    selected: true
                }
            }
            return item
        }))
    })

    return {
        queryParams,
        reportFilters: mappedReportFilters
    };
}

export const apiDataMapPresetList =
    (filtersApiData: FiltersApiData[], reportFilterList: ReportFilterFields)
        : CalibrationPresets[] => {
        return filtersApiData.map(item => {
            return {
                id: item.id,
                name: item.presetName,
                childFilter: childFilterMap(JSON.parse(item.filter), reportFilterList),
                selected: item.isDefault
            }
        })
    }

export const filterQueryFields = (query: ReportListQueryParameter): ReportListQueryParameter => {
    const queryParameters = deepCopy(query)
    Object.keys(queryParameters).forEach(key => {
        if (!workorderFields.includes(key)) {
            delete queryParameters[key]
        }
    })
    return queryParameters
}

export const clearReportFilterList = (list: ReportFilterFields): ReportFilterFields => {
    const reportFilterList = deepCopy(list)
    reportFilterList.pmPerformedBy.value.forEach(f => delete f['selected'])
    reportFilterList.pmResult.value.forEach(f => delete f['selected'])
    reportFilterList.pmStatus.value.forEach(f => delete f['selected'])
    reportFilterList.reviewStatus.value.forEach(f => delete f['selected'])
    reportFilterList.templateTypes.value.forEach(f => delete f['selected'])
    reportFilterList.workOrderType.value.forEach(f => delete f['selected'])
    return reportFilterList
}

export const dateRangeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const fromDate = control.get('fromDate')
    const toDate = control.get('toDate')
    if (fromDate && toDate) {
        const diffDays = moment(toDate.value).diff(moment(fromDate.value), 'days')
        return diffDays > maxDaysLimit ? { identityRevealed: true } : null
    }
    return null
}

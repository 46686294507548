import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'

import { ResponseHandlerService } from '@app/services/response-handler.service'
import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'
import { ResponseHandlingStrategyBuilder } from '@app/services/response-handling-strategy.builder'
import { SapDataSyncService } from '../../modules/core/services/sap-data-sync.service'
import { GetLatestSyncDateAction, GetLatestSyncDateFailureAction, GetLatestSyncDateSuccessAction } from './sap-data-sync.actions'

@Injectable()
export class SapDataSyncEffects {

    getLatestSyncDateEffect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetLatestSyncDateAction),
            mergeMap((query) => {
                return this.responseHandlerService.query(
                    () => this.sapDataSyncService.getLatestSyncDate(),
                    this.getLatestSyncDateStrategy
                )
            }),
            map((response) => {
                return GetLatestSyncDateSuccessAction(response.body)
            }),
            catchError(_ => of(GetLatestSyncDateFailureAction()))
        )
    )

    private getLatestSyncDateStrategy: ResponseHandlingStrategy

    constructor(
        private actions$: Actions,
        private responseHandlerService: ResponseHandlerService,
        private sapDataSyncService: SapDataSyncService
    ) {
        this.getLatestSyncDateStrategy = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .responseStrategy
    }
}

import { SapDataSync } from '@app/models/sap-data-sync.model'
import { GetLatestSyncDateSuccessAction, SapDataSyncAction } from './sap-data-sync.actions'

export interface SapDataSyncState {
    sapLastSync: SapDataSync
}

const initialState: SapDataSyncState = {
    sapLastSync: {
        lastSyncDate: undefined,
        eqLastSyncDate: undefined,
        woLastSyncDate: undefined
    }
}

export function reducer(state = initialState, action: SapDataSyncAction): SapDataSyncState {
    switch (action.type) {
        case GetLatestSyncDateSuccessAction.type:
            return {
                ...state,
                sapLastSync: { ...action } as SapDataSync
            }
        default:
            return state
    }
}

import { CalibrationActionRequire } from '@app/modules/shared/constants/calibration-action-require.enum'
import { isNotAValue } from '@app/utils/app-utils.function'
import { CalibrationDetails } from '../models/calibration-details.model'
import { CalibrationResultStatusEnum } from '../models/calibration-result-status.enum'
import { CalibrationStatusEnum } from '../models/calibration-status.enum'

export class CalibrationActionRequired {
    public static getRequiredAction(calibrationDetails: CalibrationDetails): string[] {
        const { calibrationStatus, finalPMResultStatus, atoDate, atoNumber } = calibrationDetails
        const actionRequired = []

        if (calibrationStatus.id !== CalibrationStatusEnum.Completed) {
            return actionRequired
        }

        if (finalPMResultStatus.id === CalibrationResultStatusEnum.Failed) {
            actionRequired.push(CalibrationActionRequire.RepairWorkOrder.toString())
        }

        const atoNumberIsMissing = isNotAValue(atoNumber)
        const atoDateIsMissing = isNotAValue(atoDate)
        const isDeferOrNotTest =
            finalPMResultStatus.id === CalibrationResultStatusEnum.Deferred ||
            finalPMResultStatus.id === CalibrationResultStatusEnum.NotTested

        if (isDeferOrNotTest && atoDateIsMissing) {
            actionRequired.push(CalibrationActionRequire.ATODueDate.toString())
        }

        if (isDeferOrNotTest && atoNumberIsMissing) {
            actionRequired.push(CalibrationActionRequire.ATONumber.toString())
        }

        return actionRequired
    }
}

export enum CalibrationResultStatusEnum {
    Passed = 1,
    Failed = 2,
    FailedAdjustedPassed = 3,
    Deferred = 4,
    NotTested = 5,
    PassedAdjustedPassed = 6
}

export enum CalibrationResultStatusTextEnum {
    'passed' = CalibrationResultStatusEnum.Passed,
    'failed' = CalibrationResultStatusEnum.Failed,
    'failed adjusted passed' = CalibrationResultStatusEnum.FailedAdjustedPassed,
    'deferred' = CalibrationResultStatusEnum.Deferred,
    'not tested' = CalibrationResultStatusEnum.NotTested,
    'passed adjusted passed' = CalibrationResultStatusEnum.PassedAdjustedPassed
}

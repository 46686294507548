import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

import { EquipmentTemplateCoreDetails } from '@app/modules/equipment/models/equipment-template-core-details.model'
import { TemplateTypeOption } from '@app/modules/equipment/models/template-type.model'
import { deepCopy } from '@app/utils/app-utils.function'
import { AppSettings } from '@settings/app.settings'
import { ActionType, EquipmentTemplateAction } from '../actions/equipment-template.actions'

export interface EquipmentTemplateState extends EntityState<EquipmentTemplateCoreDetails> {
    selectedTemplate: TemplateTypeOption
}

export const adapter: EntityAdapter<EquipmentTemplateCoreDetails> = createEntityAdapter<EquipmentTemplateCoreDetails>({
    selectId: template => template.id
})

export const initialState: EquipmentTemplateState = adapter.getInitialState({
    selectedTemplate: undefined
})

export function reducer(state = initialState, action: EquipmentTemplateAction): EquipmentTemplateState {
    switch (action.type) {
        case ActionType.GetEquipmentTemplateDetails:
            return adapter.removeAll(state)
        case ActionType.GetEquipmentTemplateDetailSuccess:
            return adapter.setAll(action.payload, state)
        case ActionType.AddEquipmentTemplate:
            return adapter.addOne({ ...action.payload, isNew: true }, state)
        case ActionType.RemoveEquipmentTemplate:
            return adapter.removeOne(action.payload.id.toString(), state)
        case ActionType.UpdateEquipmentTemplateSuccess:
            let templateUniqueId: number
            if (action.selectedTemplateId !== action.payload.id) {
                templateUniqueId = action.selectedTemplateId
            } else {
                templateUniqueId = action.payload.id
            }
            return adapter.updateOne({
                id: templateUniqueId.toString(),
                changes: action.payload as Partial<EquipmentTemplateCoreDetails>
            }, state)
        case ActionType.EquipmentTemplateWasModified:
            const modifiedTemplate = deepCopy(action.payload) as EquipmentTemplateCoreDetails
            modifiedTemplate.detail.notSaved = !action.discardChanges
            modifiedTemplate.detail.isValid = action.isValid
            return {
                ...adapter.updateOne({
                    id: action.payload.id.toString(),
                    changes: modifiedTemplate
                }, state)
            }
        case ActionType.SelectTemplateType:
            return {
                ...state,
                selectedTemplate: {
                    templateUniqueId: action.payload.id,
                    id: action.payload.templateTypeId,
                    name: action.payload.templateTypeName,
                    numberOfPoint: [action.payload.detail.numberOfPoint],
                    isActive: true,
                    notSaved: action.payload.id >= AppSettings.newTemplateIdRange.min
                } as TemplateTypeOption
            }
        case ActionType.CacheEquipmentTemplate:
            const template = deepCopy(action.payload) as EquipmentTemplateCoreDetails
            template.detail.notSaved = true
            return adapter.updateOne({
                id: action.payload.id.toString(),
                changes: template
            }, state)
        case ActionType.ClearAllTemplateDetail:
            return adapter.removeAll(state)
        default:
            return state
    }
}

export const {
    selectIds,
    selectEntities,
    selectAll,
    selectTotal,
} = adapter.getSelectors()

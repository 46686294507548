import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { HyperlinkInfo } from '@app/modules/calibration/models/hyperlink.model'
import { externalLinkWarningModalObject } from '@app/modules/shared/components/hyperlink-info/hyperlink-list-modal/hyperlink.modal-object'
import { QueryBuilderService } from '@app/services/query-builder.service'
import { AppState } from '@app/store/app.store'
import { ShowModalAction } from '@app/store/modal/modal.actions'
import { environment } from '@environments/environment'
import { AppSettings } from '@settings/app.settings'

@Injectable({
    providedIn: 'root'
})
export class HyperlinkService {
    private readonly url = `${environment.baseUrl}/api/${AppSettings.apiVersion}/Equipments/Procedures`

    constructor(private queryBuilderService: QueryBuilderService, private store: Store<AppState>) {
    }

    public getHyperlinkByEquipmentId(equipmentId: string): Observable<HyperlinkInfo> {
        return this.queryBuilderService
            .get<HyperlinkInfo>(`${this.url}?EquipmentId=${equipmentId}`)
            .pipe(map(response => response.body))
    }

    public isBrowserSupportedLink(externalUrl: string): boolean {
        const diskDriveRegex = /^[a-z]:/i
        return !externalUrl.startsWith('\\\\') &&
            !externalUrl.startsWith('//') &&
            !diskDriveRegex.test(externalUrl)
    }

    public openHyperlink(externalUrl: string): void {
        let url = externalUrl
        if (!url.startsWith('https://')) {
            url = 'https://' + url
        }
        window.open(url)
    }

    public showExternalLinkWarningModal(procedureLink: string): void {
        if (procedureLink) {
            let title = externalLinkWarningModalObject.title
            let confirmLabel = externalLinkWarningModalObject.confirmLabel
            let closeLabel = externalLinkWarningModalObject.closeLabel
            if (!this.isBrowserSupportedLink(procedureLink)) {
                title = 'UNSUPPORTED LINK'
                confirmLabel = null
                closeLabel = 'Got it'
            }
            this.store.dispatch(new ShowModalAction({
                ...externalLinkWarningModalObject,
                title,
                closeLabel,
                confirmLabel,
                confirmCallback: () => this.openHyperlink(procedureLink),
                params: {
                    externalLink: procedureLink
                }
            }))
        }
    }
}

import { Injectable } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'

import { EquipmentTemplateCoreDetails } from '../models/equipment-template-core-details.model'
import { ChecklistQuestion, CalibrationChecklist } from '@app/modules/calibration/models/checklist.model'

import { TemplateTypeEnum } from '../models/template-type.enum'
import { SpecialUomCode } from '@app/models/special-uom.model'

@Injectable({
    providedIn: 'root'
})
export class EquipmentTemplateUtilService {
    public findEquipmentTemplateById(templates: EquipmentTemplateCoreDetails[], equipmentId: string): EquipmentTemplateCoreDetails {
        return templates.find(template => template.equipmentId === equipmentId)
    }

    public transformChecklistQuestions(checklistQuestions: ChecklistQuestion[]): CalibrationChecklist[] {
        return checklistQuestions.map(checklistQuestion => {
            return {
                question: checklistQuestion,
                response: null
            }
        })
    }

    public uomIsInch16(form: UntypedFormControl, templateTypeId: number): boolean {
        const uomCode = form.value?.uomCode
        if (uomCode === SpecialUomCode.FeetInch16 && templateTypeId === TemplateTypeEnum.Repeatability) {
            return true
        }
        return false
    }
}

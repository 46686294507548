import { ApplicationRef, enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core'
import { enableDebugTools, BrowserModule, bootstrapApplication } from '@angular/platform-browser'
import { datadogRum } from '@datadog/browser-rum'

import { AppModule, REDUCER_TOKEN } from './app/app.module'
import { environment } from '@environments/environment'
import { AppComponent } from './app/app.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { metaReducers } from '@app/store/storeFreeze.reducer';
import { StoreRouterConnectingModule, FullRouterStateSerializer } from '@ngrx/router-store';
import { ToastrModule } from 'ngx-toastr';
import { EffectsModule } from '@ngrx/effects';
import { FlamingoWebComponentsAngularModule } from '@emorg-prd/flamingo-web-components-angular';
import { OverlayModule } from '@angular/cdk/overlay';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@app/app-routing.module';
import { CommonModule } from '@angular/common';
import { PolicyCheckerServiceInjectorHelper } from './app/services/policy-checker-injector-helper.service';
import { GlobalErrorHandler } from './app/utils/classes/app-error-handlers/global-error-handler';
import { MSALInstanceFactory, MSALGuardConfigFactory, MSALInterceptorConfigFactory } from '@settings/msal.settings';
import { HTTPErrorHandlerInterceptor } from './app/interceptors/http-error-handler.interceptor';
import { OauthInterceptor } from './app/interceptors/oauth.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { MsalInterceptor, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalService, MsalGuard, MsalBroadcastService, MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { appReducers, appEffects } from '@app/store/app.store';
import { StoreModule } from '@ngrx/store';
import { BackgroundQueueHandlerService } from './app/services/background-queue-handler.service';


if (!environment.development) {
    enableProdMode()
}

// (Production only)
// This sends data to DataDog for user behavior tracing in addition to Application Insights.
// See data collected: https://app.datadoghq.com/account/login/id/7bebd0eda
// Ref: https://docs.datadoghq.com/real_user_monitoring/
if (environment.name?.toLocaleLowerCase() === 'mrma') {
    datadogRum.init({
        applicationId: 'a231306d-bf38-44a4-870b-8bba48b61493',
        clientToken: 'pubb3eed2894347a90a9a6bc1c1fe9d41c1',
        site: 'datadoghq.com',
        service: 'mrma-web-production',

        version: environment.version,
        sampleRate: 100,
        sessionReplaySampleRate: 100,
        trackInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',

        // Workaround for the issue that DataDog can't differentiate paths with '#'
        // Ref: https://github.com/DataDog/browser-sdk/issues/540
        beforeSend: event => {
            event.view.url = event.view.url.replace('#/', '')
        }
    })

    datadogRum.startSessionReplayRecording()
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            AppModule,
            MsalModule,
            CommonModule,
            BrowserModule,
            AppRoutingModule,
            OverlayModule,
            FlamingoWebComponentsAngularModule,
            EffectsModule.forRoot(appEffects),
            ToastrModule.forRoot({ preventDuplicates: true, newestOnTop: false }),
            StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),
            StoreModule.forRoot(REDUCER_TOKEN, { metaReducers, runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true } }),
            environment.development ? StoreDevtoolsModule.instrument() : []
        ),
        BackgroundQueueHandlerService,
        {
            provide: REDUCER_TOKEN,
            useValue: appReducers
        },
        MsalInterceptor,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OauthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HTTPErrorHandlerInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        PolicyCheckerServiceInjectorHelper,
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations()
    ]
})
    .then(moduleRef => {

        moduleRef.bootstrap(MsalRedirectComponent)

        if (environment.development) {
            // Enable console debug tools
            // e.g. `ng.profiler.timeChangeDetection()`
            const appRef = moduleRef.injector.get(ApplicationRef)
            const componentRef = appRef.components[0]

            enableDebugTools(componentRef)
        }
    })
    .catch(err => console.error(err))

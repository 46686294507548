import { Component, Input, OnInit } from '@angular/core'
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'

import { SpecialUomCode } from '@app/models/special-uom.model'
import { CalculationType } from '@app/modules/equipment/models/calculation-type.model'
import * as TemplateOptions from '@app/modules/equipment/models/default-template-options-values'
import { EquipmentTemplateCoreDetails } from '@app/modules/equipment/models/equipment-template-core-details.model'
import { EquipmentTemplateDetailInput } from '@app/modules/equipment/models/equipment-template-detail-input.model'
import { Process } from '@app/modules/equipment/models/process.model'
import { TemplateTypeOption } from '@app/modules/equipment/models/template-type.model'
import { SequentialTemplateValidationsService } from '@app/modules/equipment/services/template-validations/sequential-template-validations.service'
import { UOM } from '@app/modules/shared/models/engineering-units/unit-of-measurement.model'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppState } from '@app/store/app.store'
import * as EquipmentTemplateAction from '@app/store/equipment/actions/equipment-template.actions'
import * as TemplateFormAction from '@app/store/equipment/actions/template-form.actions'
import { equipmentTemplateList, selectedTemplate } from '@app/store/equipment/selectors/equipment-template.selectors'
import { process } from '@app/store/equipment/selectors/process.selectors'
import { uomList } from '@app/store/equipment/selectors/uom.selectors'
import { deepCopy, findOptionWithKey, isNotAValue } from '@app/utils/app-utils.function'
import { AdminFormRowComponent } from '../admin-form-row/admin-form-row.component'
import { DropdownComponent } from '@app/modules/shared/components/dropdown/dropdown.component'
import { AsyncPipe, NgClass, NgIf } from '@angular/common'
import { FilterPercentUom } from '@app/modules/shared/pipes/filter-percent-uom.pipe'
import { UomPresentationPipePipe } from '@app/modules/equipment/pipes/uom-desc-with-short-name-pipe'
import { NumericInputComponent } from '@app/modules/mrma-ui-components/controls/numeric-input/numeric-input.component'
import { OptionsComponent } from '@app/modules/shared/components/options/options.component'

@Component({
    selector: 'app-sequential',
    templateUrl: './sequential.component.html',
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        ReactiveFormsModule,
        AdminFormRowComponent,
        DropdownComponent,
        AsyncPipe,
        FilterPercentUom,
        UomPresentationPipePipe,
        NumericInputComponent,
        OptionsComponent
    ]
})
export class SequentialComponent extends SafeUnsubscriberComponent implements OnInit {
    @Input() isAdmin: boolean

    public uomList$: Observable<UOM[]>
    public processes$: Observable<Process[]>
    public selectiveUOMList = [] as UOM[]
    public sequentialTemplateForm: UntypedFormGroup
    public numberOfPointOptions = TemplateOptions.numberOfPointSequentialOptions
    public calculationTypes: CalculationType[]
    public defaultCalculationType: CalculationType
    public selectedNumberOfPoint: number
    public isCalculationTypeDisabled: boolean
    public yesNoOption = TemplateOptions.yesNoOption
    public isRequiredOption = TemplateOptions.isRequiredOption
    public getOption = findOptionWithKey

    private selectedTemplate: TemplateTypeOption
    private templateDetails: EquipmentTemplateCoreDetails
    private percentageUOM: UOM

    constructor(
        private store: Store<AppState>,
        private formBuilder: UntypedFormBuilder,
        private sequentialValidation: SequentialTemplateValidationsService
    ) {
        super()
    }

    ngOnInit(): void {
        this.uomList$ = this.store.select(uomList)
        this.processes$ = this.store.select(process)
        this.addSubscription(
            this.store.select(selectedTemplate).subscribe(template => this.selectedTemplate = template)
        )
        this.initPercentageUOM()
        this.initTemplateData()
        this.watchMainFormControl()
        this.watchExpectedFormControl()
    }

    private watchMainFormControl(): void {
        this.addSubscription(
            this.sequentialTemplateForm.valueChanges.subscribe(formValue => {
                const point = this.sequentialTemplateForm.get('numberOfPoints.point').value
                if (point) {
                    this.selectedNumberOfPoint = point['key'] as number
                }
                const eqCoreDetails = deepCopy(this.templateDetails) as EquipmentTemplateCoreDetails
                eqCoreDetails.detail.numberOfPoint = this.selectedNumberOfPoint

                this.isCalculationTypeDisabled = !this.sequentialValidation.isCalculationTypeOptionEnabled(formValue)
                if (this.isCalculationTypeDisabled && formValue.calculationType.id !== TemplateOptions.CalculationTypes.linear.id) {
                    this.sequentialTemplateForm.controls.calculationType.setValue(TemplateOptions.CalculationTypes.linear)
                    eqCoreDetails.detail.calculationType = TemplateOptions.CalculationTypes.linear
                }

                this.store.dispatch(new TemplateFormAction.SelectCurrentTemplateFormsAction(this.sequentialTemplateForm.value))
                this.store.dispatch(new EquipmentTemplateAction.EquipmentTemplateWasModifiedAction(
                    eqCoreDetails,
                    this.sequentialValidation.isValid(this.sequentialTemplateForm.value)
                ))
            })
        )
    }

    private watchExpectedFormControl(): void {
        this.addSubscription(
            this.sequentialTemplateForm.controls['expected'].valueChanges.subscribe(expected => {
                this.filterToleranceUOMList(expected)
            })
        )
    }

    private filterToleranceUOMList(
        expected: EquipmentTemplateDetailInput,
        tolerance: EquipmentTemplateDetailInput = null
    ): void {
        if (!expected?.unitOfMeasurement) {
            return
        }

        const newList = this.sequentialValidation.limitUOMType(expected, tolerance, this.percentageUOM)

        if (newList.length === this.selectiveUOMList.length) {
            const newCodes = newList.map(it => it.uomCode)
            const currentCodes = this.selectiveUOMList.map(it => it.uomCode)
            if (newCodes.every(val => !isNotAValue(currentCodes.find(it => it === val)))) {
                return
            }
        }

        this.selectiveUOMList = newList
        this.sequentialTemplateForm.patchValue({
            tolerance: { unitOfMeasurement: this.selectiveUOMList[0] }
        })
    }

    private initPercentageUOM(): void {
        this.addSubscription(
            this.uomList$.subscribe(uoms => {
                this.percentageUOM = uoms.find(uom => uom.uomCode === SpecialUomCode.Percentage)
            })
        )
    }

    private initTemplateData(): void {
        this.calculationTypes = TemplateOptions.CalculationTypes.all

        this.store.select(equipmentTemplateList).pipe(take(1)).subscribe(equipmentTemplate => {
            this.templateDetails = equipmentTemplate.find(template =>
                template.id === this.selectedTemplate.templateUniqueId
            )

            this.defaultCalculationType = this.calculationTypes
                .find(type => type.id === this.templateDetails.detail?.calculationType?.id)
                ?? TemplateOptions.CalculationTypes.linear

            this.selectedNumberOfPoint = this.templateDetails.detail.numberOfPoint
            this.sequentialTemplateForm = this.formBuilder.group({
                process: {
                    id: (this.templateDetails?.processId ?? 1),
                    name: (this.templateDetails?.processName ?? 'Generic')
                },
                alias: (this.templateDetails?.detail?.alias ?? ''),
                numberOfPoints: this.formBuilder.group({
                    point: this.numberOfPointOptions.find(point =>
                        point.key === (this.templateDetails?.detail?.numberOfPoint?.toString() ?? '')
                    )
                }),
                input: this.formBuilder.group({
                    unitOfMeasurement: (this.templateDetails?.detail?.input?.unitOfMeasurement ?? null),
                    minimumRange: (this.templateDetails?.detail?.input?.minimumRange ?? 0),
                    maximumRange: (this.templateDetails?.detail?.input?.maximumRange ?? 0)
                }),
                expected: this.formBuilder.group({
                    unitOfMeasurement: (this.templateDetails?.detail?.expected?.unitOfMeasurement ?? null),
                    minimumRange: (this.templateDetails?.detail?.expected?.minimumRange ?? 0),
                    maximumRange: (this.templateDetails?.detail?.expected?.maximumRange ?? 0)
                }),
                tolerance: this.formBuilder.group({
                    unitOfMeasurement: (this.templateDetails?.detail?.tolerance?.unitOfMeasurement ?? null),
                    value: (this.templateDetails?.detail?.tolerance?.value ?? 0)
                }),
                calculationType: this.defaultCalculationType,
                procedure: (this.templateDetails?.detail?.procedure ?? ''),
                isTestEquipmentRequired: (this.templateDetails?.detail?.isTestEquipmentRequired ?? 'true').toString(),
                isInjectedInputRequired: (this.templateDetails?.detail?.isInjectedInputRequired?.toString() ?? 'true'),
                isChecklistRequired: 'false'
            })
            if (this.sequentialTemplateForm.value.tolerance.unitOfMeasurement !== null) {
                this.filterToleranceUOMList(
                    this.sequentialTemplateForm.value.expected,
                    this.sequentialTemplateForm.value.tolerance
                )
            }
            this.isCalculationTypeDisabled = !this.sequentialValidation.isCalculationTypeOptionEnabled(
                this.sequentialTemplateForm.value
            )
            this.store.dispatch(new TemplateFormAction.SelectCurrentTemplateFormsAction(
                this.sequentialTemplateForm.value
            ))

            if (!this.isAdmin) {
                this.sequentialTemplateForm.disable()
            }
        })
    }
}

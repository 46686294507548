import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action } from '@ngrx/store'
import { Observable } from 'rxjs'
import { exhaustMap, map } from 'rxjs/operators'

import { UOMService } from '@app/modules/equipment/services/uom.service'
import { ResponseHandlerService } from '@app/services/response-handler.service'
import { ActionType, GetUOMListSuccessAction } from '../actions/uom.actions'

@Injectable()
export class UOMEffects {

    
    loadUOM$: Observable<Action> = createEffect(() => this.actions$
        .pipe(
            ofType(ActionType.GetUOMList),
            exhaustMap(() => this.responseHandlerService.query(() => this.uomService.getAll())
                .pipe(
                    map(uom => new GetUOMListSuccessAction(uom))
                )
            )
        ))
    constructor(
        private actions$: Actions,
        private uomService: UOMService,
        private responseHandlerService: ResponseHandlerService
    ) { }
}

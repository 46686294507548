import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

import { Attachment } from '@app/modules/shared/models/attachment.model'
import { ActionType, AttachmentAction } from './attachment.actions'

export interface AttachmentState extends EntityState<Attachment> { }

export const adapter: EntityAdapter<Attachment> = createEntityAdapter<Attachment>({
    selectId: attachment => attachment.attachmentID
})

export const { selectAll } = adapter.getSelectors()

const initialState = adapter.getInitialState()

export function reducer(state = initialState, action: AttachmentAction): AttachmentState {
    switch (action.type) {

        case ActionType.DeleteCalibrationAttachmentSuccess:
        case ActionType.DeleteEquipmentSettingAttachmentSuccess:
            return adapter.removeOne(action.attachmentId, state)

        case ActionType.GetCalibrationAttachmentsSuccess:
        case ActionType.GetEquipmentSettingAttachmentsSuccess:
            adapter.removeAll(state)
            return adapter.setAll(action.payload, state)

        case ActionType.ReplaceCalibrationAttachmentSuccess:
        case ActionType.ReplaceEquipmentSettingAttachmentSuccess:
            const tempState = adapter.removeOne(action.oldAttachmentId, state)
            return adapter.addOne(action.payload, tempState)

        case ActionType.UploadCalibrationAttachmentSuccess:
        case ActionType.UploadEquipmentSettingAttachmentSuccess:
            return adapter.addOne(action.payload, state)

        default:
            return state
    }
}

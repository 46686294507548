import { ProgressBar } from '@app/modules/shared/models/progress-bar.model'
import { SectionLoaderEnum } from '@app/modules/shared/models/section-loader.enum'
import { deepCopy } from '@app/utils/app-utils.function'
import { ActionType, LoaderAction } from './loader.actions'

export interface LoaderState {
    visible: boolean,
    concurrentLoad: number,
    sectionLoaders: SectionLoaderEnum[],
    progressBar: ProgressBar[],
    isHomeComponentInitialized: boolean
}

const initialState: LoaderState = {
    visible: false,
    concurrentLoad: 0,
    sectionLoaders: [],
    progressBar: [],
    isHomeComponentInitialized: false
}

export function reducer(state = initialState, action: LoaderAction): LoaderState {
    switch (action.type) {
        case ActionType.RemoveLoader:
            const reducedConcurrentLoad = state.concurrentLoad === 0 ? 0 : state.concurrentLoad - 1
            return {
                ...state,
                visible: reducedConcurrentLoad === 0 ? false : true,
                concurrentLoad: reducedConcurrentLoad
            }
        case ActionType.SetLoader:
            const increasedConcurrentLoad = state.concurrentLoad + 1
            return {
                ...state,
                visible: true,
                concurrentLoad: increasedConcurrentLoad
            }
        case ActionType.AddSectionLoader:
            const tempSectionLoaders = state.sectionLoaders.filter(sectionLoader => {
                return sectionLoader !== action.sectionName
            })
            return {
                ...state,
                sectionLoaders: [
                    ...tempSectionLoaders,
                    action.sectionName
                ]
            }
        case ActionType.RemoveSectionLoader:
            return {
                ...state,
                sectionLoaders: state.sectionLoaders.filter(sectionLoader => {
                    return sectionLoader !== action.sectionName
                })
            }
        case ActionType.SetProgressBar:
            const stateProgressBar = (deepCopy(state.progressBar) || []) as ProgressBar[]
            const progressBarFound = stateProgressBar
                .find(pb => pb.progressBarVisibleInRoute
                    .some(route => action.visibleInRoute
                        .some(currentRoute => currentRoute === route)))

            if (!progressBarFound) {
                stateProgressBar.push({
                    progressBar: action.percentageComplete,
                    progressBarVisibleInRoute: action.visibleInRoute
                })
            } else {
                progressBarFound.progressBar = action.percentageComplete
            }
            return {
                ...state,
                progressBar: stateProgressBar
            }
        case ActionType.AppHasFinishedInitializing:
            return {
                ...state,
                isHomeComponentInitialized: true
            }
        default:
            return state
    }
}

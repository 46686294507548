import { Directive, ElementRef, OnDestroy } from '@angular/core'

import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { SectionLoaderEnum } from '../../models/section-loader.enum'
import { SectionLoaderService } from '../../services/section-loader.service'

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class SectionLoaderHelperComponent extends SafeUnsubscriberComponent implements OnDestroy {
    protected sectionName: SectionLoaderEnum
    protected sectionLoaderRef: ElementRef

    constructor(private sectionLoaderService: SectionLoaderService) {
        super()
    }

    ngOnDestroy(): void {
        this.sectionLoaderService.unregisterSectionLoader(this.sectionName, this.sectionLoaderRef)
        super.ngOnDestroy()
    }

    public isSectionLoading(): boolean {
        return this.sectionLoaderService.isSectionLoading(this.sectionName, this.sectionLoaderRef)
    }

    protected registerSectionLoader(sectionName: SectionLoaderEnum, sectionLoaderRef: ElementRef): void {
        this.sectionName = sectionName
        this.sectionLoaderRef = sectionLoaderRef
        this.sectionLoaderService.registerSectionLoader(sectionName, sectionLoaderRef)
    }
}

export enum TemplateTypeEnum {
    thirdParty = 'Third Party',
    weightFourPoint = '4 Point',
    weightSixPoint = '6 Point',
    compareTwoPoint = '2 Point Compare',
    compareThreePoint = '3 Point Compare',
    leakCheck = 'Leak Check',
    sequential = 'Sequential',
    temperature = 'Temperature',
    passFailCheck = 'Pass/Fail Check',
    repeatability = 'Repeatability',
    onePoint = 'One Point',
    safetyValve = 'Safety Valve',
    multiPoint = 'Multi Point',
    analyzer = 'Analyzer',
    'Third Party' = 2,
    '4 Point' = 4,
    '6 Point' = 5,
    '2 Point Compare' = 6,
    '3 Point Compare' = 7,
    'Leak Check' = 8,
    'Sequential' = 9,
    'Temperature' = 10,
    'Pass/Fail Check' = 11,
    'Repeatability' = 12,
    'One Point' = 13,
    'Safety Valve' = 14,
    'Multi Point' = 15,
    'Analyzer' = 16,
}

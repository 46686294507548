import { Injectable } from '@angular/core'

import { FeetInchesInch16 } from '@app/modules/shared/models/engineering-units/feet-inches-inch16.model'
import { isNotAValue } from '@app/utils/app-utils.function'

@Injectable({
    providedIn: 'root'
})
export class FeetInch16CalculationService {
    public convertToInch16(value: FeetInchesInch16): number {
        if (value.feet === null && value.inch16 === null && value.inches === null) {
            return null
        }
        return ((((value.feet || 0) * 12) + (value.inches || 0)) * 16) + (value.inch16 || 0)
    }

    public allInch16UnitHaveValue(feetInch16: FeetInchesInch16): boolean {
        if (feetInch16 && (
            feetInch16.feet === null ||
            feetInch16.inch16 === null ||
            feetInch16.inches === null)
        ) {
            return false
        }
        return true
    }

    public isValueSetWithoutInput(value: number, feetInch16: FeetInchesInch16): boolean {
        if (!isNotAValue(value) && feetInch16 && feetInch16.feet === null && feetInch16.inch16 === null && feetInch16.inches === null) {
            return true
        }
        return false
    }

    public convertToFeetInchesInch16WhenValueValid(
        value: number,
        feetInch16: FeetInchesInch16
    ): FeetInchesInch16 {
        if (this.isValueSetWithoutInput(value, feetInch16) || this.allInch16UnitHaveValue(feetInch16)) {
            return this.convertToFeetInchesInch16(value)
        }

        return feetInch16
    }

    public convertToFeetInchesInch16(value: number): FeetInchesInch16 {
        if (isNotAValue(value)) {
            return {
                inch16: null,
                inches: null,
                feet: null
            }
        }

        if (value === 0) {
            return {
                inch16: 0,
                inches: 0,
                feet: 0
            }
        }

        return {
            inch16: Math.round(value % 16),
            inches: Math.floor((value / 16) % 12),
            feet: Math.floor((value / 16) / 12)
        }
    }
}

import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { QueryBuilderService } from '@app/services/query-builder.service'
import { environment } from '@environments/environment'
import { AppSettings } from '@settings/app.settings'
import { CustomForm } from '../models/custom-form.model'

@Injectable({
    providedIn: 'root'
})
export class CustomFormService {
    private readonly url = `${environment.baseUrl}/api/${AppSettings.apiVersion}`

    constructor(private queryBuilderService: QueryBuilderService) { }

    public getCustomFormTemplate(): Observable<CustomForm[]> {
        return this.queryBuilderService
            .get<CustomForm[]>(`${this.url}/CustomCalibrationForm`)
            .pipe(map(response => response.body))
    }
}

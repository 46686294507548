import { HttpErrorResponse } from '@angular/common/http'
import { Store } from '@ngrx/store'

import { createErrorModal, getErrorMessage } from '@app/models/error-message.builder'
import { BackgroundSyncStatusEnum } from '@app/models/offline-status.enum'
import { CalibrationDetails } from '@app/modules/calibration/models/calibration-details.model'
import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'
import { AppState } from '@app/store/app.store'
import { LoadCalibrationSuccessAction } from '@app/store/calibration/calibration.actions'
import { RemoveLoaderAction, SetLoaderAction } from '@app/store/loader/loader.actions'
import { ShowModalAction } from '@app/store/modal/modal.actions'
import { OfflineService } from '../offline.service'

export class ShowModalOnErrorCalibrationStrategy extends ResponseHandlingStrategy {
    constructor(
        next: ResponseHandlingStrategy,
        private store: Store<AppState>,
        private offlineService: OfflineService,
        private calibrationDetail: CalibrationDetails,
        private delayBeforePopup: number
    ) {
        super(next)
    }

    public init(): void {
        if (this.offlineService.isOnline) {
            this.store.dispatch(new SetLoaderAction())
        }
        super.init()
    }

    public finalize(): void {
        this.store.dispatch(new RemoveLoaderAction())
        super.finalize()
    }

    public catchError(error: HttpErrorResponse): any {
        setTimeout(() => {
            const syncStatus = this.offlineService.getCalibrationBackgroundSyncStatus(
                this.calibrationDetail.workOrderNumber,
                this.calibrationDetail.equipmentId
            )
            if (syncStatus === BackgroundSyncStatusEnum.QUEUED) {
                this.store.dispatch(new LoadCalibrationSuccessAction(this.calibrationDetail))
            } else {
                this.displayErrorMessage(error)
            }
        }, this.delayBeforePopup)

        return super.catchError(error)
    }

    private displayErrorMessage(error: HttpErrorResponse): void {
        const errorMessage = getErrorMessage(error)
        const modal = createErrorModal(errorMessage)
        modal.closeCallback = () => { window.location.reload() }
        this.store.dispatch(new ShowModalAction(modal))
    }
}

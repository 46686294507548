import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Action, Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { forkJoin, Observable } from 'rxjs'
import { switchMap } from 'rxjs/operators'

import { EquipmentService } from '@app/modules/equipment/services/equipment.service'
import { SapEquipmentService } from '@app/modules/equipment/services/sap-equipment.service'
import { SectionLoaderEnum } from '@app/modules/shared/models/section-loader.enum'
import { ResponseHandlerService } from '@app/services/response-handler.service'
import { ResponseHandlingStrategy } from '@app/services/response-handling-strategies/response-handling-strategy'
import { ResponseHandlingStrategyBuilder } from '@app/services/response-handling-strategy.builder'
import { AppState } from '@app/store/app.store'
import { GetEquipmentListSuccessAction } from '../actions/equipment-list.actions'
import { ActionType, GetSapEquipmentListAction, GetSapEquipmentListSuccessAction } from '../actions/sap-equipment.actions'

@Injectable()
export class SapEquipmentEffects {
    customStrategy: ResponseHandlingStrategy

    
    getSAPEquipment: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<GetSapEquipmentListAction>(ActionType.GetSAPEquipmentList),
        switchMap((action) => {
            const equipment$ = this.equipmentService.getEquipments(null, null, false)
            const sapEquipemnt$ = this.sapEquipmentService.getSAPEquipments(
                action.payload.equipmentTag,
                action.payload.planningPlant,
                action.payload.usrStatus,
                action.payload.isTestEquipment)

            return this.responseHandlerService.query(() =>
                forkJoin([equipment$, sapEquipemnt$]), this.customStrategy)
                .pipe(
                    switchMap(response => {
                        const startingNumber =
                            Number(action.payload.perPage) * (Number(action.payload.page) - 1)
                        return [
                            new GetEquipmentListSuccessAction({
                                querySummary: JSON.parse(response[0].headers.get('Query-Summary')),
                                result: response[0].body,
                                replace: false
                            }),
                            new GetSapEquipmentListSuccessAction({
                                querySummary: {
                                    queryParameters: {
                                        equipmentTag: action.payload.equipmentTag,
                                        planningPlant: action.payload.planningPlant,
                                        usrStatus: action.payload.usrStatus,
                                        isTestEquipment: action.payload.isTestEquipment,
                                        sortBy: action.payload.sortBy,
                                        page: action.payload.page.toString(),
                                        perPage: action.payload.perPage.toString()
                                    },
                                    currentPage: Number(action.payload.page),
                                    itemPerPage: String(action.payload.perPage),
                                    totalItemCount: response[1].body.length
                                },
                                result: response[1].body.slice(
                                    startingNumber,
                                    startingNumber + Number(action.payload.perPage))
                            })]
                    })
                )
        })
    ))
    constructor(
        private actions$: Actions,
        private sapEquipmentService: SapEquipmentService,
        private equipmentService: EquipmentService,
        private store: Store<AppState>,
        private responseHandlerService: ResponseHandlerService,
        private toastr: ToastrService
    ) {
        this.customStrategy = new ResponseHandlingStrategyBuilder()
            .useRethrowError()
            .useShowToastrOnError(this.toastr)
            .useShowSectionLoader(this.store, SectionLoaderEnum.ModalBody)
            .responseStrategy
    }
}

import { HttpResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { CalibrationResultStatusEnum } from '@app/modules/calibration/models/calibration-result-status.enum'
import { CalibrationStatusEnum } from '@app/modules/calibration/models/calibration-status.enum'
import { NotificationBasic } from '@app/modules/work-order/models/notification-basic.model'
import { QueryBuilderService } from '@app/services/query-builder.service'
import { WorkOrderCache } from '@app/store/offline/offline.reducer'
import { environment } from '@environments/environment'
import { AppSettings } from '@settings/app.settings'
import { EquipmentCalibration } from '../models/equipment-calibration.model'
import { WorkOrderDetails } from '../models/work-order-details.model'
import { isEmpty } from 'lodash'

@Injectable({
    providedIn: 'root'
})
export class WorkOrderService {
    private readonly url = `${environment.baseUrl}/api/${AppSettings.apiVersion}/WorkOrder`
    private readonly urlForNotification = `${environment.baseUrl}/api/${AppSettings.apiVersion}`

    constructor(private queryBuilderService: QueryBuilderService) { }

    public getWorkOrderDetails(id: string, fetchNotification = false): Observable<WorkOrderDetails> {
        return this.queryBuilderService
            .get<WorkOrderDetails>(`${this.url}Details(${id})?fetchNotification=${fetchNotification}`)
            .pipe(map(response => response.body))
    }

    public getWorkOrderFilterListBy(query: string): Observable<HttpResponse<string[]>> {
        return this.queryBuilderService
            .get<string[]>(`${this.url}/${query}`)
            .pipe(map(response => response))
    }

    public handleWorkOrderDetailsWithCache(item: WorkOrderDetails, workOrderCaches: WorkOrderCache[]): WorkOrderDetails {
        item.equipmentCalibrations = item.equipmentCalibrations.map(equipmentCalibration => {
            const woCache = workOrderCaches.find(cache => {
                return cache.workOrderNumber === item.workOrderNumber
                    && cache.equipments.some(equipmentCache => {
                        return equipmentCache.equipmentId === equipmentCalibration.equipmentId
                    })
            })

            if (woCache) {
                const cachedEquipment = woCache.equipments.find(equipment => {
                    return equipment.equipmentId === equipmentCalibration.equipmentId
                })
                const cachedCalibrationStatus = cachedEquipment.calibrationStatusId

                if (cachedCalibrationStatus === CalibrationStatusEnum.Draft) {
                    equipmentCalibration.status = 'draft'
                } else if (cachedCalibrationStatus === CalibrationStatusEnum.Completed) {
                    equipmentCalibration.status = 'completed'
                }

                equipmentCalibration.actionRequired = cachedEquipment.actionRequired
                equipmentCalibration.result = cachedEquipment.finalPMResultStatusId
                    ? CalibrationResultStatusEnum[cachedEquipment.finalPMResultStatusId]
                    : equipmentCalibration.result
            }

            return equipmentCalibration
        })

        return item
    }

    public calculateWorkOrderStatusPill(workOrderSystemStatus: string): string {
        if (!workOrderSystemStatus) {
            return ''
        }

        if (workOrderSystemStatus.includes('CLSD') || workOrderSystemStatus.includes('TECO')) {
            return 'closed'
        }
        return 'open'
    }

    public getNotificationInfo(workOrderNumber: string, notificationNumber: string): Observable<NotificationBasic> {
        if (isEmpty(notificationNumber)) {
            throw new Error('Notification number is empty')
        }
        return this.queryBuilderService
            .get<NotificationBasic>(`${this.urlForNotification}/WorkOrder(${workOrderNumber})/Notifications(${notificationNumber})`)
            .pipe(map(response => response.body))
    }

    public getEquipmentNotification(notificationNumber: string, equipmentCalibrations: EquipmentCalibration[]): string {
        const isMultipleEquipment = equipmentCalibrations.length > 1
        const isSingleEquipment = equipmentCalibrations.length === 1
        const notificationsList = equipmentCalibrations.filter(eq => eq.equipmentNotificationNumber)

        if ((isMultipleEquipment && notificationsList.length > 1) || isSingleEquipment) {
            return notificationNumber
        } else {
            return null
        }
    }

    public getPrimaryNotification(notificationNumber: string, equipmentCalibrations: EquipmentCalibration[]): string {
        const isMultipleEquipment = equipmentCalibrations.length > 1
        const notificationsList = equipmentCalibrations.filter(eq => eq.equipmentNotificationNumber)

        if ((isMultipleEquipment && notificationsList.length === 1) || (isMultipleEquipment && notificationsList.length === 0)) {
            return notificationNumber
        } else {
            return null
        }
    }
}

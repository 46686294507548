import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'

import { BackgroundSyncStatusEnum } from '@app/models/offline-status.enum'
import { AppState } from '@app/store/app.store'
import { GetCalibrationAction, RemoveCachedCalibrationAction } from '@app/store/calibration/calibration.actions'
import { UpdatePMUploadQueueAction } from '@app/store/offline/offline.actions'
import { GetNotificationAction, LoadWorkOrderDetailsAction } from '@app/store/work-order/work-order.actions'
import { OfflineService } from './offline.service'

@Injectable({
    providedIn: 'root'
})
export class BackgroundQueueHandlerService {
    private statusUpdateQueue = []

    constructor(
        private store: Store<AppState>,
        private offlineService: OfflineService
    ) { }

    public handleBackgroundSyncStatus(event: any): void {
        this.statusUpdateQueue.push(event)
        if (this.offlineService.offlineState.ids.length > 0) {
            this.executeQueue()
        }
    }

    private executeQueue(): void {
        for (let event = this.statusUpdateQueue.shift(); event; event = this.statusUpdateQueue.shift()) {
            const cacheType = event.data.payload.type
            if (cacheType !== 'background-queue') {
                return
            }

            const {
                workOrderNumber,
                notificationNumber,
                equipmentId,
                cacheStatus,
                calibrationStatus,
                finalPMResultStatus
            } = event.data.payload
            const updates = this.offlineService.generateCalibrationSyncStatusUpdateInstance(
                workOrderNumber,
                equipmentId,
                cacheStatus,
                calibrationStatus,
                finalPMResultStatus
            )

            if (updates.length === 0) {
                continue
            }

            const currentPMQueue = this.offlineService.getPMUploadQueue().find(pm =>
                pm.workOrderNumber === workOrderNumber &&
                pm.equipmentId === equipmentId
            )

            if (!currentPMQueue && cacheStatus !== BackgroundSyncStatusEnum.NOT_QUEUED) {
                continue
            }

            const isSynced = BackgroundSyncStatusEnum[cacheStatus] === BackgroundSyncStatusEnum.SYNCED

            if (isSynced) {
                this.store.dispatch(LoadWorkOrderDetailsAction({ id: workOrderNumber, fetchNotification: false }))
                this.store.dispatch(GetNotificationAction({ workOrderNumber, notificationNumber }))
                this.store.dispatch(new GetCalibrationAction(workOrderNumber, equipmentId))
                this.store.dispatch(new RemoveCachedCalibrationAction(workOrderNumber, equipmentId))
            }

            this.store.dispatch(new UpdatePMUploadQueueAction({
                workOrderNumber,
                equipmentId,
                notificationNumber,
                status: BackgroundSyncStatusEnum[cacheStatus]
            }))
        }
    }
}

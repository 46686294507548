import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js'
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web'
import { Store } from '@ngrx/store'

import { loggableStoreState } from '@app/store/app.selectors'
import { AppState } from '@app/store/app.store'
import { environment } from '@environments/environment'
import { IdentityService } from './identity.service'

@Injectable({
    providedIn: 'root'
})
export class AppMonitoringService {

    private appInsights?: ApplicationInsights

    private loggableStoreSnapshot = {}

    constructor(private identityService: IdentityService, private store: Store<AppState>, private router: Router) { }

    public get sessionId(): string {
        return this.appInsights?.context['sessionManager']?.automaticSession?.id
    }
    public get instance(): ApplicationInsights | null | undefined {
        return this.appInsights
    }

    public initialize(): void {
        // We are not doing this in the constructor() or onInit() because
        // those will only run on the first time this service is injected.
        // Keeping this logic here ensure that the user inject the service
        // at least once and initalize it early on in the app's runtime.
        if (environment['appInsights']) {
            const angularPlugin = new AngularPlugin()
            this.appInsights = new ApplicationInsights({
                config: {
                    ...environment['appInsights'].config,
                    extensions: [angularPlugin],
                    extensionConfig: {
                        [angularPlugin.identifier]: { router: this.router }
                    }
                }
            })
            this.appInsights.loadAppInsights()
            this.appInsights.trackPageView()
            this.appInsights.addTelemetryInitializer(this.appMetricTelemetryInitilizer)
            this.appInsights.addTelemetryInitializer(this.basicUserInfoTelemetryInitilizer)
            this.appInsights.addTelemetryInitializer(this.includeReduxStoreSnapshotTelemetryInitilizer)
            if (this.appInsights.context?.application) {
                this.appInsights.context.application.ver = environment.version
            }
            this.store.select(loggableStoreState)
                .subscribe(loggableStoreSnapshot => { this.loggableStoreSnapshot = loggableStoreSnapshot })

        } else {
            console.warn('AppInsight failed to initialize')
        }
    }

    public logErrorMessageToConsole(error: any, source: string = 'UNKNOWN'): void {
        if (environment.development) {
            console.error(`[${source}]: \n`, error)
        } else {
            console.error(
                `Please send this ID: '${this.sessionId}' to your site admin or supervisor for further investigation.\n\n`,
                `${(new Date())}: ${error?.message}.`,
            )
        }
    }

    // Note: These TelemetryInitilizers NEEDS to be arrow functions
    // or else `this` will not refer to this service.
    private appMetricTelemetryInitilizer = (item: ITelemetryItem): boolean | void => {
        const baseData = item.baseData
        if (baseData) {
            const appVersion = environment.version
            const apiVersion = environment.apiVersion
            const baseProperties = baseData.properties ?? {}
            baseData.properties = {
                ...baseProperties,
                appVersion,
                apiVersion,
                screenResolution: screen.width + 'x' + screen.height
            }
        }
    }

    private basicUserInfoTelemetryInitilizer = (item: ITelemetryItem): boolean | void => {
        const currentUser = this.identityService?.currentIdentity$?.value
        if (!currentUser) {
            return
        }
        const baseData = item.baseData
        if (baseData) {
            const baseProperties = baseData.properties ?? {}
            baseData.properties = {
                ...baseProperties,
                preferredPlantCode: currentUser.preferredPlantCode,
                userGUID: currentUser.guid,
                userId: currentUser['id'] ?? ''
            }
        }
    }

    private includeReduxStoreSnapshotTelemetryInitilizer = (item: ITelemetryItem): boolean | void => {
        if (item.baseType === 'ExceptionData') {
            const baseData = item.baseData
            if (baseData) {
                const baseProperties = baseData.properties ?? {}
                baseData.properties = {
                    ...baseProperties,
                    // Place upper char limit so at least the telemetry does not fail to send
                    partialStoreSnapshot: JSON.stringify(this.loggableStoreSnapshot).substring(0, 5000)
                }
            }
        }
    }
}

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'

import { User, UserQueryParameter } from '@app/models/user.model'
import { QuerySummary } from '@app/modules/shared/models/query-summary.model'
import { ActionType, UserAction } from './user.actions'

export interface UserState extends EntityState<User> {
    querySummary: QuerySummary<UserQueryParameter>
    selectedUser: User
    userListFetchingInProgress: boolean,
    userListLastSyncDate: string
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>({
    selectId: user => user.guid
})

const initialState: UserState = adapter.getInitialState({
    querySummary: {
        queryParameters: {
            keyword: undefined,
            roleId: '2,3,4,5,6,8',
            includeExpired: true,
            includeInactive: true,
            sortBy: 'displayName',
            page: '1',
            perPage: '10'
        },
        sortBy: 'displayName',
        currentPage: 1,
        itemPerPage: '10',
        totalItemCount: 0
    } as QuerySummary<UserQueryParameter>,
    selectedUser: undefined,
    userListFetchingInProgress: false,
    userListLastSyncDate: 'Syncing in progress'
})

export function reducer(state = initialState, action: UserAction): UserState {
    switch (action.type) {
        case ActionType.FilterUser:
            return {
                ...state,
                querySummary: action.payload
            }
        case ActionType.GetAllUser:
            return {
                ...state,
                userListFetchingInProgress: true
            }
        case ActionType.GetAllUserSuccess:
            adapter.removeAll(state)
            return {
                ...adapter.setAll(action.payload, state),
                userListFetchingInProgress: false,
                userListLastSyncDate: action.lastSyncDate
            }
        case ActionType.SelectUser:
            return {
                ...state,
                selectedUser: action.payload
            }
        case ActionType.GetUserListFetchingFetchingProcess:
            return {
                ...state,
                userListFetchingInProgress: true
            }
        case ActionType.UpdateUserCertificationSuccess:
            return {
                ...adapter.updateOne({
                    id: action.payload.guid,
                    changes: action.payload
                }, state),
                selectedUser: undefined
            }
        default:
            return state
    }
}


export const {
    selectAll
} = adapter.getSelectors()

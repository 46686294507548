import { HttpErrorResponse } from '@angular/common/http'
import { ToastrService } from 'ngx-toastr'

import { mrmaAlertConfigs } from '@app/models/alert-configuration.model'
import { composeTechnicalHTMLResponse, getErrorMessage } from '@app/models/error-message.builder'
import { ResponseHandlingStrategy } from './response-handling-strategy'
import { get, includes, upperCase } from 'lodash'

export class ShowToastrOnErrorStrategy extends ResponseHandlingStrategy {
    constructor(
        next: ResponseHandlingStrategy,
        private toastr: ToastrService,
        private delay = 0
    ) {
        super(next)
    }

    public catchError(error: HttpErrorResponse): any {
        this.displayErrorMessage(error)
        return super.catchError(error)
    }

    public displayErrorMessage(error: HttpErrorResponse): void {
        const errorDetail = get(error, 'error.detail')
        const errorMessage = getErrorMessage(error)
        const config = mrmaAlertConfigs.NetworkError
        const isSapError = includes(upperCase(errorDetail), 'SAP')

        if (error.status !== 0) {
            setTimeout(() => {
                this.toastr.error(isSapError ? `
                    <section class="em-c-section em-c-section--expandable em-is-closed toast-expand-section-error">
                    <p class="sap-error-message">${errorMessage}</p>
                        <header class="em-c-section__header em-js-section-trigger toast-expand-header-error">
                            <svg class="em-c-icon em-c-icon--medium em-c-section__icon">
                                <use xmlns:xlink="http://www.w3.org/1999/xlink"
                                 xlink:href="./assets/unity-1.2.0/images/24/em-icons.svg#caret-down"></use>
                            </svg>
                            <h2 class="em-c-section__title">
                                Click to see detailed information
                            </h2>
                        </header>
                        <div class="em-c-section__body em-js-section-target">
                            <p>${errorDetail}</p>
                        </div>
                    </section>` : composeTechnicalHTMLResponse(errorMessage),
                    config.message,
                    config.configuration)
                if (isSapError) {
                    setTimeout(() => this.addToastExpandClickEvent(), 0)
                }
            }, this.delay)
        }
    }

    private addToastExpandClickEvent(): void {
        const sectionList = document.querySelectorAll('.toast-error .em-c-section--expandable')
        const section = sectionList[sectionList.length - 1]
        const triggerElement = section.children[1]
        triggerElement.addEventListener('click', () => {
            if (section.className.indexOf('em-is-closed') === -1) {
                section.classList.add('em-is-closed')
            } else {
                section.classList.remove('em-is-closed')
            }
        })
        const icon = document.createElement('span')
        icon.innerHTML = `
        <svg class="em-c-icon em-c-icon--medium em-c-section__icon">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="./assets/unity-1.2.0/images/24/em-icons.svg#caret-down"></use>
        </svg>`
        triggerElement.prepend(icon)
    }
}

import { SlideUpOverlay } from '@app/modules/shared/models/slide-up-overlay.modal'

export enum ActionType {
    ToggleOverlay = '[Overlay] Toggle',
    SlideOverlay = '[Overlay] Slide'
}

export class SlideOverlayAction {
    readonly type = ActionType.SlideOverlay
    constructor(public payload: SlideUpOverlay) { }
}

export type ToggleOverlay = SlideOverlayAction

<div [ngClass]="{'show-expected': isExpectedReadingEdited}"
     [formGroup]="innerFormGroup"
     [ngStyle]="rowStyle">

    <div class="setpoint-row">
        <div class="row-header setpoint-name">
            <span *ngIf="rowIndex"
                  class="index">{{rowIndex}}</span>
            SET POINT NAME
        </div>

        <div class="setpoint-name-container">
            <textarea class="setpoint-name-input"
                      placeholder="Enter Set Point Name"
                      formControlName="name"
                      [formControl]="innerFormGroup.get('name')"
                      [appCopyToInput]="defaultSetPointName"
                      maxlength="500"></textarea>
        </div>


        <div class="row-header setpoint-value">
            SETPOINT
        </div>

        <app-numeric-input class="setpoint-input"
                           [formControl]="innerFormGroup.get('setPoint.value')"
                           [decimalPlaces]="5"
                           placeholder="0.000">
        </app-numeric-input>

        <div class="uom-input setpoint-uom-input">

            <div *ngIf="isSharedSetPoint !== 'true' || rowIndex === 1">
                <app-dropdown [form]="innerFormGroup.controls.setPoint"
                              [formControlPath]="'unitOfMeasurement'"
                              [options]="(uomList$ | async | uomPresentationPipe | filterPercentUom)"
                              [key]="'uomCode'"
                              [value]="'uomDisplayValue'"
                              [displayValue]="'uomCodeForTech'"
                              [injectedStyles]="{'margin-bottom':'0px', 'background-color':'#FFFFFF'}">
                </app-dropdown>
            </div>

            <div class="shared-uom"
                 *ngIf="isSharedSetPoint === 'true' && rowIndex !== 1">
                {{innerFormGroup.get('setPoint.unitOfMeasurement').value?.uomCodeForTech}}
            </div>

        </div>

        <div class="row-header expected-reading"
             *ngIf="isExpectedReadingEdited">
            EXPECTED READING
        </div>

        <app-numeric-input class="expected-reading-input"
                           [formControl]="innerFormGroup.get('expectedReading.value')"
                           *ngIf="isExpectedReadingEdited"
                           [decimalPlaces]="5"
                           placeholder="0.000">
        </app-numeric-input>

        <div class=" uom-input expected-reading-uom-input"
             *ngIf="isExpectedReadingEdited">

            <div *ngIf="isSharedSetPoint !== 'true' || rowIndex === 1">
                <app-dropdown [form]="innerFormGroup.controls.expectedReading"
                              [formControlPath]="'unitOfMeasurement'"
                              *ngIf="isExpectedReadingEdited"
                              [options]="(uomList$ | async | uomPresentationPipe)"
                              [key]="'uomCode'"
                              [value]="'uomDisplayValue'"
                              [displayValue]="'uomCodeForTech'"
                              [injectedStyles]="{'margin-bottom':'0px', 'background-color':'#FFFFFF'}">
                </app-dropdown>
            </div>

            <div class="shared-uom"
                 *ngIf="isSharedSetPoint === 'true' && rowIndex !== 1">
                {{innerFormGroup.get('expectedReading.unitOfMeasurement').value?.uomCodeForTech}}
            </div>

        </div>

        <div class="row-header tolerance">
            TOLERANCE
        </div>

        <div class="tolerance-input">
            <div>
                <em>-</em>
                <app-numeric-input [ngModel]="innerFormGroup.get('tolerance.lowerRange').value | absolute"
                                   [ngModelOptions]="{standalone: true}"
                                   [decimalPlaces]="5"
                                   placeholder="0.00"
                                   (ngModelChange)="updateLowerRange($event)"
                                   [allowNegative]="false"
                                   [disabled]="disabled">
                </app-numeric-input>
            </div>
            <span>
                to
            </span>
            <div>
                <em>+</em>
                <app-numeric-input [ngModel]="innerFormGroup.get('tolerance.higherRange').value | absolute"
                                   [ngModelOptions]="{standalone: true}"
                                   [decimalPlaces]="5"
                                   placeholder="0.00"
                                   (ngModelChange)="updateHigherRange($event)"
                                   [allowNegative]="false"
                                   [disabled]="disabled">
                </app-numeric-input>
            </div>
        </div>

        <div class=" uom-input tolerance-uom-input">

            <div *ngIf="isSharedSetPoint !== 'true' || rowIndex === 1">
                <app-dropdown [form]="innerFormGroup.controls.tolerance"
                              [formControlPath]="'unitOfMeasurement'"
                              [options]="toleranceUomList | uomPresentationPipe"
                              [key]="'uomCode'"
                              [value]="'uomDisplayValue'"
                              [displayValue]="'uomCodeForTech'"
                              [injectedStyles]="{'margin-bottom':'0px', 'background-color':'#FFFFFF'}">
                </app-dropdown>
            </div>

            <div class="shared-uom"
                 *ngIf="isSharedSetPoint === 'true' && rowIndex !== 1">
                {{innerFormGroup.get('tolerance.unitOfMeasurement').value?.uomCodeForTech}}
            </div>

        </div>

        <div class="row-header expected">
            EXPECTED
        </div>

        <div class="expected-display">
            <div [ngClass]=" expectedRange() ? 'expected-value' :'expected-value--null' ">
                {{ expectedRange()?.minimumRange || expectedRange()?.minimumRange === 0 ? expectedRange()?.minimumRange : '-' }}
            </div>
            <span class="to">
                to
            </span>
            <div [ngClass]=" expectedRange() ? 'expected-value' :'expected-value--null' ">
                {{ expectedRange()?.maximumRange || expectedRange()?.maximumRange === 0 ? expectedRange()?.maximumRange : '-' }}
            </div>
        </div>

        <div class="expected-uom">
            {{innerFormGroup.get('expectedReading.unitOfMeasurement').value?.uomCodeForTech}}
        </div>

    </div>
</div>

import { Action } from '@ngrx/store'
import { EquipmentTemplateCoreDetails } from '@app/modules/equipment/models/equipment-template-core-details.model'

export enum ActionType {
    SaveTemplateFormsDetails = '[TemplateForm] Save Template Details',
    SelectCurrentTemplateFormsDetail = '[TemplateForm] Select Current Template Details'
}

export class SaveTemplateDetailAction implements Action {
    public readonly type = ActionType.SaveTemplateFormsDetails
    constructor(public payload: EquipmentTemplateCoreDetails) { }
}

export class SelectCurrentTemplateFormsAction implements Action {
    public readonly type = ActionType.SelectCurrentTemplateFormsDetail
    constructor(public payload: object) { }
}

export type TemplateFormAction = SelectCurrentTemplateFormsAction
    | SaveTemplateDetailAction

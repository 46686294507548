import { ActionReducer, MetaReducer } from '@ngrx/store'
import { localStorageSync } from 'ngrx-store-localstorage'

import { AppState } from '@app/store/app.store'

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: [
            'calibration',
            'toDo',
            'offline',
            'tooltip'
        ],
        rehydrate: true
    })(reducer)
}

export const metaReducers: MetaReducer<AppState>[] = [localStorageSyncReducer]

import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'absolute',
    standalone: true
})
export class MathAbsolutePipe implements PipeTransform {
    transform(value: any): any {
        if (typeof (value) === 'number') {
            return Math.abs(value)
        }
        return value
    }
}

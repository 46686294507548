<section class="admin-form-section">
    <app-admin-form-row [formGroup]="form"
                        label="PROCESS *">
        <div class="em-l-grid__item admin-input-width">
            <app-dropdown [form]="form"
                          [formControlPath]="'process'"
                          [options]="(processes$ | async)"
                          [key]="'id'"
                          [value]="'name'">
            </app-dropdown>
        </div>
    </app-admin-form-row>
    <app-admin-form-row [formGroup]="form"
                        label="TEMPLATE ALIAS">
        <div class="em-l-grid__item admin-input-width">
            <input formControlName="alias"
                   type="text"
                   class="em-c-input em-u-font-style-regular"
                   maxlength="24">
        </div>
    </app-admin-form-row>
</section>

<section class="admin-form-section">
    <app-admin-form-row [formGroup]="form.get('setPoint')"
                        label="SET POINT *">
        <div class="em-l-grid__item admin-input-width">
            <app-dropdown [form]="form.get('setPoint')"
                          [formControlPath]="'unitOfMeasurement'"
                          [options]="(uomList$ | async | filterPercentUom)"
                          [key]="'uomCode'"
                          [value]="mapUomObjToUomDisplay">
            </app-dropdown>
        </div>
        <div [formGroup]="form.get('setPoint')"
             class="em-l-grid__item admin-input-width em-c-field">
            <div class="em-l-grid em-c-field tolerance-input">
                <div class="em-l-grid__item em-u-padding-top-none">
                    <app-numeric-input formControlName="value"
                                       [clearZeroOnFocus]="true">
                    </app-numeric-input>
                </div>
            </div>
        </div>
    </app-admin-form-row>

    <app-admin-form-row [formGroup]="form.get('expectedReading')"
                        label="EXPECTED READING *">
        <div class="em-l-grid__item admin-input-width">
            <app-dropdown [form]="form.get('expectedReading')"
                          [formControlPath]="'unitOfMeasurement'"
                          [options]="(uomList$ | async | filterPercentUom)"
                          [key]="'uomCode'"
                          [value]="mapUomObjToUomDisplay">
            </app-dropdown>
        </div>
        <div [formGroup]="form.get('expectedReading')"
             class="em-l-grid__item admin-input-width">
            <div class="em-l-grid em-c-field tolerance-input">
                <div class="em-l-grid__item em-u-padding-top-none">
                    <app-numeric-input formControlName="value"
                                       [clearZeroOnFocus]="true">
                    </app-numeric-input>
                </div>
            </div>
        </div>
    </app-admin-form-row>

    <app-admin-form-row [formGroup]="form.get('tolerance')"
                        label="TOLERANCE *">
        <div class="em-l-grid__item admin-input-width">
            <app-dropdown [form]="form.get('tolerance')"
                          [formControlPath]="'unitOfMeasurement'"
                          [options]="toleranceUoMs | uomPresentationPipe"
                          [key]="'uomCode'"
                          [value]="mapUomObjToUomDisplay">
            </app-dropdown>
        </div>
        <div class="em-l-grid__item admin-input-width">
            <div class="em-l-grid em-c-field tolerance-input">
                <div class="em-l-grid__item em-u-padding-top-none">
                    <em>-</em>
                    <app-numeric-input [ngModel]="form.get('tolerance.lowerRange').value | absolute"
                                       [ngModelOptions]="{standalone: true}"
                                       [allowNegative]="false"
                                       [disabled]="form.disabled"
                                       (emitWhenInputLosingFocus)="updateLowerRange($event)"
                                       [clearZeroOnFocus]="true">
                    </app-numeric-input>
                </div>
                <span>To</span>
                <div class="em-l-grid__item em-u-padding-top-none">
                    <em>+</em>
                    <app-numeric-input [ngModel]="form.get('tolerance.higherRange').value | absolute"
                                       [ngModelOptions]="{standalone: true}"
                                       [disabled]="form.disabled"
                                       [allowNegative]="false"
                                       (emitWhenInputLosingFocus)="updateHigherRange($event)">
                    </app-numeric-input>
                </div>
            </div>
        </div>
    </app-admin-form-row>

    <app-admin-form-row [formGroup]="form.get('expectedReading')">
        <div class="em-l-grid__item expected-reading-range">
            <span>Expected: </span>
            <strong>{{ expectedReadingRange }}</strong>
        </div>
    </app-admin-form-row>
</section>

<section class="admin-form-section">
    <app-admin-form-row [formGroup]="form.get('testingDirection')"
                        label="TESTING DIRECTION"
                        description="(Increasing/Decreasing)">
        <div class="em-l-grid__item admin-input-width">
            <div [formGroup]="form"
                 [ngClass]="{'em-is-disabled': form.disabled}"
                 class="em-c-field">
                <select formControlName="testingDirection"
                        class="em-c-select em-u-width-100">
                    <option *ngFor="let option of testingDirectionOptions"
                            [ngValue]="option.key">
                        {{ option.value }}
                    </option>
                </select>
            </div>

        </div>
    </app-admin-form-row>
</section>

<section class="admin-form-section">
    <app-admin-form-row [formGroup]="form"
                        label="PROCEDURE NUMBER">
        <div class="em-l-grid__item admin-input-width em-c-field">
            <input formControlName="procedure"
                   type="text"
                   placeholder="B4P-RXG-XXX.doc"
                   class="em-c-input em-u-font-style-regular">
        </div>
    </app-admin-form-row>

    <app-admin-form-row [formGroup]="form"
                        label="TEST EQUIPMENT"
                        description="(Choose 'Required' if you want technician to select test equipment before completing the work)">
        <div class="em-l-grid__item admin-input-width">
            <app-options [formGroup]="form"
                         [disabled]="form.disabled"
                         [formControlPath]="'isTestEquipmentRequired'"
                         [defaultOption]="getOption(form.get('isTestEquipmentRequired').value, isRequiredOption)"
                         [labelStyle]="'em-u-width-50'"
                         [options]="isRequiredOption"
                         [key]="'key'"
                         [value]="'value'">
            </app-options>
        </div>
    </app-admin-form-row>
</section>

import { Action } from '@ngrx/store'

import { Attachment } from '@app/modules/shared/models/attachment.model'

export enum ActionType {
    // calibraion attachment
    DeleteCalibrationAttachment = '[Calibration Attachment] Delete attachment',
    DeleteCalibrationAttachmentSuccess = '[Calibration Attachment] Delete attachment success',
    GetCalibrationAttachments = '[Calibration Attachment] Get attachments',
    GetCalibrationAttachmentsSuccess = '[Calibration Attachment] Get attachment success',
    ReplaceCalibrationAttachment = '[Calibration Attachment] Replace attachment',
    ReplaceCalibrationAttachmentSuccess = '[Calibration Attachment] Replace attachment success',
    UploadCalibrationAttachment = '[Calibration Attachment] Upload attachment',
    UploadCalibrationAttachmentSuccess = '[Calibration Attachment] Upload attachment success',

    // equipment setting attachment
    DeleteEquipmentSettingAttachment = '[Equipment Setting Attachment] Delete attachment',
    DeleteEquipmentSettingAttachmentSuccess = '[Equipment Setting Attachment] Delete attachment success',
    GetEquipmentSettingAttachments = '[Equipment Setting Attachment] Get attachment',
    GetEquipmentSettingAttachmentsSuccess = '[Equipment Setting Attachment] Get attachment success',
    ReplaceEquipmentSettingAttachment = '[Equipment Setting Attachment] Replace attachment',
    ReplaceEquipmentSettingAttachmentSuccess = '[Equipment Setting Attachment] Replace attachment success',
    UploadEquipmentSettingAttachment = '[Equipment Setting Attachment] Upload attachment',
    UploadEquipmentSettingAttachmentSuccess = '[Equipment Setting Attachment] Upload success'
}

export class DeleteCalibrationAttachmentAction implements Action {
    readonly type = ActionType.DeleteCalibrationAttachment

    constructor(
        public workOrderNumber: string,
        public equipmentId: string,
        public calibrationId: string,
        public attachmentId: string
    ) { }
}

export class DeleteCalibrationAttachmentSuccessAction implements Action {
    readonly type = ActionType.DeleteCalibrationAttachmentSuccess

    constructor(
        public workOrderNumber: string,
        public equipmentId: string,
        public calibrationId: string,
        public attachmentId: string
    ) { }
}

export class GetCalibrationAttachmentsAction implements Action {
    readonly type = ActionType.GetCalibrationAttachments

    constructor(
        public calibrationId: string,
        public calibrationStatusId = -1,
        public templateTypeId = -1
    ) { }
}

export class GetCalibrationAttachmentsSuccessAction implements Action {
    readonly type = ActionType.GetCalibrationAttachmentsSuccess

    constructor(public payload: Attachment[]) { }
}

export class ReplaceCalibrationAttachmentAction implements Action {
    readonly type = ActionType.ReplaceCalibrationAttachment

    constructor(
        public calibrationId: string,
        public attachmentId,
        public file: File
    ) { }
}

export class ReplaceCalibrationAttachmentSuccessAction implements Action {
    readonly type = ActionType.ReplaceCalibrationAttachmentSuccess

    constructor(public oldAttachmentId: string, public payload: Attachment) { }
}

export class UploadCalibrationAttachmentAction implements Action {
    readonly type = ActionType.UploadCalibrationAttachment

    constructor(
        public workOrderNumber: string,
        public equipmentId: string,
        public calibrationId: string,
        public file: File
    ) { }
}

export class UploadCalibrationAttachmentSuccessAction implements Action {
    readonly type = ActionType.UploadCalibrationAttachmentSuccess

    constructor(public payload: Attachment) { }
}

export class GetEquipmentSettingAttachmentsAction implements Action {
    public readonly type = ActionType.GetEquipmentSettingAttachments
    constructor(public equipmentId: string) { }
}

export class GetEquipmentSettingAttachmentsSuccessAction implements Action {
    public readonly type = ActionType.GetEquipmentSettingAttachmentsSuccess
    constructor(public payload: Attachment[]) { }
}

export class UploadEquipmentSettingAttachmentAction implements Action {
    public readonly type = ActionType.UploadEquipmentSettingAttachment
    constructor(
        public equipmentId: string,
        public file: File
    ) { }
}

export class UploadEquipmentSettingAttachmentSuccessAction implements Action {
    public readonly type = ActionType.UploadEquipmentSettingAttachmentSuccess
    constructor(public payload: Attachment) { }
}

export class DeleteEquipmentSettingAttachmentAction implements Action {
    public readonly type = ActionType.DeleteEquipmentSettingAttachment
    constructor(
        public equipmentId: string,
        public attachmentId: string
    ) { }
}

export class DeleteEquipmentSettingAttachmentSuccessAction implements Action {
    public readonly type = ActionType.DeleteEquipmentSettingAttachmentSuccess
    constructor(
        public equipmentId: string,
        public attachmentId: string
    ) { }
}

export class ReplaceEquipmentSettingAttachmentAction implements Action {
    public readonly type = ActionType.ReplaceEquipmentSettingAttachment
    constructor(
        public equipmentId: string,
        public file: File,
        public attachmentId: string
    ) { }
}

export class ReplaceEquipmentSettingAttachmentSuccessAction implements Action {
    public readonly type = ActionType.ReplaceEquipmentSettingAttachmentSuccess
    constructor(
        public oldAttachmentId: string,
        public payload: Attachment
    ) { }
}

export type AttachmentAction = DeleteCalibrationAttachmentAction
    | DeleteCalibrationAttachmentSuccessAction
    | GetCalibrationAttachmentsAction
    | GetCalibrationAttachmentsSuccessAction
    | ReplaceCalibrationAttachmentAction
    | ReplaceCalibrationAttachmentSuccessAction
    | UploadCalibrationAttachmentAction
    | UploadCalibrationAttachmentSuccessAction
    | DeleteEquipmentSettingAttachmentAction
    | DeleteEquipmentSettingAttachmentSuccessAction
    | GetEquipmentSettingAttachmentsAction
    | GetEquipmentSettingAttachmentsSuccessAction
    | ReplaceEquipmentSettingAttachmentAction
    | ReplaceEquipmentSettingAttachmentSuccessAction
    | UploadEquipmentSettingAttachmentAction
    | UploadEquipmentSettingAttachmentSuccessAction

import { Action } from '@ngrx/store'

import { UOM } from '@app/modules/shared/models/engineering-units/unit-of-measurement.model'

export enum ActionType {
    GetUOMList = '[UOM] Load UOM',
    GetUOMListSuccess = '[UOM] Add UOM'
}

export class GetUOMListAction implements Action {
    public readonly type = ActionType.GetUOMList
}

export class GetUOMListSuccessAction implements Action {
    public readonly type = ActionType.GetUOMListSuccess
    constructor(public payload: UOM[]) { }
}

export type UOMAction = GetUOMListAction | GetUOMListSuccessAction

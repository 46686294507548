import { Pipe, PipeTransform } from '@angular/core'
import { UOM } from '@app/modules/shared/models/engineering-units/unit-of-measurement.model'


@Pipe({
    name: 'filterPercentUom',
    standalone: true
})
export class FilterPercentUom implements PipeTransform {
    transform(value: UOM[]): any {
        return value.filter(e => e.uomCode !== 'P1')
    }
}

<div class="em-l-grid em-l-grid--2up simple-form-row">

    <!-- Label and Description -->
    <div class="em-l-grid__item em-u-width-33 em-u-text-align-right simple-form-row__section"
         [ngClass]=" 'simple-form-row__section--'+labelsVerticleAlign ">
        <label class="em-c-field__label simple-form-row__label">
            {{ label }}
        </label>
        <p *ngIf="description"
           class="em-u-font-style-semibold em-u-font-size-small">
            {{ description }}
        </p>
    </div>

    <!-- Right side -->
    <div class="em-l-grid__item em-u-padding-left-double em-u-width-66 simple-form-row__section"
         [ngClass]=" 'simple-form-row__section--'+contentVerticleAlign ">
        <div>
            <ng-content></ng-content>
        </div>
    </div>

</div>
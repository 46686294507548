import { AsyncPipe, DOCUMENT, NgClass, NgFor, NgIf } from '@angular/common'
import { Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { FormsModule, UntypedFormControl } from '@angular/forms'
import { BehaviorSubject, Observable } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'

import { TestEquipment } from '@app/modules/test-equipment/models/test-equipment.model'
import { TestEquipmentFilteringService } from '@app/modules/test-equipment/services/test-equipment-filtering.service'
import { SafeUnsubscriberComponent } from '@app/safe-unsubscriber.component'
import { AppSettings } from '@settings/app.settings'
import { EquipmentQueryParameter } from '../../models/equipment-query-parameter.model'
import { EquipmentListService } from '../../services/equipment-list.service'

@Component({
    selector: 'app-test-equipment-picker',
    templateUrl: './test-equipment-picker.component.html',
    styleUrls: ['./test-equipment-picker.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        FormsModule,
        AsyncPipe,
        NgFor
    ]
})
export class TestEquipmentPickerComponent extends SafeUnsubscriberComponent implements OnInit, OnChanges, OnDestroy {
    @Input() placeHolder = 'Select Test Equipment'
    @Input() itemOrder: number
    @Input() masterTestEquipments: TestEquipment[] = []
    @Input() showRemoveButton: boolean
    @Input() testEquipmentControl: UntypedFormControl
    @Input() disableExpired: boolean
    @Input() isDisabled: boolean
    @Input() isCompareEquiment = false
    @Input() apiPaginationSize = AppSettings.equipmentListTotalRowPerPage

    @Output() removeButtonClicked: EventEmitter<number> = new EventEmitter()
    @Output() clearSelectionClicked: EventEmitter<number> = new EventEmitter()

    @ViewChild('input', { static: true }) el: ElementRef
    @ViewChild('itemsList', { static: true }) itemsListRef: ElementRef

    public searchText: BehaviorSubject<string> = new BehaviorSubject('')
    public searchText$: Observable<string> = this.searchText.asObservable()
    public filteredTestEquipments: TestEquipment[] = []

    private hasFocus: boolean
    private focusTimeout: any
    private clickHandler: any
    private currentScrollPage = 1
    private currentApiPage = 0
    private previousSearchText = ''

    private readonly pageSize = AppSettings.testEquipmentDropdownPageSize

    constructor(
        private eRef: ElementRef,
        private filteringService: TestEquipmentFilteringService,
        private equipmentListService: EquipmentListService,
        @Inject(DOCUMENT) private document: any,
    ) {
        super()
    }

    get selectedTestEquipment(): TestEquipment { return this.testEquipmentControl.value }

    private get itemsList(): HTMLUListElement {
        return this.itemsListRef.nativeElement as HTMLUListElement
    }

    ngOnInit(): void {
        this.addSubscription(
            this.searchText$.pipe(
                debounceTime(500),
                distinctUntilChanged()
            ).subscribe(newVal => {
                this.applyFilter(newVal, true)
            })
        )
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateTestEquipmentFromParentComponent(changes)
        this.updateDisplayOrder(changes)
    }

    ngOnDestroy(): void {
        this.removeClickHandler()
        super.ngOnDestroy()
    }

    public setFocusState(value: boolean): void {
        this.applyFilter(this.searchText.value)

        if (this.focusTimeout) {
            clearTimeout(this.focusTimeout)
        }

        this.focusTimeout = setTimeout(() => {
            this.hasFocus = value

            if (!this.hasFocus) {
                this.removeClickHandler()
            } else {
                if (!this.clickHandler) {
                    this.clickHandler = event => {
                        if (!this.eRef.nativeElement.contains(event.target)) {
                            this.hasFocus = false

                            this.removeClickHandler()
                        }
                    }
                    this.document.addEventListener('click', this.clickHandler, {
                        passive: true
                    })
                }
            }
        }, 200)
    }

    public selectTestEquipment(testEquipment: TestEquipment): void {
        if (this.isTestEquipmentNeededToBeDisabled(testEquipment) || this.isDisabled) {
            return
        }

        const testEquipmentWithOrder = { ...testEquipment, displayOrder: this.itemOrder + 1 } as TestEquipment

        this.setInputMode(false, false)
        this.removeClickHandler()
        this.testEquipmentControl.setValue(testEquipmentWithOrder)
        this.searchText.next('')
    }

    public clearSelection(): void {
        if (this.isDisabled) {
            return
        }

        this.testEquipmentControl.setValue(undefined)
        this.searchText.next('')
        this.clearSelectionClicked.next(this.itemOrder)
    }

    public isTestEquipmentNeededToBeDisabled(testEquipment: TestEquipment): boolean {
        return (testEquipment.isExpired || !testEquipment.isActive) && this.disableExpired
    }

    public setInputMode(value: boolean, isFocus?: boolean): void {
        if (this.isDisabled) {
            return
        }

        if (value && isFocus) {
            this.el.nativeElement.focus()
        } else {
            this.setFocusState(false)
        }
    }

    public isReadyToShowList(): boolean {
        const isReady =
            this.hasFocus &&
            !this.selectedTestEquipment && !this.isDisabled

        return isReady
    }

    public removeSelf(): void {
        this.removeButtonClicked.emit(this.itemOrder)
    }

    public getPlaceholderMessage(): string {
        return (this.selectedTestEquipment || this.isDisabled) ? '' : this.placeHolder
    }

    public onListScroll(event: Event): void {
        const target = event.target as HTMLElement
        const scrollLeft = (target.scrollHeight - (target.scrollTop + target.clientHeight))
        if (scrollLeft <= 10) {
            this.currentScrollPage++
            this.applyFilter(this.searchText.value)
        }
    }

    private applyFilter(searchText: string, resetScroll: boolean = false): void {
        this.filteredTestEquipments = this.filteringService
            .filterTestEquipment(this.masterTestEquipments, searchText)
            .slice(0, this.currentScrollPage * this.pageSize)

        if (resetScroll) {
            this.itemsList.scrollTo(0, 0)
        }

        if (!this.isCompareEquiment) {
            return
        }

        const query: EquipmentQueryParameter = { search: searchText, expandTemplate: false }
        let nextApiPage = Math.ceil((this.currentScrollPage * this.pageSize) / this.apiPaginationSize)

        if (this.previousSearchText !== searchText) {
            this.previousSearchText = searchText
            this.currentApiPage = 0
            this.currentScrollPage = 1
            nextApiPage = 1
        }

        if (this.previousSearchText === searchText && nextApiPage <= this.currentApiPage) {
            return
        }

        this.currentApiPage = nextApiPage
        this.equipmentListService.compareEquipmentFetch(query, nextApiPage, this.apiPaginationSize)
    }

    private removeClickHandler(): void {
        if (this.isReadyToShowList()) {
            return
        }
        this.document.removeEventListener('click', this.clickHandler)
        this.clickHandler = null
    }

    private updateTestEquipmentFromParentComponent(changes: SimpleChanges): void {
        if (changes.masterTestEquipments && changes.masterTestEquipments.currentValue) {
            this.applyFilter(this.searchText.value)
        }
    }

    private updateDisplayOrder(changes: SimpleChanges): void {
        if (changes.itemOrder && changes.itemOrder.currentValue !== undefined) {
            if (this.selectedTestEquipment) {
                const testEquipment = {
                    ...this.selectedTestEquipment,
                    displayOrder: this.itemOrder + 1
                } as TestEquipment

                this.testEquipmentControl.setValue(testEquipment, { onlySelf: true })
            }
        }
    }
}

import { Injectable } from '@angular/core'
import { EquipmentTemplateDetailInput } from '@app/modules/equipment/models/equipment-template-detail-input.model'
import { safeObjGet, isNotAValue } from '@app/utils/app-utils.function'
import { Process } from '../../models/process.model'

@Injectable({
    providedIn: 'root'
})
export class TemperatureTemplateValidationService {

    public isValid(formDetails: object): boolean {
        const tolerance = safeObjGet(formDetails, 'tolerance') as EquipmentTemplateDetailInput
        const input = safeObjGet(formDetails, 'input') as EquipmentTemplateDetailInput
        const expected = safeObjGet(formDetails, 'expected') as EquipmentTemplateDetailInput
        const process = safeObjGet(formDetails, 'process') as Process

        if (isNotAValue(process.id) ||
            isNotAValue(process.name) ||
            isNotAValue(tolerance.unitOfMeasurement) ||
            isNotAValue(tolerance.value) ||
            isNotAValue(input.unitOfMeasurement) ||
            isNotAValue(input.maximumRange) ||
            isNotAValue(input.minimumRange) ||
            isNotAValue(expected.unitOfMeasurement) ||
            isNotAValue(expected.maximumRange) ||
            isNotAValue(expected.minimumRange)
        ) {
            return false
        }

        if (input.minimumRange > input.maximumRange ||
            expected.minimumRange > expected.maximumRange
        ) {
            return false
        }

        return true
    }
}

import { SortedState, SortingState } from '@app/modules/shared/models/filter.model'

export const reportSortingState = [
    {
        sortId: 'finalPMResultStatus.name',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'reviewStatusId',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'calibrationStatus.name',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'workOrderNumber',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'equipmentId',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'equipmentTag',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'techIdentificationNumber',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'functionalLocation',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'workOrderDescription',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'pmPerformedTechnician.displayName',
        sortingState: SortingState.None,
        sortOrder: 0
    },
    {
        sortId: 'pmPerformedDate',
        sortingState: SortingState.None,
        sortOrder: 0
    }
] as SortedState[]

import { createAction, props, union } from '@ngrx/store'

import { MaintenancePlanAPIResponse, MaintenancePlanQuery } from '@app/modules/maintenance-plan/models/maintenance-plan-query.model'
import { MaintenancePlanExpanded } from '@app/modules/shared/models/maintenance-plan.model'

/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match */

/** Start loop that will get ALL pages of maintenance plan list.  */
export const MaintenancePlanListSyncStartAction =
    createAction('[Maintenance Plan] Maintenance Plan Sync Start')

/** Get indivitual page of maintenance plan list. */
export const GetMaintenancePlanListAction =
    createAction('[Maintenance Plan] Get Maintenance Plan List', props<MaintenancePlanQuery>())

/** Tell reducer to push new page into its statre */
export const GetMaintenancePlanListSuccessAction =
    createAction('[Maintenance Plan] Get Maintenance Plan List Success', props<MaintenancePlanAPIResponse>())

export const GetMaintenancePlanDetailAction =
    createAction('[Maintenance Plan] Get Maintenance Plan Detail', props<{ maintenancePlanNumber: string }>())

export const GetMaintenancePlanDetailSuccessAction =
    createAction('[Maintenance Plan] Get Maintenance Plan Detail Success', props<{ maintenancePlan: MaintenancePlanExpanded }>())

// TODO: Should forward HTTPError
export const GetMaintenancePlanDetailFailedAction =
    createAction('[Maintenance Plan] Get Maintenance Plan Detail Failed')

export const GetMaintenancePlanLongText =
    createAction('[Maintenance Plan] Get Maintenance Plan Long Text', props<{ maintenancePlanNumber: string | number }>())

export const GetMaintenancePlanLongTextSuccess =
    createAction('[Maintenance Plan] Get Maintenance Plan Long Text Success', props<{ maintenancePlanNumber: string | number, longText: string }>())

export const GetMaintenanceItemLongText =
    createAction('[Maintenance Plan] Get Maintenance Item Long Text', props<{ maintenanceItemNumber: string | number }>())

export const GetMaintenanceItemLongTextSuccess =
    createAction('[Maintenance Plan] Get Maintenance Item Long Text Success', props<{ maintenanceItemNumber: string | number, longText: string }>())

// This allow action creator syntax to be combined with switch type reducer
const all = union({
    MaintenancePlanListSyncStartAction,
    GetMaintenancePlanListAction,
    GetMaintenancePlanListSuccessAction,
    GetMaintenancePlanDetailAction,
    GetMaintenancePlanDetailSuccessAction,
    GetMaintenancePlanDetailFailedAction,
    GetMaintenancePlanLongText,
    GetMaintenancePlanLongTextSuccess,
    GetMaintenanceItemLongText,
    GetMaintenanceItemLongTextSuccess
})
export type MaintenancePlanAction = typeof all

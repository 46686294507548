<div [formGroup]="formGroup" class="em-l-grid em-l-grid--2up">
    <div [ngClass]="{'admin-label-description': description}" class="em-l-grid__item em-u-width-33 em-u-text-align-right admin-label">
        <label class="em-c-field__label em-u-font-size-small-2">
            {{ label }}
        </label>
        <p *ngIf="description" class="em-u-font-style-semibold em-u-font-size-small">
            {{ description }}
        </p>
    </div>
    <div class="em-l-grid__item em-u-padding-left-double em-u-width-66">
        <div class="em-l-grid em-l-grid--2up">
            <ng-content></ng-content>
        </div>
    </div>
</div>

import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { ToastrService } from 'ngx-toastr'
import { take } from 'rxjs/operators'

import { mrmaAlertConfigs } from '@app/models/alert-configuration.model'
import { AlertType, alertTypeToastrRef } from '@app/models/alert-type.enum'
import { BackgroundSyncStatusEnum } from '@app/models/offline-status.enum'
import { TemplateTypeEnum } from '@app/modules/equipment/models/template-type.enum'
import { OfflineService } from '@app/services/offline.service'
import { AppState } from '@app/store/app.store'
import { GetCalibrationAttachmentsAction } from '@app/store/attachment/attachment.actions'
import { hasAttachment } from '@app/store/attachment/attachment.selectors'
import { isNotAValue } from '@app/utils/app-utils.function'
import { Attachment } from '../../shared/models/attachment.model'
import { pmAlertMessage } from '../models/calibration-alert-message.model'
import { CalibrationAlert } from '../models/calibration-alert.model'
import { CalibrationDetails } from '../models/calibration-details.model'
import { CalibrationResultStatusEnum } from '../models/calibration-result-status.enum'
import { CalibrationStatusEnum } from '../models/calibration-status.enum'
import { CalibrationStatusWithPreviousState } from '../models/calibration-status.model'
import { WorkOrderDetails } from '@app/modules/work-order/models/work-order-details.model'
import { every } from 'lodash'

@Injectable({
    providedIn: 'root'
})
export class CalibrationAlertService {
    constructor(
        private store: Store<AppState>,
        private router: Router,
        private toastr: ToastrService,
        private offlineService: OfflineService
    ) { }

    public offlineAlert(calibrationDetail: CalibrationDetails, syncStatus: BackgroundSyncStatusEnum): boolean {
        if (!this.router.url.includes('calibration')) {
            return false
        }

        const calibrationStatus = calibrationDetail?.calibrationStatus?.id

        const queueAndCalDraftAlert = {
            isEnabled: syncStatus === BackgroundSyncStatusEnum.QUEUED && calibrationStatus === CalibrationStatusEnum.Draft,
            alert: {
                type: AlertType.WorkflowWarning,
                message: pmAlertMessage.swDataSavedOffline
            } as CalibrationAlert
        }

        const queuedAndCalCompleteAlert = {
            isEnabled: syncStatus === BackgroundSyncStatusEnum.QUEUED && calibrationStatus === CalibrationStatusEnum.Completed,
            alert: {
                type: AlertType.WorkflowWarning,
                message: pmAlertMessage.swEQWaitingNetworkConnection
            } as CalibrationAlert
        }

        const syncedAndCalDraftAlert = {
            isEnabled: syncStatus === BackgroundSyncStatusEnum.SYNCED && calibrationStatus === CalibrationStatusEnum.Draft,
            alert: {
                type: AlertType.WorkflowSuccess,
                message: pmAlertMessage.swEQUploadDraft
            } as CalibrationAlert
        }

        const syncedAndCalCompleteAlert = {
            isEnabled: syncStatus === BackgroundSyncStatusEnum.SYNCED && calibrationStatus === CalibrationStatusEnum.Completed,
            alert: {
                type: AlertType.WorkflowSuccess,
                message: pmAlertMessage.swEQUploadSuccess
            } as CalibrationAlert
        }

        const alerts = [
            queueAndCalDraftAlert, queuedAndCalCompleteAlert,
            syncedAndCalDraftAlert, syncedAndCalCompleteAlert
        ]

        const alertToShow = alerts.find(it => it.isEnabled)

        if (alertToShow) {
            this.alert(alertToShow.alert)
            return true
        }

        return false
    }

    public handleOfflineErrorAlert(error: HttpErrorResponse, calibrationDetail: CalibrationDetails): void {
        if (this.offlineService.isOnline && error.status !== 0) {
            return
        }

        let syncStatus = this.offlineService.getCalibrationBackgroundSyncStatus(
            calibrationDetail.workOrderNumber,
            calibrationDetail.equipmentId
        )

        syncStatus = isNotAValue(syncStatus) ? BackgroundSyncStatusEnum.QUEUED : syncStatus
        this.offlineAlert(calibrationDetail, syncStatus)
    }

    public saveAndCompleteAlert(
        calibrationDetailFromApi: CalibrationDetails,
        currentCalibrationDetail: CalibrationDetails,
        currentWODetails?: WorkOrderDetails
    ): void {
        this.removeAllAlert()
        const newStatusId = calibrationDetailFromApi?.calibrationStatus?.id
        const currentStatusId = (currentCalibrationDetail?.calibrationStatus as CalibrationStatusWithPreviousState)?.previousStatus?.id

        const completedCalibrationByPost = newStatusId === CalibrationStatusEnum.Completed && currentCalibrationDetail === null
        const completedCalibrationByPut = newStatusId === CalibrationStatusEnum.Completed && currentStatusId === CalibrationStatusEnum.Draft

        if (completedCalibrationByPost || completedCalibrationByPut) {
            this.alert({ type: AlertType.WorkflowSuccess, message: pmAlertMessage.pmEqCompleted })
            this.showAlertIfAllCalibrationsCompletedButNotificationOpen(currentWODetails, currentCalibrationDetail)
        } else {
            this.alert({ type: AlertType.WorkflowSuccess, message: pmAlertMessage.saved })
        }

        this.verifyAttachmentBeforeAlert(calibrationDetailFromApi)
        this.repairWoRequiredAlert(calibrationDetailFromApi)
    }

    public repairWoRequiredAlert(calibrationDetail: CalibrationDetails): void {
        const calibrationStatus = calibrationDetail?.calibrationStatus?.id
        if (calibrationStatus !== CalibrationStatusEnum.Completed) {
            return
        }

        if (!calibrationDetail.repairWorkOrderNumber &&
            calibrationDetail.finalPMResultStatus?.id === CalibrationResultStatusEnum.Failed
        ) {
            this.alert({ type: AlertType.WorkflowRequire, message: pmAlertMessage.repairWONumber })
        }
    }

    public attachmentRequiredAlert(
        calibrationId: string,
        calibrationStatusId: number,
        templateTypeId: number,
        attachmentList: Attachment[]
    ): void {
        const isThirdParty = templateTypeId === TemplateTypeEnum[TemplateTypeEnum.thirdParty]
        const calibrationComplete = calibrationStatusId === CalibrationStatusEnum.Completed
        if (isThirdParty && calibrationComplete && !attachmentList.length && !!calibrationId) {
            this.alert({ type: AlertType.WorkflowRequire, message: pmAlertMessage.attachmentRequire })
        }
    }

    public calibrationSyncCompleteAlert(updateStatusCount: boolean[]): void {
        const updateSynced = updateStatusCount.reduce((acc, cur) => acc + Number(cur), 0)
        const message = pmAlertMessage.pmUploadFinished
            .replace('[uploaded]', updateSynced.toString())
            .replace('[total]', updateStatusCount.length.toString())

        this.alert({ type: AlertType.WorkflowSuccess, message } as CalibrationAlert)
    }

    public removeAllAlert(): void {
        this.toastr.clear()
    }

    private showAlertIfAllCalibrationsCompletedButNotificationOpen(workOrderDetails: WorkOrderDetails, updatedCalibration: CalibrationDetails): void {
        if (workOrderDetails && every(workOrderDetails.equipmentCalibrations,
            calibration => calibration.equipmentId === updatedCalibration.equipmentId || calibration.status === 'completed')) {
            this.toastr.warning(pmAlertMessage.notificationClosureDelay, '', mrmaAlertConfigs.NotificationClosureDelayWarning.configuration)
        }
    }

    private alert(alert: CalibrationAlert): void {
        const config = mrmaAlertConfigs[alert.type]
        this.toastr.show(alert.message, '', config.configuration, alertTypeToastrRef[alert.type])
    }

    private verifyAttachmentBeforeAlert(calibrationDetail: CalibrationDetails): void {
        const calibrationStatusId = calibrationDetail?.calibrationStatus?.id ?? -1
        this.store.select(hasAttachment).pipe(take(1)).subscribe(attachmentExist => {
            if (!attachmentExist) {
                // Dispatch GetAttachmentsAction end response should call attachmentRequiredAlert()
                this.store.dispatch(new GetCalibrationAttachmentsAction(calibrationDetail.id, calibrationStatusId, calibrationDetail.templateTypeId))
            }
        })
    }
}

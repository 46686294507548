import { createAction, props, union } from '@ngrx/store'

/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match */

const actionPrefix = '[Tooltip]'

export const AcknowledgeTipAction =
    createAction(`${actionPrefix} Acknowledge Tip`, props<{ featureName: string, tipIndex: number }>())

export const SkipFeatureTipsAction =
    createAction(`${actionPrefix} Skip Feature Tips`, props<{ featureName: string }>())

const all = union({
    AcknowledgeTipAction,
    SkipFeatureTipsAction
})
export type TooltipAction = typeof all
